import React, { Fragment, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import { FAQ_DATA } from './constants/faq'

import Accordion from 'components/Accordion'

const Faq = () => {
    const t = useTranslate()

    const [activeAccordion, setActiveAccordion] = useState(null)

    const handleActiveAccordion = (index) => {
        setActiveAccordion((prev) => (prev === index ? null : index))
    }

    return (
        <div className="column">
            <span className="a-bodyTextMedium">{t('general.faq')}</span>
            <p className="a-mediumText a-lightText -mt10">
                {t('general.welcomeToFaq')}
            </p>
            {FAQ_DATA.map((item, sectionIndex) => (
                <div className="-mt20 fullWidth" key={sectionIndex}>
                    <span className="a-bodyTextMedium">{item.title}</span>
                    {item.importantNotice && (
                        <p className="a-mediumText a-lightText -mt10">
                            <span className="a-mediumTextSemiBold">
                                {t('general.importantNotice')}
                                {': '}
                            </span>
                            {item.importantNotice}
                        </p>
                    )}
                    {item.description && (
                        <p className="a-mediumText a-lightText -mt10">
                            {item.description}{' '}
                            {item.link && (
                                <Link to={item.link} target="blank">
                                    {item.linkText}{' '}
                                </Link>
                            )}
                            {item.description2 && item.description2}
                        </p>
                    )}
                    {item.questions?.map((question, index) => (
                        <Accordion
                            title={question.question}
                            withBorders
                            key={index}
                            index={`section-${sectionIndex}-question-${index}`}
                            translateTitle={false}
                            onlyOneOpen
                            activeAccordion={activeAccordion}
                            handleActiveAccordion={handleActiveAccordion}
                        >
                            <div>
                                <div className="a-mediumText a-lightText">
                                    {question.answer}{' '}
                                    {question.link && (
                                        <Fragment>
                                            <Link
                                                to={question.link}
                                                target="blank"
                                            >
                                                {question.linkText}
                                            </Link>{' '}
                                            {question.answerRemainingText}
                                        </Fragment>
                                    )}
                                    {question.answerList && (
                                        <ul className="-mt5">
                                            {question.answerList.map(
                                                (listItem, index) => (
                                                    <li
                                                        className="-liDisc"
                                                        key={index}
                                                    >
                                                        <span className="a-mediumTextSemiBold">
                                                            {listItem.boldText}
                                                        </span>
                                                        {' - '}
                                                        {listItem.text}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </Accordion>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default Faq

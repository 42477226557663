import React from 'react'
import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.createHistoricalDiveRecord',
    videoLink: 'https://embed.app.guidde.com/playbooks/v2GGphqvEwvd5qb7LfJBdg',
    description: `howTo.createHistoricalDiveRecordDescription`,
    goTo: false,
    steps: [
        {
            title: `Introduction`,
            description: `First, head over to the Skill N Depth platform.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F2V6vMVtypewmFy89zZjG17_doc.png?alt=media&token=0e2f701f-03b7-47cf-815f-42094077b8d6',
        },
        {
            title: 'Click "Create a dive record"',
            description: `Next, click on “Create a dive record.” This will open up
                        a new form where you can start entering the details of
                        your dive.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fsg88ejUMbW8Fb9sfpY4oD9_doc.png?alt=media&token=be0d5eba-fa68-402c-be48-bccf5f5fa14d',
        },
        {
            title: 'Select role that you had',
            description: `Now, pick the role you had during the dive—whether you
                        were a Diver or a Dive supervisor. This choice helps
                        categorize your record correctly.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fdj2ppPm7YWHkKytao52QER_doc.png?alt=media&token=a6117856-4e8e-4cc3-997b-858d42c310b1',
        },
        {
            title: 'Select diving mode',
            description: `Next up, choose the diving mode that was used for this
                        particular dive.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F777xKz1EwyjG3QJJrPhM6G_doc.png?alt=media&token=18499ed7-6558-45f6-a007-2a399dd288e3',
        },
        {
            title: 'Click "Continue"',
            description: `Click Continue to proceed to the next step.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F58MutN1yCnSgUGWVezC28j_doc.png?alt=media&token=8adbcbbd-1f7d-49cb-b490-cbcd66749ae8',
        },
        {
            title: 'Fill in location details',
            description: `It’s time to set the scene! Start by providing the
                        location details.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FebSA6KjaVLMAojb4TSA3Ns_doc.png?alt=media&token=b29e8703-217a-4148-8a25-7ba6668b7471',
        },
        {
            title: 'Input geographical data',
            description: `Next, provide geographical data.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FoBnYtr2Tkrc8JVoRxN4R2F_doc.png?alt=media&token=3ca8f109-7c3f-4e13-a359-0011a10494fb',
        },
        {
            title: 'Enter dive details',
            description: `Next, enter details about the dive itself. Details
                        include diving mode and diving platform as mandatory
                        fields. Dive physical characteristic can be left empty.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FrZa1iz6srAwu5RpyX9sZCv_doc.png?alt=media&token=0709f01f-3882-4cc9-b609-f1fa542957a7',
        },
        {
            title: 'Enter environmental conditions',
            description: `Though optional, this section is super helpful. Provide
                        information about the environmental conditions during
                        the dive. It’s a great way to paint a full picture of
                        your experience.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fdn1DnhU9A2ASxzev8G6k43_doc.png?alt=media&token=57441044-5be3-447a-a266-3184d715cc11',
        },
        {
            title: 'Click next',
            description: `Once you are satisfied with data entered click on next
                        to go to he next step.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fs1FUzErpi6jUbZ1cJEd27p_doc.png?alt=media&token=fafdfe5b-2959-4fb0-94ed-e18e59b69faa',
        },
        {
            title: 'Choose the breathing apparatus type',
            description: `Select the type of breathing apparatus you used.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Ff35xAmVyq3NECRbavWihoo_doc.png?alt=media&token=9bc9101b-a184-4362-b9c3-215d05a49ce0',
        },
        {
            title: 'Enter data about the equipment',
            description: `While these fields are optional, it’s always nice to
            give a complete picture of the dive by listing the
            equipment you used. This includes everything from your
            suit to any specialized equipment.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FachDC1jmZy3UNTWRAbiyjJ_doc.png?alt=media&token=9808500c-f92e-49eb-9411-c017a9e7664a',
        },
        {
            title: 'Add gas cylinder used',
            description: `Time to log your gas cylinder details.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FspqCVa1BfsVr8te7eUB6v9_doc.png?alt=media&token=46d0d1b9-874b-4996-b8c6-f1c146c3299a',
        },
        {
            title: 'Input data',
            description: `Start by inputting the data`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F2ogxqJjxY1L9ubRHmpzey8_doc.png?alt=media&token=08502cec-cd75-4299-abc9-fd5291dff615',
        },
        {
            title: 'Click on "Add gas cylinder"',
            description: `Then click on “Add gas cylinder” to save it.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fjs14WWwzXruPGRdKAeDM6D_doc.png?alt=media&token=2c956e93-713c-480b-aa85-c406a56fcb39',
        },
        {
            title: 'Observe added cylinder',
            description: `You’ll see a list of all the gas cylinders you’ve added
                        at the bottom.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F3pDTkyXdWhE6LX21rf13uo_doc.png?alt=media&token=eb564be7-2ad0-4d68-8303-3d8d0ac8cf70',
        },
        {
            title: 'Click "Next"',
            description: `Proceed to the next section.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F9itzmAXC94sb7omwX7KLsb_doc.png?alt=media&token=228370db-d60f-450e-999c-d67483c0c3ef',
        },
        {
            title: 'Click "Add dive event"',
            description: `Depending on the diving mode selected on the first step
                        of creation of the dive record you will get different
                        options here. For example, for Scuba diving we need to
                        enter data about the dive event. No matter what section
                        shows the process is the same. Click on Add button, add
                        data, and save it, It is really easy. So lets click on
                        add dive event button here.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F4HgNNqLujG3ASpxAtQz5qQ_doc.png?alt=media&token=b3621950-dccd-4890-9465-3678734619d8',
        },
        {
            title: 'Fill in the form',
            description: `Proved all data required by the form. Mandatory fields
                        are marked with a star next to the title of the field.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FxejEDzxoATiPrRxdFhvFsa_doc.png?alt=media&token=8b9e31a8-ae19-40b4-b60a-de22e7690837',
        },
        {
            title: 'Click "Save"',
            description: `Save the data.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FwM8gMkTthimuQDFUaqURxZ_doc.png?alt=media&token=5b6ee5d6-d24c-4992-824f-7881b07e9ef8',
        },
        {
            title: 'Double-check the data',
            description: `If everything went smoothly you should have a card with
                        all data entered. Double check it and if you are happy
                        proceed. Keep in mind that some dive modes have multiple
                        cards that need to be entered.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fm4KR9FdtCmRY4NNrHmnQNA_doc.png?alt=media&token=85717cff-641e-42e2-8b6e-65eebb710eb7',
        },
        {
            title: 'Check option at the top',
            description: `Each page has a header with an indication of the current
                        step and a list of all steps required. You can click "Go
                        back" to get to the Skill N Depth app, or you can click
                        on one of the previous steps in order to get back to
                        that step.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fxf2R9KriskGWUuS8W3CMQy_doc.png?alt=media&token=ea812fa0-b867-4af9-9ed6-169343bf4ddd',
        },
        {
            title: 'Keep in mind "Save and continue later" option',
            description: `If you’re not done or need to take a break, no problem!
                        Use the “Save and continue later” option to save your
                        progress. Only you will be able to see this draft until
                        you’re ready to finalize it.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FaHaCibLBHu5jN7k9ep2NhU_doc.png?alt=media&token=95c248bc-4580-4523-81a8-db24c1ccf2a8',
        },
        {
            title: 'Click "Next"',
            description: `Proceed to the next step.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FbRa8qhHqHraEdkBphSFC5F_doc.png?alt=media&token=cfa715d8-3e39-4b2e-9398-4ef487122965',
        },
        {
            title: 'Enter tasks performed',
            description: `Now, you’ll need to list the tasks you performed during
                        the dive. This could include inspections, repairs, or
                        any other activities.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FxhFJyutmdpTJT5HDBrxUck_doc.png?alt=media&token=ce32bbc5-8bbd-4fe4-9416-a962230a7352',
        },
        {
            title: 'Add data',
            description: `Choose the relevant structures, systems, devices,
            objects, and materials.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fixf9SgfLNyb3aaNqYqZyc2_doc.png?alt=media&token=3ae316c3-e309-4fb6-876c-29b8ee56e4fe',
        },
        {
            title: 'Add activities',
            description: `Now, select all the activities you performed during the
                        dive.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FmVraPzHoR5sDAGBKQBdi8d_doc.png?alt=media&token=0b491f11-83d9-4c34-870a-ec7f368fb7bb',
        },
        {
            title: 'Select all tools used',
            description: `Choose the tools that were used during the dive. This
                        provides additional context to the tasks performed.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FdbGZ2cjyZGGubqt72xkbnt_doc.png?alt=media&token=1af68b8e-b41e-4dcd-9924-340c4a6f7cb6',
        },
        {
            title: 'Click "Next"',
            description: `Proceed to the next section.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FkeAXP4YXZwyxBJx26UatVL_doc.png?alt=media&token=6cf3e404-52e3-4607-b2f3-2cbc4900f565',
        },
        {
            title: 'Enter data',
            description: `Input information about your rest, diet, hydration,
                        medications, supplements, and physical activity on the
                        day of the dive. While these fields are optional, they
                        add valuable context to your record.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FkD6PaFPHBhAWb4UjU1fVz3_doc.png?alt=media&token=ec5346f2-123e-4c19-b91e-89a75b2de1d4',
        },
        {
            title: 'Enter data',
            description: `Enter details regarding your mental wellness and
                        hygiene. This step is important for providing a holistic
                        view of your dive day.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fd3Z28E7Lt31bR2guXKWLFN_doc.png?alt=media&token=b879a2c5-65dd-47bd-911f-720af6cd614e',
        },
        {
            title: 'Enter data',
            description: `The next step is really important. It is about
                        incidents. We take the security and wellbeing of divers
                        very seriously. If there have been any incidents please
                        describe them and give as much detail as possible, Only
                        by being transparent we can improve the standards in our
                        industry.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FcitUHRtzUf3MyGzmKc7sSk_doc.png?alt=media&token=98e6da59-d715-43b7-b963-ee4c980a263a',
        },
        {
            title: 'Upload any documents',
            description: `If you have any relevant documents or pictures related
                        to the dive, you can upload them here.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FpPG8MPCwiTprAaj6kD4h4L_doc.png?alt=media&token=632334bd-e8bd-4cce-a801-012a2ca95e22',
        },
        {
            title: 'Click "Save"',
            description: `Once you’ve reviewed all your entries, click “Save” to
                        complete your dive record.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FhrpEvYhTuRPByENJzMkVCg_doc.png?alt=media&token=4b876b9b-b616-4233-93e4-617ed57adec3',
        },
        {
            title: 'See created dive record',
            description: `Now your dive record is clearly visible in the list of
                        your dive records. Next to it, you will see the status
                        "Completed" indicating that this dive record is fully
                        done, but not yet verified. An icon next to it is the
                        button that you can click and get the verification link.
                        This is the process of verification of Level 1 and you
                        can get more details by viewing this section in our app.
                        The last button that you can use is the copy button. If
                        you have a couple of similar dive records you are able
                        to copy one record and all of its values. Creating the
                        next one will only include changing a couple of items.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FjQykp5DDZyQLEo1wj3w3d4_doc.png?alt=media&token=0b92e176-3912-46f1-ada6-27334c7ac7ed',
        },
    ],
    endNote: `Congratulations! You've successfully created a
                        historical dive record. This record is now part of your
                        official log and can be verified or edited as needed.
                        Happy diving, and keep those records accurate and
                        up-to-date!`,
}

const CreateHistoricalDiveRecord = () => {
    return <HowToPage howToObject={howToObject} />
}

export default CreateHistoricalDiveRecord

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from './Icon'

const LinkWithIcon = ({ to, icon, linkText }) => {
    const t = useTranslate()
    return (
        <Link to={to} className="aligned-center -gap5">
            <Icon
                name={icon}
                color={COLORS.LIGHT_BLUE}
                size={ICON_SIZE.SIZE20}
            />
            <span className="a-captionsTextRegular">{t(linkText)}</span>
        </Link>
    )
}

LinkWithIcon.propTypes = {
    to: PropTypes.string,
    icon: PropTypes.string,
    linkText: PropTypes.string,
}

export default LinkWithIcon

import React from 'react'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import { BUTTON_SIZE, ICON_SIZE } from 'constants/enums'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Icon from 'components/Icon'
import ActionButton from './table/ActionButton'

const DropdownActions = ({
    open,
    setOpen,
    actions,
    item,
    icon = null,
    customClass = '',
    itemIndex,
}) => {
    const t = useTranslate()

    return (
        <div
            className={`a-actions ${customClass}`}
            onClick={() => setOpen(!open)}
        >
            {icon ?? <Icon name={ICONS.DOTS} />}
            <div className={`a-actions__buttons ${open ? '-active' : ''}`}>
                {actions.map((action, index) => (
                    <ActionButton
                        key={index}
                        {...action}
                        item={item}
                        itemIndex={itemIndex}
                        iconSize={ICON_SIZE.SIZE20}
                    />
                ))}
            </div>
        </div>
    )
}

export default clickOutsideHOC(DropdownActions)

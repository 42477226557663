import React from 'react'
import GalleryImagesPolicy from './GalleryImagesPolicy'

const galleryImagesPolicyObject = {
    mainTitle: 'Gallery images policy',
    mainSubTitle: 'Service summary:',
    mainDescription:
        'To preserve artistic integrity and comply with copyright laws, our gallery showcases images that align with established guidelines. We prioritize original content and public domain works, avoiding likenesses of recognizable copyrighted characters or contemporary artist styles.',
    sections: [
        {
            sectionTitle: 'File format:',
            subSections: [
                {
                    title: 'Supported formats:',
                    description: [
                        'Only the following file types are accepted: JPEG (or JPG), WEBP, PNG, and HEIC (or HEIF).',
                        'Ensure that your images are saved in these formats before uploading. Unsupported formats (e.g., TIFF, BMP, RAW) will be rejected.',
                    ],
                },
            ],
        },
        {
            sectionTitle: 'Image size:',
            sectionList: [
                {
                    boldText: 'Maximum file size:',
                    text: 'Each image must not exceed 5MB in size to ensure fast loading times and optimal performance on both web and mobile platforms. Larger images will be rejected.',
                },
                {
                    boldText: 'Compression:',
                    text: 'We recommend compressing images before uploading to balance quality and file size.',
                },
            ],
        },
        {
            sectionTitle: 'Content',
            descriptionBoldText: 'Relevance:',
            description:
                'All images must be relevant to the context of the gallery and the purpose it serves. Irrelevant images that do not align with the gallery’s theme may be removed.',
        },
        {
            sectionTitle: 'No offensive material.',
            sectionTitleDescription: 'Images must not contain:',
            sectionList: [
                {
                    boldText: 'Hate speech, violent, or offensive content.',
                },
                {
                    boldText: 'Sexually explicit material',
                    text: 'or nudity.',
                },
                {
                    boldText: 'Illegal or harmful activities',
                    text: '(e.g., drug use, weapons).',
                },
                {
                    boldText: 'Derogatory content',
                    text: 'aimed at any individual, group, or community.',
                },
            ],
        },
        {
            sectionTitle: 'Respect for privacy:',
            sectionList: [
                {
                    text: 'Avoid uploading images that contain private or personal information of others (e.g., addresses, contact details, personal identification numbers) without consent.',
                },
            ],
        },
        {
            sectionTitle: 'No copyright violations: ',
            sectionList: [
                {
                    text: 'Upload only images that you own or have permission to use.',
                },
                {
                    text: 'Images from other sources should not be uploaded unless they are royalty-free, public domain, or you have obtained the appropriate rights or licenses.',
                },
                {
                    text: 'Be mindful of using images from search engines or social media, which are often copyrighted.',
                },
            ],
        },
        {
            sectionTitle: 'Watermarks and logos:',
            sectionList: [
                {
                    text: 'Avoid images that are heavily watermarked, as they may distract from the visual quality of the gallery. Small, unobtrusive watermarks are acceptable if necessary for brand identification.',
                },
            ],
        },
        {
            sectionTitle: 'Quality standards:',
            sectionList: [
                {
                    text: 'Ensure the image is high quality and not blurry, pixelated, or poorly lit. Low-quality images will be flagged for removal.',
                },
                {
                    text: 'Avoid excessive use of text within images, as this can disrupt the visual flow of the gallery.',
                },
            ],
        },
        {
            sectionTitle: 'Respectful to community standards:',
            sectionList: [
                {
                    text: 'All content must align with our community standards, respecting cultural, ethical, and social norms. Content that is deemed divisive or inappropriate will be reviewed and removed.',
                },
            ],
        },
        {
            sectionTitle: 'Moderation rights:',
            description:
                'Our moderation team reserves the right to review, approve, or remove any images that do not meet these guidelines or that violate our and',
            sectionLinkText: 'Terms of Service.',
            sectionLink: 'https://skillndepth.com/terms-of-service.html',
        },
    ],
}

const GalleryImagesPolicyPage = () => {
    return (
        <GalleryImagesPolicy
            galleryImagesPolicyObject={galleryImagesPolicyObject}
        />
    )
}

export default GalleryImagesPolicyPage

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { BUTTON_SIZE, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'

import Button from 'components/Button'

const ActionButton = ({
    title: actionTitle,
    handleAction: action,
    icon: iconName,
    label: actionLabel,
    iconColor,
    iconPosition,
    iconSize,
    btnClass,
    buttonSize,
    tooltip: iconTooltip,
    confirmationMessage: actionConfirmationMessage,
    customConfirmationMessage,
    item,
    itemIndex,
    stopPropagation,
    textColor,
    isDisabled,
    switchableAction,
    translateMessage,
}) => {
    const t = useTranslate()

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const {
        icon = iconName,
        tooltip = iconTooltip,
        label = actionLabel,
        handleAction = action,
        title = actionTitle,
        confirmationMessage = actionConfirmationMessage,
    } = switchableAction ? switchableAction(item) : {}

    const disabled = isDisabled ? isDisabled(item) : false

    const handleClick = (event) => {
        stopPropagation && event.stopPropagation()

        if (disabled) return

        if (confirmationMessage || customConfirmationMessage) {
            const message = customConfirmationMessage
                ? customConfirmationMessage(item)
                : confirmationMessage

            showConfirmationModal({
                title,
                message: message,
                translateMessage: translateMessage,
                handleConfirm: (event) => handleConfirm(event),
                handleCancel: (event) => handleCancel(event),
            })
        } else {
            handleAction(item, itemIndex)
        }
    }

    const handleConfirm = (event) => {
        stopPropagation && event.stopPropagation()
        closeConfirmationModal()
        handleAction(item, itemIndex)
    }

    const handleCancel = (event) => {
        stopPropagation && event.stopPropagation()
        closeConfirmationModal()
    }

    if (!handleAction) return null

    return (
        <Button
            label={label}
            onClick={handleClick}
            tooltip={tooltip && !label && !disabled ? tooltip : ''}
            icon={icon}
            iconColor={iconColor}
            btnClass={btnClass}
            buttonSize={buttonSize}
            iconPosition={iconPosition}
            iconSize={iconSize}
            gap={10}
            textColor={textColor}
            disabled={disabled}
        />
    )
}

ActionButton.propTypes = {
    title: PropTypes.string,
    handleAction: PropTypes.func,
    icon: PropTypes.string,
    label: PropTypes.string,
    iconColor: PropTypes.string,
    iconPosition: PropTypes.string,
    iconSize: PropTypes.number,
    btnClass: PropTypes.string,
    buttonSize: PropTypes.string,
    tooltip: PropTypes.string,
    confirmationMessage: PropTypes.string,
    customConfirmationMessage: PropTypes.func,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    itemIndex: PropTypes.number,
    stopPropagation: PropTypes.bool,
    textColor: PropTypes.string,
    isDisabled: PropTypes.func,
    switchableAction: PropTypes.func,
    translateMessage: PropTypes.bool,
}

ActionButton.defaultProps = {
    item: null,
    btnClass: BUTTON_STATUS.TERTIARY,
    buttonSize: BUTTON_SIZE.MEDIUM,
}

export default ActionButton

import { useTranslate } from 'react-polyglot'
import React from 'react'

const StatisticsItem = ({label, value}) => {
    const t = useTranslate()

    return (
        <div className={'column statistics-item'}>
            <p className="a-mediumText a-lightText -opacity-60 -about">{t(label)}</p>
            <span className="a-bodyTextMedium">{value}</span>
        </div>
    )
}

export default StatisticsItem
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import {
    getIsDateSameOrAfter,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    getTomorrowDate,
    MOMENT_FORMATS,
} from 'services/moment.service'

import { YES_NO_OPTIONS } from 'constants/constants'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    FILE_UPLOAD_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'

import FocusError from 'components/FocusError'
import {
    DateTimeField,
    FileUploadField,
    InputField,
    RadioGroupField,
    SelectField,
} from 'components/formFields'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import TextAreaField from 'components/formFields/TextAreaField'

const PersonalEquipmentForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        equipmentType: initialData?.equipmentType ?? null,
        equipmentBrand: initialData?.equipmentBrand ?? null,
        model: initialData?.model ?? '',
        serialNumber: initialData?.serialNumber ?? '',
        dateOfManufacture: initialData?.dateOfManufacture ?? '',
        datePutInService: initialData?.datePutInService ?? '',
        removedFromService:
            !initialData || initialData.removedFromService === false ? 0 : 1,
        dateRemovedFromService: initialData?.dateRemovedFromService ?? '',
        files: initialData?.files ?? [],
        description: initialData?.description ?? null,
    }

    const requiredMessage = t('form.error.required')
    const descriptionMaximumCharacters = `${t('form.error.descriptionCannotExceed')} 250 ${t(
        'form.error.characters'
    )}`

    const validation = Yup.object({
        equipmentType: Yup.object().required(requiredMessage),
        equipmentBrand: Yup.object().required(requiredMessage),
        description: Yup.string().trim().max(250, descriptionMaximumCharacters),
        model: Yup.string().trim().required(requiredMessage),
        serialNumber: Yup.string().trim().required(requiredMessage),
        dateOfManufacture: Yup.string()
            .nullable()
            .test('dateOfManufacture', (value, { parent, createError }) => {
                if (value === '' || value === null || value === undefined) {
                    return true // Allow empty string, null, or undefined
                }
                if (
                    getIsDateSameOrBefore(
                        value,
                        getTodaysDate(),
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.dateOfManufactureInFuture'),
                    path: 'dateOfManufacture',
                })
            }),
        datePutInService: Yup.string()
            .nullable()
            .test('datePutInService', (value, { parent, createError }) => {
                if (value === '' || value === null || value === undefined) {
                    return true // Allow empty string, null, or undefined
                }

                if (
                    !getIsDateSameOrBefore(
                        value,
                        getTodaysDate(),
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return createError({
                        message: t('form.error.datePutInServiceInFuture'),
                        path: 'datePutInService',
                    })
                }

                if (!parent.dateOfManufacture) {
                    return true
                }

                if (
                    getIsDateSameOrAfter(
                        value,
                        parent.dateOfManufacture,
                        MOMENT_FORMATS.DATE_API
                    )
                ) {
                    return true
                }

                return createError({
                    message: t('form.error.datePutInService'),
                    path: 'datePutInService',
                })
            }),
        removedFromService: Yup.boolean().required(requiredMessage),
        dateRemovedFromService: Yup.date().when('removedFromService', {
            is: (val) => val === true,
            then: () =>
                Yup.date()
                    .test(
                        'dateRemovedFromService',
                        (value, { parent, createError }) => {
                            if (
                                getIsDateSameOrBefore(
                                    value,
                                    parent.datePutInService,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return createError({
                                    message: t(
                                        'form.error.removeBeforePutToService'
                                    ),
                                    path: 'dateRemovedFromService',
                                })
                            }
                            if (
                                getIsDateSameOrBefore(
                                    value,
                                    parent.dateOfManufacture,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return createError({
                                    message: t(
                                        'form.error.removeBeforeDateOfManufacture'
                                    ),
                                    path: 'dateRemovedFromService',
                                })
                            }
                            return true
                        }
                    )
                    .test(
                        'dateRemovedFromService',
                        (value, { parent, createError }) => {
                            if (
                                getIsDateSameOrBeforeCurrentDate(
                                    value,
                                    MOMENT_FORMATS.DATE
                                )
                            ) {
                                return true
                            }
                            return createError({
                                message: t('form.error.dateRemovedFromService'),
                                path: 'dateRemovedFromService',
                            })
                        }
                    )
                    .required(requiredMessage),
            otherwise: () => Yup.string().notRequired(),
        }),
        files: Yup.array(),
    })

    const onSubmit = async (formData) => {
        try {
            await handleSubmit({
                ...formData,
                removedFromService: !!formData.removedFromService,
                user: { id: userId, entityType: ENTITIES.USER },
            })
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const minDateRemovedFromService = (datePutInService, dateOfManufacture) => {
        const minDate = datePutInService
            ? getTomorrowDate(datePutInService)
            : dateOfManufacture
            ? getTomorrowDate(dateOfManufacture)
            : null

        return minDate
    }

    const maxDateOfManufacture = (datePutInService, dateRemovedFromService) => {
        const maxDate = datePutInService
            ? new Date(datePutInService)
            : dateRemovedFromService
            ? new Date(dateRemovedFromService)
            : getTodaysDate()

        return maxDate
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({
                values: {
                    removedFromService,
                    dateOfManufacture,
                    datePutInService,
                    dateRemovedFromService,
                },
                setFieldValue,
                isSubmitting,
            }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title={title || ''}
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.saveEquipment',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _m6">
                                    <SelectField
                                        name="equipmentType"
                                        entityType={ENTITIES.EQUIPMENT_TYPE}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="equipmentBrand"
                                        entityType={ENTITIES.EQUIPMENT_BRAND}
                                        required
                                        searchable
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <InputField
                                        name="model"
                                        placeholder="form.placeholder.equipmentModel"
                                        label="form.label.equipmentModel"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <InputField name="serialNumber" required />
                                </div>

                                <div className="_12 _m6 ">
                                    <DateTimeField
                                        name="dateOfManufacture"
                                        dateFormat="MMM/y"
                                        monthPicker
                                        maxDate={maxDateOfManufacture(
                                            datePutInService,
                                            dateRemovedFromService
                                        )}
                                    />
                                </div>

                                <div className="_12 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="datePutInService"
                                        dateFormat="MMM/y"
                                        monthPicker
                                        maxDate={
                                            dateRemovedFromService
                                                ? new Date(
                                                      dateRemovedFromService
                                                  )
                                                : getTodaysDate()
                                        }
                                        minDate={
                                            dateOfManufacture
                                                ? new Date(dateOfManufacture)
                                                : new Date(dateOfManufacture)
                                        }
                                    />
                                </div>

                                <div className="_12 _m6">
                                    <RadioGroupField
                                        name="removedFromService"
                                        label="form.label.removedFromService"
                                        defaultOptions={YES_NO_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                        onUpdate={(newValue) => {
                                            setFieldValue(
                                                'removedFromService',
                                                newValue
                                            )
                                            if (newValue === 0) {
                                                setFieldValue(
                                                    'dateRemovedFromService',
                                                    null
                                                )
                                            }
                                        }}
                                    />
                                </div>

                                <div className="_12 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="dateRemovedFromService"
                                        required={removedFromService === 1}
                                        disabled={removedFromService === 0}
                                        dateFormat="MMM/y"
                                        monthPicker
                                        minDate={minDateRemovedFromService(
                                            datePutInService,
                                            dateOfManufacture
                                        )}
                                        maxDate={getTodaysDate()}
                                    />
                                </div>

                                <div className="_12">
                                    <TextAreaField
                                        name="description"
                                    />
                                </div>
                            </div>

                            <div className="_w">
                                <Separator />
                                <FileUploadField
                                    name="files"
                                    label="form.label.uploadMaintenanceRecord"
                                    type={FILE_UPLOAD_TYPE.FILE}
                                />
                            </div>

                            {isSubmitting && <Loader />}
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default PersonalEquipmentForm

import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'

import FilterIconButton from 'components/FilterIconButton'
import SearchAndFilter from 'components/SearchAndFilter'

const TableFilters = ({ FilterComponent, filters, filtersKey, searchKey }) => {
    const [open, setOpen] = useState(false)

    const { activeFilters } = useFetchActiveFilters(filters, filtersKey)

    return (
        <SearchAndFilter
            searchPlaceholder="form.placeholder.search"
            activeFilters={activeFilters}
            filters={filters}
            searchKey={searchKey}
            showFilterButton={!!FilterComponent}
            tableFilters={true}
        >
            <Fragment>
                <FilterIconButton setOpen={setOpen} />
                {open && (
                    <FilterComponent
                        open={open}
                        setOpen={setOpen}
                        activeFilters={activeFilters}
                    />
                )}
            </Fragment>
        </SearchAndFilter>
    )
}

TableFilters.propTypes = {
    FilterComponent: PropTypes.elementType,
    filters: PropTypes.object,
    filtersKey: PropTypes.string,
    searchKey: PropTypes.string,
}

export default TableFilters

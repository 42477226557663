import { ROLES, USER_SUBSCRIPTION_TYPE } from 'constants/enums'

const {
    ROLE_SUPER_ADMIN,
    ROLE_DIVER,
    ROLE_COMPANY_ADMIN,
    ROLE_DIVING_CONTRACTOR,
    ROLE_CLIENT_ORGANIZATION_ADMIN,
} = ROLES

export const translateRoleOptions = (item, translation) => {
    switch (item?.name || item) {
        case ROLE_SUPER_ADMIN.name:
            return translation('general.superAdmin')
        case ROLE_DIVER.name:
            return translation('general.diver')
        case ROLE_DIVING_CONTRACTOR.name:
            return translation('general.divingContractor')
        case ROLE_COMPANY_ADMIN.name:
            return translation('general.companyAdmin')
        case ROLE_CLIENT_ORGANIZATION_ADMIN.name:
            return translation('general.clientOrganizationAdmin')
        default:
            return item?.name || item || ''
    }
}

export const translatePackagesOptions = (item, translation) => {
    return item ? translation(`general.${item.key}`) : ''
}

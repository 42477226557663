import DropdownActions from 'components/DropdownActions'
import Icon from 'components/Icon'
import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'

const StatusActions = ({ actions, item, statuses }) => {
    const statusPreview = (
        <div className="a-status a-captionsTextRegular -green aligned-center">
            <span>{statuses[0]?.name}</span>
            <Icon
                name={ICONS.CHEVRON_DOWN}
                size={ICON_SIZE.SIZE16}
                color={COLORS.GREEN}
            />
        </div>
    )

    return (
        <DropdownActions
            actions={actions}
            item={item}
            icon={statusPreview}
            customClass="status-dropdown"
        />
    )
}

export default StatusActions

import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import axios, { POST } from 'utils/axiosClient'

import { AlertContext } from 'contexts/AlertContext'

import REGEXP from 'constants/regex'
import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import FocusError from 'components/FocusError'
import { InputField } from 'components/formFields'
import Loader from 'components/Loader'

const IndividualAccountChangePassword = ({ open, setOpen, user }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        newPassword: '',
        confirmNewPassword: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        newPassword: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .matches(REGEXP.PASSWORD, t('form.error.mustContain'))
            .required(requiredMessage),
        confirmNewPassword: Yup.string()
            .trim()
            .required(requiredMessage)
            .oneOf(
                [Yup.ref('newPassword'), null],
                t('form.error.passwordsMustMatch')
            ),
    })

    const handleSubmit = async ({ newPassword }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await axios(
                POST,
                `/${ENTITIES.USER}/${ENTITIES.ADMIN}/${ENTITIES.CHANGE_PASSWORD}/${user.id}`,
                { newPassword }
            )
            setAlert(
                t('message.successfullyChangedUserPassword'),
                ALERT_TYPES.SUCCESS
            )
            setOpen(false)
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.changeUserPassword"
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                    >
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <div className="_12 -mb5">
                                    <InputField
                                        name="newPassword"
                                        type={INPUT_FILED_TYPE.PASSWORD}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                                <div className="_12 -mb5">
                                    <InputField
                                        name="confirmNewPassword"
                                        type={INPUT_FILED_TYPE.PASSWORD}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

IndividualAccountChangePassword.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    user: PropTypes.object,
}

export default IndividualAccountChangePassword

import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { deleteEntityService } from 'services/entity.service'
import { removeSecondsFromTime } from 'utils/timeFormatter'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
} from 'constants/enums'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import CardPlaceholder from 'components/CardPlaceholder'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import Button from 'components/Button'
import CardHeader from 'components/card/CardHeader'
import CardFooter from 'components/card/CardFooter'

const SurfaceDecoEventList = ({
    data,
    fetchData,
    isLoading,
    setEditItem,
    hideActions,
    unitImperial,
    messages,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { currentUser } = useContext(CurrentUserContext)

    const isImperialUnit = unitImperial ?? currentUser.userSetting.unitImperial

    const unitOfMeasurementDepth = isImperialUnit
        ? 'form.label.ft'
        : 'form.label.m'

    const handleDelete = async ({ id }) => {
        try {
            await deleteEntityService(ENTITIES.SURFACE_DECOMPRESSION_EVENT, id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    if (isLoading && data.length === 0 && !hideActions) {
        return <CardPlaceholder />
    }

    const getGasContents = (name, helium, nitrogen, oxygen) => {
        let arr = []
        if (helium) arr.push(`Helium ${helium}%`)
        if (nitrogen) arr.push(`Nitrogen ${nitrogen}%`)
        if (oxygen) arr.push(`Oxygen ${oxygen}%`)
        const composition = arr.length > 0 ? ` (${arr.join(', ')})` : ''
        return `${name}${composition}`
    }

    return (
        <div className="_wr">
            <div className="_w justify-center">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            !hideActions ? '_12 _l6 -mt20' : 'fullWidth -mb20'
                        }`}
                    >
                        <CardContainer>
                            <CardHeader
                                title={messages.title}
                                item={item}
                                actions={
                                    hideActions
                                        ? []
                                        : [
                                              {
                                                  handleAction: setEditItem,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  label: 'button.editEvent',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  label: 'button.deleteEvent',
                                                  title: messages.delete,
                                                  confirmationMessage:
                                                      messages.deleteAreYouSure,
                                                  textColor: '-redText',
                                              },
                                          ]
                                }
                            />
                            {item.surfaceInterval && (
                                <Info
                                    label="form.label.surfaceInterval"
                                    value={
                                        item.surfaceInterval +
                                        t('form.label.min')
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.gasContents && (
                                <Info
                                    label="form.label.gasContents"
                                    value={getGasContents(
                                        item.gasContents.name,
                                        item.helium,
                                        item.nitrogen,
                                        item.oxygen
                                    )}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.timeReachedDepth && (
                                <Info
                                    label="form.label.timeReachedDepthHours"
                                    value={removeSecondsFromTime(
                                        item.timeReachedDepth
                                    )}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.decoDepthInChamber !== undefined && (
                                <Info
                                    label="form.label.decoDepthInChamber"
                                    value={
                                        item.decoDepthInChamber +
                                        t(unitOfMeasurementDepth)
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {(item.totalTimeOnOxygenHours !== undefined ||
                                item.totalTimeOnOxygenMinutes !==
                                    undefined) && (
                                <Info
                                    label="form.label.totalTimeOnOxygenHours"
                                    value={
                                        (item.totalTimeOnOxygenHours || 0) +
                                        'h ' +
                                        (item.totalTimeOnOxygenMinutes || 0) +
                                        'min'
                                    }
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            {item.timeReachedSurface && (
                                <Info
                                    label="form.label.timeReachedSurfaceHours"
                                    value={removeSecondsFromTime(
                                        item.timeReachedSurface
                                    )}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="form.label.timeInChamberHours"
                                value={
                                    item.timeInChamberHours +
                                    'h ' +
                                    item.timeInChamberMinutes +
                                    'min'
                                }
                                withBorder={item.comment ? true : false}
                                withSpaceBetween
                            />
                            {item.comment && (
                                <Info
                                    label="form.label.comment"
                                    value={item.comment}
                                    column
                                />
                            )}
                            {!hideActions && (
                                <CardFooter withTopMargin>
                                    <Button
                                        label={messages.view}
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition={ICON_POSITION.RIGHT}
                                        onClick={() => setEditItem(item)}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                    />
                                </CardFooter>
                            )}
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SurfaceDecoEventList

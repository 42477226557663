import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 0.9.1',
    introText:
        'We are excited to announce the release of version 0.9.1 of Skill N Depth. This update brings several new features and enhancements designed to improve your experience and streamline the use of our platform.',
    items: [
        {
            title: 'Chat feature',
            description:
                'All users can now engage in seamless communication with each other through our newly implemented chat system. This feature supports 1-on-1 chats, group chats, file sharing, threads, and replies, ensuring comprehensive and flexible interaction within the platform.',
            icon: ICONS.CHAT,
        },
        {
            title: 'Dive record improvements',
            description:
                'We have made significant enhancements to the dive record creation process based on user feedback. Steps 1, 2, 4, and 5 have been refined to adjust the mandatory and optional fields, making it easier and more intuitive to complete a dive record.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Approval level 1',
            description:
                'Historical dive records can now be approved by individuals who do not have a Skill N Depth account. This new feature involves the creation of a unique, shareable link that can be sent to an external person. They can then view and approve the dive record, facilitating broader and more accessible verification.',
            icon: ICONS.VERIFICATION_OUTLINE,
        },
        {
            title: 'Short URLs',
            description:
                'Profile pages now feature short, user-friendly URLs, simplifying the process of sharing profiles with potential employers and colleagues. These URLs enhance the visibility and accessibility of user profiles.',
            icon: ICONS.LINK,
        },
        {
            title: 'Profile page with hero image',
            description:
                'Users can now personalize their profile pages by uploading a custom hero image. This feature includes the ability to crop the image to fit, providing a more engaging and visually appealing profile.',
            icon: ICONS.USER,
        },
        {
            title: 'Dive record numbering',
            description:
                'Each diver now has an individualized numbering system for their dive records. This unique identifier for each record helps in better organizing and tracking dive logs on a per-user basis.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Hide a project',
            description:
                'Companies can now choose to hide their projects. When a project is hidden, it remains visible only to people employed on the project, enhancing privacy and project confidentiality.',
            icon: ICONS.SHOW_PASSWORD,
        },
        {
            title: 'Improved project page with map integration',
            description:
                'The project page now includes map integration, providing a visual representation of project locations. This enhancement aids in better planning and coordination by offering geographic context to project activities.',
            icon: ICONS.MAP_ICON,
        },
        {
            title: 'Bug fixes and performance improvements',
            description:
                'We have addressed various bugs and implemented numerous performance enhancements across the platform. These improvements ensure greater stability, reliability, and a smoother user experience.',
            icon: ICONS.BUG_FIXES_AND_PERFORMANCE,
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingItems: [
        {
            title: 'Overhaul of project recruitment process for companies',
            description:
                'We are revamping the project recruitment process to streamline job postings, application reviews, and candidate selection. This overhaul aims to improve efficiency and effectiveness in recruiting the best talent for projects.',
        },
        {
            title: 'Date picker upgrade',
            description:
                'Our upcoming date picker upgrade will provide a more user-friendly and efficient way to select dates. The improved UI will ensure a smoother and faster date selection process.',
        },
        {
            title: 'User education section reorganization',
            description:
                'We are reorganizing the user education section by splitting it into categories. This reorganization will improve the ease of use and navigation within the education resources, making it simpler to find and utilize the information.',
        },
        {
            title: 'Verification showcase',
            description:
                'Users will soon be able to showcase the number of verified dive records on their profile page. This feature will highlight their verified achievements and enhance their professional credibility.',
        },
        {
            title: 'User badges',
            description:
                'We will introduce user badges, which will be awarded based on profile data. These badges will provide a visual representation of individual achievements and milestones. This feature will expand over time as we explore new areas of recognition.',
        },
        {
            title: 'Export CV',
            description:
                'Both users and companies will soon be able to export their profiles into a CV format PDF file. This feature will utilize all profile data to generate a comprehensive CV, making it easier to present professional information in a standardized format.',
        },
    ],
    outroText:
        'We trust that these updates will greatly improve your experience with Skill N Depth. We highly value your feedback and are dedicated to ongoing enhancement of our platform with your insights. Thank you for helping us refine and perfect Skill N Depth.',
}

const Release090 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release090

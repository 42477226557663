import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'

import useQueryParams from 'hooks/useQueryParams'

import { getTomorrowDate } from 'services/moment.service'

import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import { DateTimeField, InputField, SelectField } from 'components/formFields'
import Separator from 'components/Separator'

const CompanyAccountFilters = ({ open, setOpen, activeFilters }) => {
    const t = useTranslate()

    const { replaceFilterParams } = useQueryParams()

    const emptyValues = {
        size: null,
        tinVat: '',
        registeredAt: { after: null, before: null },
    }

    const initialValues = {
        size: activeFilters.size ?? emptyValues.size,
        tinVat: activeFilters.tinVat ?? emptyValues.tinVat,
        registeredAt: activeFilters.registeredAt ?? emptyValues.registeredAt,
    }

    const handleFilter = (filters) => {
        replaceFilterParams(filters)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleFilter}>
            {({ values, isSubmitting, setFieldValue, setTouched }) => (
                <Form>
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.filterCompanies"
                        isSubmitting={isSubmitting}
                        values={values}
                        smallModalWithDropdowns
                        buttons={{
                            middleBtn: {
                                handleClick: () =>
                                    handleReset(
                                        values,
                                        setFieldValue,
                                        setTouched
                                    ),
                            },
                            nextBtn: {
                                label: 'button.filter',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _m6">
                                    <SelectField
                                        name="size"
                                        label="form.label.companySize"
                                        placeholder="form.placeholder.companySize"
                                        entityType={ENTITIES.COMPANY_SIZE}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="tinVat" />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.companyRegistered')}
                                </span>
                                <div className="_12 _m6">
                                    <DateTimeField
                                        name="registeredAt.after"
                                        label="form.label.from"
                                        maxDate={
                                            values.registeredAt.before
                                                ? new Date(
                                                      values.registeredAt.before
                                                  )
                                                : null
                                        }
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <DateTimeField
                                        name="registeredAt.before"
                                        label="form.label.to"
                                        minDate={
                                            values.registeredAt.after
                                                ? getTomorrowDate(
                                                      values.registeredAt.after
                                                  )
                                                : null
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

CompanyAccountFilters.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    activeFilters: PropTypes.object,
}

export default CompanyAccountFilters

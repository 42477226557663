import React from 'react'

import { APP_LINK } from 'constants/constants'

import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.verifyIdentityUsingPersona',
    videoLink: 'https://embed.app.guidde.com/playbooks/m7rSVTru7zn1TSTY7Ym3Jb',
    description: 'howTo.verifyIdentityUsingPersonaDescription',
    goTo: false,
    steps: [
        {
            title: `Introduction`,
            description: `The first step in the process is to navigate to the
                        Skill N Depth platform. This is the starting point for
                        verifying your identity. Make sure you’re in a
                        comfortable setting with a reliable internet connection,
                        as you'll need to follow a few simple steps from here to
                        complete the process.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F1XbUBzGU54LxjUQh9k2GDj_doc.png?alt=media&token=7e11fe51-30f5-4400-bf66-a0b8e5c8cf2e',
        },
        {
            title: `Read the message`,
            description: `Once you're on the platform, you'll be greeted with an
                        important message about the verification process.
                        Identity verification through Persona involves checking
                        your government-issued ID and a selfie to ensure
                        everything matches. This process is crucial for
                        maintaining the integrity of your account, so please
                        take a moment to read and understand the message. You'll
                        need your ID and a device with a camera—either a laptop
                        or mobile phone will work.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FmVt6L4R2tqFmKzrzHPUXRt_doc.png?alt=media&token=03eef2e0-3bbe-4e78-83ae-d29067c7906e',
        },
        {
            title: `Click "Start verification process"`,
            description: `After you've read the introductory message and gathered
                        your materials, it’s time to get started. Click on the
                        "Start verification process" button.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F6wsUSXdVrh8kyUY7sHMnjR_doc.png?alt=media&token=be2530b8-06dc-43df-986f-daaa04632d00',
        },
        {
            title: `Click "Go to Persona"`,
            description: `After starting the process, you’ll be prompted to click
                        the "Go to Persona" button. If you prefer to switch
                        devices, you'll see a QR code that allows you to move
                        from your computer to your mobile phone, or vice versa.
                        But for now, we'll proceed on the device you're using.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FwAVqUGdSeuZS9v3eecUEjA_doc.png?alt=media&token=e5825b83-f988-4113-ba01-d2a952435cf0',
        },
        {
            title: `Click "Go to Persona"`,
            description: `Navigate to the next step by clicking Go to Persona
                        button.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F63rZvwCfqnJG67wHwoSbiN_doc.png?alt=media&token=5ef11c1d-4a8b-419d-a88d-b270db109754',
        },
        {
            title: `Switch to "inquiry.withpersona.com"`,
            description: `On the Persona page, you'll be greeted with a welcome
                        message. Please take the time to read it fully to
                        understand the process.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FaBRKwm43cUDWkJjaTXYKGH_doc.png?alt=media&token=f3daec6d-7396-48f1-abde-0b2376ab5361',
        },
        {
            title: `Click "Begin verifying"`,
            description: `Once you've read the welcome message and are ready to
                        proceed, click on the "Begin verifying" button to move
                        forward.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FeKXPh5GLZohytQWvshVyM4_doc.png?alt=media&token=a1817ea4-0ee4-4337-bd51-584fd506dadd',
        },
        {
            title: `Click "Select"`,
            description: `At this point, you’ll need to select the country where
                        your government-issued ID was issued. This ensures that
                        Persona knows which types of IDs to expect. After making
                        your selection, click "Select" to continue to the next
                        step.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F3d5CQguS72F3QgBzc9RUj1_doc.png?alt=media&token=a542b424-e290-4f45-98b5-262fd581a0f8',
        },
        {
            title: `Select ID type`,
            description: `Next, you’ll be asked to choose the type of ID you want
                        to use for verification. This could be a driver’s
                        license, passport, or another form of identification.
                        Select the ID that you have on hand, and then click on
                        "Driver License" (or the applicable ID type) to move
                        forward.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F4gr2Xbc8dZYit4JgNfp8Lu_doc.png?alt=media&token=cdb67605-94e2-4e23-8849-7096fdb94596',
        },
        {
            title: `Provide an image of the ID`,
            description: `Now, it’s time to upload an image of your ID. If you
                        already have a photo saved, you can upload it directly.
                        If not, you can take a new picture using the camera on
                        your device. Make sure the image is clear and all the
                        details on your ID are visible.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F2XpbXkDfs9u71FCeTELS6i_doc.png?alt=media&token=19270089-45f7-4c53-9d2d-a0975577c4e7',
        },
        {
            title: `Keep in mind the option to continue on another
                        device`,
            description: `Remember, if at any point you need to switch
                        devices—maybe your phone has a better camera, or you
                        need to move from your computer to your mobile—Persona
                        makes this easy. You can pick up right where you left
                        off on another device without starting over.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2Ff24Tj3w71SfmgC8MLXnrk3_doc.png?alt=media&token=bc452c20-3799-4e07-998a-253138718a36',
        },
        {
            title: `Select "Use this photo"`,
            description: `If you're happy with the photo you provided, click "Use
                        this photo" to confirm your selection.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FrmrNgShpczBWaJMjxCBccy_doc.png?alt=media&token=e042e0cd-6b47-4777-bcb1-8c6214228b97',
        },
        {
            title: `Click "Get started"`,
            description: `Now comes the part where you verify your identity with a
                        selfie. Position yourself in front of the camera, making
                        sure your face is centered. You may be asked to move
                        your head slightly to the left and right. When you’re
                        ready, click on "Get Started" to begin the selfie
                        capture. This step helps ensure that you are who you say
                        you are by comparing your live image to the photo on
                        your ID.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FcCyz7EVjmcuWuzu7jTGtdo_doc.png?alt=media&token=8bb88482-473f-4025-87ea-51fd46d50c3a',
        },
        {
            title: `Click "Done"`,
            description: `Great job! Once all the information has been submitted
                        and verified, you’ll see a "Done" button. Click it to
                        finish the process. If everything matches up correctly,
                        you’re all set! Persona will notify Skill N Depth of the
                        successful verification. If any issues arise, such as a
                        mismatch or suspected fraudulent activity, Persona will
                        alert Skill N Depth, and you’ll receive a message with
                        further instructions.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FwsHXuKf93BxFnyYK2zvdFS_doc.png?alt=media&token=b2b7b3cd-8b48-4411-9acd-09d7d8e8ca39',
        },
        {
            title: `Switch to "${APP_LINK.TEXT}"`,
            description: `Congratulations! You've successfully completed the
                        Persona verification process. Now, you'll be redirected
                        back to the Skill N Depth platform, where you can fully
                        enjoy all the features with your verified account.
                        Welcome to the complete Skill N Depth experience!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2FtAcEUatGn1GgdBgMJxxyfC_doc.png?alt=media&token=adcc1c83-2834-44a2-8d8f-6be4902b1dff',
        },
    ],
    endNote: `You did it! By following these steps, you've successfully verified your identity with Persona, ensuring the security and integrity of your Skill N Depth account. We hope this guide made the process clear and easy to follow. Now that you're fully verified, you can explore everything our platform has to offer with peace of mind. If you have any further questions or need assistance, don’t hesitate to reach out to our support team. Welcome aboard!`,
}

const VerifyIdentityUsingPersona = () => {
    return <HowToPage howToObject={howToObject} />
}

export default VerifyIdentityUsingPersona

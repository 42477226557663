import React from 'react'

import ActionButton from './ActionButton'
import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'

const TableActions = ({ actions, isTablet }) => {
    return (
        <div className="m-table__actions -actionButtons">
            {actions.length > 0 &&
                actions.map((action, index) => (
                    <div key={index} className="-ml20">
                        <ActionButton
                            key={index}
                            {...action}
                            label={!isTablet ? action.label : ''}
                            btnClass={BUTTON_STATUS.PRIMARY}
                            buttonSize={BUTTON_SIZE.LARGE}
                        />
                    </div>
                ))}
        </div>
    )
}

export default TableActions

import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useCanLeavePage from 'hooks/useCanLeavePage'
import useLogout from 'hooks/useLogout'

import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { ACTION } from 'constants/constants'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Avatar from './Avatar'
import { FeedbackModalContext } from '../contexts/FeedbackModalContext'

const AvatarDropdown = ({ open }) => {
    const t = useTranslate()

    const { canLeavePage, handleShowModal } = useCanLeavePage()
    const { handleLogout } = useLogout()

    const { showFeedbackModal } = useContext(FeedbackModalContext)

    const { currentUser, isDiver, isSuperAdmin, currentCompany } =
        useContext(CurrentUserContext)

    const handleOpenModal = (action, route) => {
        if (!canLeavePage && action === ACTION.NAVIGATE) {
            handleShowModal(route)
        } else if (!canLeavePage) {
            handleShowModal(null)
        }
    }

    const diverProfileRoute = `${ROUTES.DIVER_PROFILE}/${currentUser?.profileHash}`
    const organizationProfileRoute = `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${currentCompany?.profileHash}`

    if (!currentUser || (!isDiver && !isSuperAdmin && !currentCompany))
        return null

    return (
        <div className="m-avatar">
            <Avatar
                firstName={currentUser.firstName}
                lastName={currentUser.lastName}
                avatar={
                    currentUser?.thumbnails?.md ||
                    currentCompany?.thumbnails?.md
                }
                companyAvatar={!!currentCompany}
            />
            <i className={`a-chevron ${open ? '-up' : '-down'}`} />
            <div className={`m-avatar__popup ${open ? '-open' : ''}`}>
                {!isDiver && !isSuperAdmin && (
                    <Link
                        className="m-avatar__item a-mediumText"
                        to={canLeavePage ? organizationProfileRoute : undefined}
                        onClick={() =>
                            canLeavePage
                                ? undefined
                                : handleOpenModal(
                                      ACTION.NAVIGATE,
                                      organizationProfileRoute
                                  )
                        }
                    >
                        <Icon
                            name={ICONS.BUILDING}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE22}
                        />
                        {t('button.organizationProfile')}
                    </Link>
                )}
                {!isSuperAdmin && (
                    <Link
                        className="m-avatar__item a-mediumText"
                        to={canLeavePage ? diverProfileRoute : undefined}
                        onClick={() =>
                            canLeavePage
                                ? undefined
                                : handleOpenModal(
                                      ACTION.NAVIGATE,
                                      diverProfileRoute
                                  )
                        }
                    >
                        <Icon
                            name={ICONS.USER}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE22}
                        />
                        {t(
                            isDiver
                                ? 'button.profile'
                                : 'button.personalProfile'
                        )}
                    </Link>
                )}
                {!isSuperAdmin && (
                    <Link
                        className="m-avatar__item -logout a-mediumText"
                        to={ROUTES.SETTINGS}
                    >
                        <Icon
                            name={ICONS.SETTINGS}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE22}
                        />
                        {t('button.settingsAndPrivacy')}
                    </Link>
                )}
                {!isSuperAdmin && (
                    <Link
                        className="m-avatar__item -logout a-mediumText"
                        to={`${ROUTES.SETTINGS}/${ROUTES.ACCOUNT_MANAGEMENT}`}
                    >
                        <Icon
                            name={ICONS.ACCOUNT_MANAGEMENT}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE24}
                        />
                        {t('button.accountManagement')}
                    </Link>
                )}
                {!isSuperAdmin && (
                    <Link
                        className="m-avatar__item a-mediumText"
                        to={canLeavePage ? ROUTES.HELP_AND_INFO : undefined}
                        onClick={() =>
                            canLeavePage
                                ? undefined
                                : handleOpenModal(
                                      ACTION.NAVIGATE,
                                      ROUTES.HELP_AND_INFO
                                  )
                        }
                    >
                        <Icon
                            name={ICONS.HELP_AND_INFO}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE22}
                        />
                        {t('button.helpAndInfo')}
                    </Link>
                )}
                {!isSuperAdmin && (
                    <Link
                        className="m-avatar__item a-mediumText"
                        to={undefined}
                        onClick={() => showFeedbackModal()}
                    >
                        <Icon
                            name={ICONS.ROUNDED_EDIT}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE22}
                        />
                        {t('button.feedback')}
                    </Link>
                )}
                <Link
                    className="m-avatar__item -logout a-mediumText"
                    to={canLeavePage ? '#' : undefined}
                    onClick={() =>
                        canLeavePage
                            ? handleLogout()
                            : handleOpenModal(ACTION.LOGOUT)
                    }
                >
                    <Icon
                        name={ICONS.LOGOUT}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.logout')}
                </Link>
            </div>
        </div>
    )
}

AvatarDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default clickOutsideHOC(AvatarDropdown, true)

import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import axios, { POST } from 'utils/axiosClient'

import { AlertContext } from 'contexts/AlertContext'

import REGEXP from 'constants/regex'
import { ALERT_TYPES, ICON_SIZE, INPUT_FILED_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Modal from 'components/Modal'
import FocusError from 'components/FocusError'
import { InputField, SelectField } from 'components/formFields'
import Loader from 'components/Loader'

const IndividualAccountChangePhoneNumber = ({
    open,
    setOpen,
    user,
    fetchData,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        countryPhoneCode: user.countryPhoneCode ?? null,
        phoneNumber: user.phoneNumber ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        countryPhoneCode: Yup.object().required(requiredMessage),
        phoneNumber: Yup.string()
            .matches(REGEXP.DIGITS, 'Must be only digits')
            .test(
                'phoneNumber',
                'Must be maximum 15 digits',
                (val) => val.length <= 15
            )
            .typeError(t('form.error.invalidPhone'))
            .required(requiredMessage),
    })

    const handleSubmit = async (data, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await axios(
                POST,
                `/${ENTITIES.USER}/${ENTITIES.ADMIN}/${ENTITIES.CHANGE_PHONE_NUMBER}/${user.id}`,
                data,
                false
            )
            setAlert(
                t('message.successfullyChangedUserPhoneNumber'),
                ALERT_TYPES.SUCCESS
            )
            fetchData()
            setOpen(false)
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.changeUserPhoneNumber"
                        isSubmitting={isSubmitting}
                        smallModal
                        smallModalWithDropdowns
                        buttons={{
                            prevBtn: {
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                    >
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <div className="_12 -mb5">
                                    <div className="-phoneNumber fakeLabel -notFirst">
                                        <SelectField
                                            name="countryPhoneCode"
                                            label="form.label.userNewPhoneNumber"
                                            placeholder="form.placeholder.newPhoneNumber"
                                            entityType={ENTITIES.COUNTRY}
                                            displayAttribute="callingCode"
                                            searchAttribute="callingCode"
                                            iconAttribute="flag"
                                            params={{ sort: 'callingCode' }}
                                            searchable
                                            required
                                        />
                                        <InputField
                                            name="phoneNumber"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.phone"
                                            icon={ICONS.PHONE}
                                            iconColor={COLORS.DARK_BLUE_60}
                                            size={ICON_SIZE.SIZE20}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

IndividualAccountChangePhoneNumber.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    user: PropTypes.object,
    fetchData: PropTypes.func,
}

export default IndividualAccountChangePhoneNumber

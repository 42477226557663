import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import LinkWithIcon from 'components/LinkWithIcon'

const TabHeading = ({
    tabTitle,
    tab,
    myProfile,
    withoutForwardSlash,
    tabDescription,
}) => {
    const t = useTranslate()
    return (
        <div className="column fullWidth">
            <div className="aligned-center space-between -gap10 fullWidth">
                <span className="a-bodyTextMedium">{t(tabTitle)}</span>
                {myProfile && tab && (
                    <LinkWithIcon
                        icon={ICONS.DATA_ACCESS}
                        to={`${ROUTES.SETTINGS}/${ROUTES.DATA_ACCESS_CONTROL}${
                            withoutForwardSlash ? '' : '/'
                        }${tab}`}
                        linkText="general.manageDataAccess"
                    />
                )}
            </div>
            {tabDescription && (
                <span className="a-mediumText a-lightText -mt20">
                    {t(tabDescription)}
                </span>
            )}
        </div>
    )
}

TabHeading.propTypes = {
    tabTitle: PropTypes.string,
    tab: PropTypes.string,
    myProfile: PropTypes.bool,
    withoutForwardSlash: PropTypes.bool,
    tabDescription: PropTypes.string,
}

TabHeading.defaultProps = {
    myProfile: false,
    withoutForwardSlash: false,
}

export default TabHeading

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'

import useQueryParams from 'hooks/useQueryParams'

import { translateRoleOptions } from 'utils/translateOptionsHelper'

import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import { InputField, SelectField } from 'components/formFields'

const IndividualAccountFilters = ({ open, setOpen, activeFilters }) => {
    const { replaceFilterParams } = useQueryParams()

    const emptyValues = {
        firstName: '',
        lastName: '',
        currentTitle: '',
        userStatus: null,
        role: '',
    }

    const initialValues = {
        firstName: activeFilters.firstName ?? emptyValues.firstName,
        lastName: activeFilters.lastName ?? emptyValues.lastName,
        currentTitle: activeFilters.currentTitle ?? emptyValues.currentTitle,
        userStatus: activeFilters.userStatus ?? emptyValues.userStatus,
        role: activeFilters.role ?? emptyValues.role,
    }

    const handleFilter = (filters) => {
        replaceFilterParams(filters)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleFilter}>
            {({ values, isSubmitting, setFieldValue, setTouched }) => (
                <Form>
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.filterUsers"
                        isSubmitting={isSubmitting}
                        values={values}
                        smallModalWithDropdowns
                        buttons={{
                            middleBtn: {
                                handleClick: () =>
                                    handleReset(
                                        values,
                                        setFieldValue,
                                        setTouched
                                    ),
                            },
                            nextBtn: {
                                label: 'button.filter',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _m6">
                                    <InputField name="firstName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="lastName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="currentTitle"
                                        label="form.label.title"
                                        placeholder="form.placeholder.title"
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="userStatus"
                                        entityType={ENTITIES.USER_STATUSES}
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="role"
                                        entityType={ENTITIES.ROLE}
                                        translateItem={translateRoleOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

IndividualAccountFilters.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default IndividualAccountFilters

import React from 'react'

import { APP_LINK } from 'constants/constants'

import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.createAProject',
    videoLink: 'https://embed.app.guidde.com/playbooks/nvPwExdR8Uo9qzN8AsZVZA',
    description: `howTo.createAProjectDescription`,
    goTo: false,
    steps: [
        {
            title: `Introduction`,
            description: `First things first, open your browser and head over to
                        ${APP_LINK.TEXT}. This is where all the magic
                        happens. Once you’re on the site, you'll be ready to
                        start creating your new project.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2F4X3WQCvumvcxyjgtXUrjaL_doc.png?alt=media&token=af4cdebf-c1a6-428f-9fa9-fd15bdb03b73',
        },
        {
            title: `Click "Create project"`,
            description: `Once you’re logged in, find the “Create Project” button
                        and give it a click. This will open up a new form where
                        you can start entering details about your project. Think
                        of this as the foundation for everything else, so let's
                        make sure we start strong!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FbEQTzHs6zzB9YDVNUippTX_doc.png?alt=media&token=eba1499f-ee8e-4290-a6be-76205db758ae',
        },
        {
            title: `Enter general information`,
            description: `Now it’s time to enter some general information about
                        your project. This includes details like the project’s
                        status and name. While not all fields are required, we
                        highly recommend filling out as much as possible. The
                        more information you provide, the better your project
                        will stand out to potential employees.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fort4gbmpZ1LGAnhuAfdwH8_doc.png?alt=media&token=a8f0af52-141d-4b58-89ed-cf5fb7a7003f',
        },
        {
            title: `Hide project if needed`,
            description: `If you want to keep your project under wraps, you can
                        choose to hide it. When a project is hidden, only you
                        and the people you invite will be able to see it. This
                        is a great option if you're working on something
                        sensitive or just want to keep things private.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2F7AsrKuPDCdywQMSdP1opwp_doc.png?alt=media&token=46c6d644-6f5f-4bd5-8439-632f3f9ff489',
        },
        {
            title: `Enter date range`,
            description: `You can also define the start and end dates of your
                        project. This step is optional, but it provides
                        potential employees with more accurate information about
                        the timeline, which could help in attracting the right
                        candidates.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fug8ApnMNnm197qLw3SKSAY_doc.png?alt=media&token=d214a3b1-6c75-43e5-99e3-399c14bcc06f',
        },
        {
            title: `Enter geographical information`,
            description: `Next up, it’s time to add some geographical data. You’ll
                        need to enter details like the country, region, and
                        specific location of your project. One of the cool
                        features here is the ability to place a marker on a map,
                        giving you the option to pinpoint an exact or
                        approximate location.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FtC7RrQnAb6b3DnbcbxMtBB_doc.png?alt=media&token=3e956ec6-ab13-4350-86f6-855e4a009f0b',
        },
        {
            title: `Enter data about planned work`,
            description: `This section allows you to provide more details about
                        the actual work that will take place during the project.
                        Be as specific as possible to ensure that potential
                        employees know what to expect.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FbqnPv16Cww8iEoyDxbweP6_doc.png?alt=media&token=18dcfaa8-b4bf-428f-96fd-62c0e9fcad3e',
        },
        {
            title: `Enter details`,
            description: `Now, you’ll need to provide some additional details,
                        such as the name of the person in charge. This is the
                        only required field in this section, but feel free to
                        add more information if you have it.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fh74KdxqvztfGPMZAHjqtYg_doc.png?alt=media&token=d3887f6d-8350-4238-9280-d212accdcdc3',
        },
        {
            title: `Add positions`,
            description: `The last major step is all about positions. Start by
                        specifying the type of position and the number of
                        workers needed. You can enhance the job posting by
                        adding details like the pay rate, position description,
                        and required skills. The more information you provide,
                        the easier it will be to attract the right candidates.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fh74KdxqvztfGPMZAHjqtYg_doc.png?alt=media&token=d3887f6d-8350-4238-9280-d212accdcdc3',
        },
        {
            title: `Click "Add position"`,
            description: `Once you’ve entered all the necessary information for a
                        position, click “Add position.” This will save the
                        position to your project.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2F4fc12SS8e3pPdMGfmWmCeq_doc.png?alt=media&token=2bf99ec6-e1b7-4f0c-8085-97f3ba1b9630',
        },
        {
            title: `View added positions`,
            description: `After adding positions, you’ll be able to see them
                        listed at the bottom of the page. This gives you a
                        chance to review and make any necessary edits before
                        finalizing the project.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2FnasrS4STzzPSKJJ4jC56Ca_doc.png?alt=media&token=55a3efb8-7b91-4489-8dd4-3e752eab6fa5',
        },
        {
            title: `Click "Save project"`,
            description: `Finally, when everything looks good, click “Save
                        project.” Your project is now live and ready to start
                        attracting talent!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FnvPwExdR8Uo9qzN8AsZVZA%2Fai2jvGBx6U5wbyWWXZLR18_doc.png?alt=media&token=c04a2104-c010-4c70-83a7-7527fa07db76',
        },
    ],
    endNote: `And that’s it! You’ve successfully created a project on
            the Skill N Depth platform. With just a few steps,
            you’ve set the stage for finding the perfect team for
            your next big endeavor. If you ever need to make
            changes, remember that you can always go back and edit
            your project. Good luck, and happy hiring!`,
}

const CreateAProject = () => {
    return <HowToPage howToObject={howToObject} />
}

export default CreateAProject

import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { formatFromToValues } from '../common/projectFormatActions'

import { ALERT_TYPES } from 'constants/enums'

import AvailablePositionsForm from './AvailablePositionsForm'

const EditAvailablePositions = ({
    editItem,
    setEditItem,
    availablePositions,
    setAvailablePositions,
    editItemIndex,
    setEditItemIndex,
}) => {
    const { setAlert } = useContext(AlertContext)
    const t = useTranslate()

    const handleClose = () => {
        setEditItem(null)
        setEditItemIndex(null)
    }

    const handleSubmit = (values, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const newPositions = [...availablePositions]
            newPositions[editItemIndex] = {
                ...newPositions[editItemIndex],
                payRatePerDay:
                    !values.fromPayRate && !values.toPayRate
                        ? ''
                        : formatFromToValues(
                              values.fromPayRate,
                              values.toPayRate,
                              '$'
                          ),
                ...values,
            }
            setAvailablePositions(newPositions)

            setAlert(t('message.successPositionEdit'), ALERT_TYPES.SUCCESS)

            handleClose()
        } catch (error) {
            setAlert(t('message.errorPositionEdit'), ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div scroll-attribute="availablePositionsErrorScroll">
            <AvailablePositionsForm
                initialData={editItem}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                title={t('general.editProjectPosition')}
            />
        </div>
    )
}

export default EditAvailablePositions

import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Fragment } from 'react'

import ICONS from 'constants/icons'
import { TAB } from './constants/tabs'
import COLORS from 'constants/colors'

import TabNavigation from 'components/tabs/TabNavigation'
import CompanyInfo from './components/CompanyInfo'

const CompanyInformation = ({ myProfile, company, isVerified }) => {
    const t = useTranslate()

    return (
        <Fragment>
            <div className="-box">
                <CompanyInfo
                    myProfile={myProfile}
                    company={company}
                    isVerified={isVerified}
                />
            </div>

            <div className="-box -mt20 -mb20">
                <h3 className="a-bodyTextMedium -mb20">
                    {t('general.companyInformation')}
                </h3>

                <TabNavigation
                    tabs={[
                        {
                            key: TAB.ABOUT_COMPANY,
                            title: 'general.aboutCompany',
                            icon: `${ICONS.ABOUT_COMPANY}`,
                        },
                        {
                            key: TAB.DIVE_PROJECTS,
                            title: 'general.diveProjects',
                            icon: `${ICONS.DIVE_PROJECTS_OUTLINE}`,
                        },
                        {
                            key: TAB.FAVORITE_USERS,
                            title: 'general.favoriteUsers',
                            icon: `${ICONS.FAVORITES}`,
                            iconColor: COLORS.TRANSPARENT,
                            hidden: !myProfile,
                        },
                        {
                            key: TAB.GALLERY,
                            title: 'general.gallery',
                            icon: `${ICONS.GALLERY_SIDEBAR}`,
                        },
                        {
                            key: TAB.USER_MANAGEMENT,
                            title: 'general.userManagement',
                            icon: `${ICONS.USER_MANAGEMENT}`,
                            hidden: !myProfile,
                        },
                    ]}
                    tabQueryParam="tab"
                    columnView
                    scrollToTarget={false}
                />
            </div>
        </Fragment>
    )
}

CompanyInformation.propTypes = {
    myProfile: PropTypes.bool,
    company: PropTypes.object,
}

export default CompanyInformation

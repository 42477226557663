import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 1.4.0',
    introText:
        'We’re excited to bring you the latest updates with release 1.4.0, designed to make your experience smoother and more flexible. From introducing offline capabilities to enhancing data access and usability, here’s what’s new and what’s coming soon:',
    items: [
        {
            title: 'Offline mode',
            description:
                'The mobile app now supports offline mode, allowing you to continue using the app even without an active internet connection. While some features may have limitations, you can still add diving records on the go.',
            icon: ICONS.OFFLINE_MODE,
        },
        {
            title: 'Help and info section enhancements',
            description:
                'We’ve updated the Help and Info section with additional resources and clarity:',
            icon: ICONS.HELP_AND_INFO,
            descriptionList: [
                {
                    title: 'Dive record statuses',
                    description:
                        ' Understand the progress and status of your dive records.',
                },
                {
                    title: 'Dive certifications verification',
                    description: 'Guidance for verifying your certifications.',
                },
                {
                    title: 'Gallery image policy',
                    description:
                        'Clear policies for managing and sharing images.',
                },
                {
                    title: 'Offline mode help',
                    description:
                        'Get support for using the new offline feature.',
                },
            ],
        },
        {
            title: 'Optimizations and improvements',
            description:
                'Enjoy a more efficient and seamless experience with different optimisations across the platform.',
            icon: ICONS.BUG_FIXES_AND_PERFORMANCE,
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingSectionDescription: 'Here’s what’s on the horizon:',
    upcomingItems: [
        {
            title: 'Upload dive records as files:',
            description:
                'Simplify record-keeping by uploading your dive logs directly as files.',
        },
        {
            title: 'Feedback mechanism:',
            description:
                'Share your thoughts and help us improve the platform.',
        },
        {
            title: 'Total bottom times and days summary:',
            description: 'Get a comprehensive summary of your diving activity.',
        },
        {
            title: 'Dive records:',
            description:
                'Improved separation and filtering for better organization.',
        },
        {
            title: 'Dive training schools:',
            description:
                'Automatic population of school addresses for added convenience.',
        },
        {
            title: 'Data access:',
            description: 'Enhanced options to share contact information.',
        },
    ],
    outroText:
        'Thank you! Thank you for being a part of our growing community! These updates are driven by your feedback, and we’re committed to providing a platform that meets your needs. Stay tuned for more exciting updates in future releases!',
}

const Release140 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release140

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'

import useQueryParams from 'hooks/useQueryParams'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import TabNavigation from 'components/tabs/TabNavigation'
import Certificates from 'screens/diver/DiverOnboarding/TrainingRecordsOnboarding/components/Certificates'
import TrainingCourses from 'screens/diver/DiverOnboarding/TrainingRecordsOnboarding/components/TrainingCourses'
import FormalEducation from 'screens/diver/DiverOnboarding/TrainingRecordsOnboarding/components/FormalEducation'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
    DIVING_PERSONNEL_POSITIONS,
    PRIMARY_TRAINING_TYPE,
} from 'constants/enums'
import { TRAINING_RECORD_OPTIONS } from 'screens/diver/profile/diverInformation/constants/trainingRecordOptions'
import { useContext } from 'react'
import { ProfileContext } from 'screens/diver/profile/context/ProfileContext'
import RequestAccess from '../../RequestAccess'
import TabHeading from './TabHeading'

const TrainingRecord = ({
    userID,
    myProfile,
    profilePage,
    refetchData,
    user,
}) => {
    const t = useTranslate()
    const {
        profile: { positionsCodes },
    } = useContext(ProfileContext)
    const searchParams = useLocation().search
    const showActions = myProfile || !profilePage

    const { subTab } = useQueryParams()

    const {
        data: diveTrainings,
        meta,
        isLoading: isLoadingDiveTrainings,
        fetchData: fetchDiveTrainings,
    } = useFetchData(
        ENTITIES.TRAINING_COURSE,
        {
            include:
                'files,primaryTrainingType,primaryTrainingTypeCategory,diveSchool,trainingTypes,proficiencyLevel,branch,country,trainingCertificates,trainingCertificates.files',
            'user.id': userID,
            'primaryTrainingType.id':
                PRIMARY_TRAINING_TYPE.DIVE_SCHOOL_TRAINING,
        },
        true,
        false
    )

    const {
        data: vocationTrainings,
        isLoading: isLoadingVocationTrainings,
        fetchData: fetchVocationTrainings,
    } = useFetchData(
        ENTITIES.TRAINING_COURSE,
        {
            include:
                'files,primaryTrainingType,primaryTrainingTypeCategory,diveSchool,trainingTypes,proficiencyLevel,branch,country,trainingCertificates,trainingCertificates.files',
            'user.id': userID,
            'primaryTrainingType.id': PRIMARY_TRAINING_TYPE.OTHER_TRAINING,
        },
        true,
        false
    )

    const {
        data: trainingCertificates,
        isLoading: isLoadingCertificates,
        fetchData: fetchCertificates,
    } = useFetchData(
        ENTITIES.TRAINING_CERTIFICATE,
        {
            include: 'files',
            'user.id': userID,
        },
        true,
        false
    )

    const {
        data: formalEducation,
        isLoading: isLoadingFormalEducation,
        fetchData: fetchFormalEducation,
    } = useFetchData(
        ENTITIES.FORMAL_EDUCATION,
        {
            include: 'files',
            'user.id': userID,
        },
        true,
        false
    )

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    const hideFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id

    const userCanSeeDiveTraining = () => {
        const allowedPositions = [
            DIVING_PERSONNEL_POSITIONS.DIVE_SUPERVISOR.code,
            DIVING_PERSONNEL_POSITIONS.BELL_SAT_DIVER.code,
            DIVING_PERSONNEL_POSITIONS.SURFACE_DIVER.code,
        ]
        return positionsCodes?.some((pos) => allowedPositions.includes(pos))
    }

    const fetchData = () => {
        fetchDiveTrainings()
        fetchVocationTrainings()
        fetchCertificates()
        fetchFormalEducation()
    }

    const fetchDiveTrainingData = () => {
        fetchDiveTrainings()
        fetchCertificates()
    }

    const navTabs = [
        {
            key: TRAINING_RECORD_OPTIONS.FORMAL_EDUCATION,
            title: 'general.formalEducation',
            queryParams: searchParams,
        },
        {
            key: TRAINING_RECORD_OPTIONS.DIVE_TRAINING,
            title: 'general.diveTraining',
            queryParams: searchParams,
        },
        {
            key: TRAINING_RECORD_OPTIONS.VOCATION_TRAINING,
            title: 'general.vocationTraining',
            queryParams: searchParams,
        },
        {
            key: TRAINING_RECORD_OPTIONS.CERTIFICATES,
            title: 'general.certificates',
            queryParams: searchParams,
        },
    ]

    const getNavTabs = () => {
        if (userCanSeeDiveTraining()) {
            return navTabs
        }

        return navTabs.filter(
            (tab) => tab.key !== TRAINING_RECORD_OPTIONS.DIVE_TRAINING
        )
    }

    const renderTabContent = () => {
        if (subTab === TRAINING_RECORD_OPTIONS.CERTIFICATES) {
            return (
                <Certificates
                    data={trainingCertificates}
                    isLoading={isLoadingCertificates}
                    fetchData={fetchData}
                    profilePage={profilePage}
                    showActions={showActions}
                />
            )
        } else if (
            subTab === TRAINING_RECORD_OPTIONS.DIVE_TRAINING &&
            userCanSeeDiveTraining()
        ) {
            return (
                <TrainingCourses
                    data={diveTrainings}
                    isLoading={isLoadingDiveTrainings}
                    fetchData={fetchDiveTrainingData}
                    profilePage={profilePage}
                    showActions={showActions}
                    refetchData={refetchData}
                    addNewTitle={'general.addDiveTraining'}
                />
            )
        } else if (subTab === TRAINING_RECORD_OPTIONS.VOCATION_TRAINING) {
            return (
                <TrainingCourses
                    data={vocationTrainings}
                    isLoading={isLoadingVocationTrainings}
                    fetchData={fetchVocationTrainings}
                    profilePage={profilePage}
                    showActions={showActions}
                    refetchData={refetchData}
                    addNewTitle={'general.addVocationTraining'}
                />
            )
        } else {
            return (
                <FormalEducation
                    data={formalEducation}
                    isLoading={isLoadingFormalEducation}
                    fetchData={fetchData}
                    profilePage={profilePage}
                    showActions={showActions}
                    refetchData={refetchData}
                />
            )
        }
    }

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.TRAINING_RECORDS}
                title="general.educationAndTraining"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profile={user}
                checkAccessControl={fetchData}
                requestTime={meta.dataAccessRequestTime}
            />
        )
    }

    return (
        <div>
            <div className="-mb20">
                <TabHeading
                    tabTitle="general.educationAndTraining"
                    tab={ROUTES.TRAINING_RECORDS}
                    myProfile={myProfile}
                />
            </div>
            {hideFiles && (
                <p className="a-mediumText a-lightText -mt20 -mb20">
                    {t('general.noAccessToFiles')}
                </p>
            )}
            <TabNavigation tabs={getNavTabs()} tabQueryParam="subTab" />
            {renderTabContent()}
        </div>
    )
}

TrainingRecord.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
}

TrainingRecord.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default TrainingRecord

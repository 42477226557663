import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 0.9.0',
    introText:
        'Here are some items that have been changes in the newest update',
    items: [
        {
            title: 'New homepage UI',
            description:
                'We have redesigned the homepage based on user feedback, focusing on enhanced data visibility and usability. The new layout provides more width for the main content, offering a cleaner and more intuitive user experience.',
            icon: ICONS.UI_UX_IMPROVEMENTS,
        },
        {
            title: 'Expansion of data access control system',
            description:
                'Our data access control system has been significantly expanded, providing users with more granular control over their personal information. This enhancement ensures that users can easily manage and restrict access to their data, reinforcing our commitment to privacy and security.',
            icon: ICONS.DATA_ACCESS,
        },
        {
            title: 'Improvement to “Personal equipment“ section',
            description:
                'The “Personal equipment“ section has been revamped for better organization and usability. Users can now easily manage their equipment details, ensuring accurate tracking and reporting of their diving gear.',
            icon: ICONS.PERSONAL_EQUIPMENT,
        },
        {
            title: 'Complete rework of “Experience and skills upgrade” section',
            description:
                'We have overhauled the “Experience and skills upgrade” section based on extensive feedback from divers and diving organizations. This rework simplifies the process of logging new skills and experiences, making it more user-friendly and efficient.',
            icon: ICONS.SKILLS,
        },
        {
            title: 'Easy addition of dive records for existing projects',
            description:
                'Adding dive records to existing projects has been streamlined. Users can now quickly and effortlessly log their dives, reducing administrative overhead and improving record accuracy.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Optimization of listing and search functionality',
            description:
                'We have optimized our listing and search functionalities by enhancing performance and incorporating fuzzy search capabilities. This improvement ensures faster, more accurate searches, making it easier for users to find relevant information.',
            icon: ICONS.LISTING_AND_SEARCH,
        },
        {
            title: 'Dive records titling',
            description:
                'Dive records now include a standardized title format: “Project Name - Date of Dive - #ID.” This change improves organization and makes it easier to identify and retrieve specific dive records.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Enhanced country dropdown',
            description:
                'The country dropdown menu has been improved for better usability, making it easier to find and select the desired country quickly.',
            icon: ICONS.LOCATION,
        },
        {
            title: 'Application loading time optimization',
            description:
                'Significant optimizations have been made to reduce the overall loading times of the application, ensuring a smoother and more responsive user experience.',
            icon: ICONS.LOADER_ICON,
        },
        {
            title: 'Automated certificate renewal reminders',
            description:
                'We have added automated reminders for certificate renewals, ensuring that users are notified well in advance of expiration dates, and helping them maintain up-to-date certifications.',
            icon: ICONS.NOTIFICATION_BELL,
        },
        {
            title: 'Bug fixes and performance improvements',
            description:
                'Various bugs have been addressed, and numerous performance enhancements have been made across the platform to ensure stability and reliability.',
            icon: ICONS.BUG_FIXES_AND_PERFORMANCE,
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingItems: [
        {
            title: 'Approval of dive records by non-members',
            description:
                'Soon, dive records can be approved by users who are not members of the Skill N Depth platform, ensuring greater flexibility and accurate verification.',
        },
        {
            title: 'Customizable profile page with diver hero image',
            description:
                'Users will be able to customize their profile pages, including uploading a hero image, to better showcase their achievements and experiences',
        },
        {
            title: 'Improved project page with map integration',
            description:
                'The project page will feature map integration, providing a visual representation of project locations to enhance planning and coordination.',
        },
        {
            title: 'Easily shareable profile pages with short URLs',
            description:
                'Profile pages will have short, user-friendly URLs, making it easier to share profiles with potential employers and colleagues.',
        },
        {
            title: 'Overhaul of project recruitment process for companies',
            description:
                'We are revamping the project recruitment process for companies, streamlining job postings, application reviews, and candidate selection to improve efficiency.',
        },
    ],
    outroText:
        'We hope these updates enhance your experience with Skill N Depth and look forward to your feedback. As always, we are committed to continuous improvement and value your input in making our platform the best it can be.',
}

const Release090 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release090

import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    DIVE_RECORD_STATUS,
    DIVE_RECORD_TYPE,
    ICON_SIZE,
    INFO_VALUE_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { DIVE_RECORD_STEPS } from 'screens/diver/diveRecord/constants/diveRecordSteps'
import { formatUrl } from 'utils/jsonApiFormatters'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    createEntityService,
    deleteEntityService,
} from 'services/entity.service'
import { formatDate } from 'services/moment.service'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import VerificationLevelIcon from 'components/VerificationLevelIcon'
import CardFooter from 'components/card/CardFooter'
import CardHeader from 'components/card/CardHeader'
import VerificationSelection from './VerificationSelection'
import IconWithTooltip from 'components/icons/IconWithTooltip'

const DiveRecordListCard = ({ diveRecord, fetchData, sidebarList }) => {
    const { setAlert } = useContext(AlertContext)
    const { userId, isContractor, currentCompany } =
        useContext(CurrentUserContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const t = useTranslate()
    const navigate = useNavigate()

    const [openVerificationSelection, setOpenVerificationSelection] =
        useState(false)

    const copyDiveRecord = async ({ id }) => {
        try {
            await createEntityService(
                `${ENTITIES.COPY_DIVE_RECORD}/${id}`,
                null,
                false
            )
            fetchData()
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR, t)
        }
    }

    const projectId = diveRecord.projectId

    const creator = {
        id: diveRecord.creatorId,
        fullName: diveRecord.creatorFullName,
        avatarPath: diveRecord.creatorAvatarPath,
        profileHash: diveRecord.creatorProfileHash,
    }

    const urlParams = {
        role: diveRecord.diveRecordType,
        divingMode: diveRecord.divingModeId,
    }

    const restrictedDiveRecordAction = (
        title = 'general.diveRecordEditingRestricted',
        message = 'message.diveRecordCanOnlyBeEditedOnDevice'
    ) => {
        showConfirmationModal({
            title,
            customText: (
                <span className="a-bodyTextRegular">
                    {`${t(message)} `}
                    <span className="a-bodyTextBold">
                        {diveRecord.deviceName}
                    </span>
                    {` ${t('message.whereItWasOriginallyCreated')}`}
                </span>
            ),
            translateMessage: false,
            hideCancel: true,
            handleConfirm: () => closeConfirmationModal(),
        })
    }

    const editDiveRecord = () => {
        if (diveRecord.deviceId) {
            restrictedDiveRecordAction()
            return
        }

        const lastProgressStepRoute =
            DIVE_RECORD_STEPS.find((item) => item.id === diveRecord.currentStep)
                ?.route ?? DIVE_RECORD_STEPS[0].route

        projectId
            ? navigate(
                  formatUrl(
                      `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                      urlParams
                  )
              )
            : navigate(
                  formatUrl(
                      `/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                      urlParams
                  )
              )
    }

    const verifyDiveRecordLink = projectId
        ? `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD_SUMMARY}/${diveRecord.id}/${DIVE_RECORD_STEPS[0].route}`
        : `/${ROUTES.DIVE_RECORD_SUMMARY}/${diveRecord.id}/${DIVE_RECORD_STEPS[0].route}`

    const verifyDiveRecord = () => navigate(verifyDiveRecordLink)

    const deleteDiveRecord = ({ id }) => {
        if (diveRecord.deviceId) {
            restrictedDiveRecordAction(
                'general.diveRecordDeletionRestricted',
                'message.diveRecordCanOnlyBeDeletedOnDevice'
            )
            return
        }

        showConfirmationModal({
            message: 'message.areYouSureDeleteRecord',
            title: 'general.deleteRecord',
            handleConfirm: () => handleConfirm(id),
            handleCancel: () => closeConfirmationModal(),
        })
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.DIVE_RECORD, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const sendForVerification = () => {
        setOpenVerificationSelection(true)
    }

    const getStatusInfo = () => {
        switch (diveRecord.status) {
            case DIVE_RECORD_STATUS.SUBMITTED.id:
                return {
                    statusText: t('general.diveRecordStatusSubmitted'),
                    statusColor: 'blue',
                }
            case DIVE_RECORD_STATUS.VERIFIED.id:
                return {
                    statusText: t('general.verified'),
                    statusColor: 'green',
                }
            case DIVE_RECORD_STATUS.REJECTED.id:
                return {
                    statusText: t('general.diveRecordStatusRejected'),
                    statusColor: 'red',
                }
            case DIVE_RECORD_STATUS.COMPLETED.id:
                return {
                    statusText: t('general.diveRecordStatusCompleted'),
                    statusColor: 'green',
                }
            case DIVE_RECORD_STATUS.RE_SUBMITTED.id:
                return {
                    statusText: t('general.diveRecordStatusResubmitted'),
                    statusColor: 'blue',
                }
            case DIVE_RECORD_STATUS.DRAFT.id:
            default:
                return {
                    statusText: t('general.diveRecordStatusDraft'),
                    statusColor: 'gray',
                }
        }
    }

    const { statusText, statusColor } = getStatusInfo()

    const getActions = () => {
        if (isContractor || creator.id !== userId) {
            return []
        }

        const actions = [
            {
                handleAction: copyDiveRecord,
                icon: ICONS.COPY,
                tooltip: 'button.diveRecordCopy',
                label: 'button.diveRecordCopy',
            },
        ]

        if (canContinueEditing()) {
            actions.push(
                {
                    handleAction: editDiveRecord,
                    icon: ICONS.EDIT,
                    iconColor: COLORS.DARK_BLUE,
                    label: 'button.diveRecordEdit',
                },
                {
                    handleAction: deleteDiveRecord,
                    icon: ICONS.DELETE,
                    iconColor: COLORS.RED,
                    label: 'button.diveRecordDelete',
                    textColor: '-redText',
                }
            )
        }

        return actions
    }

    // If the dive record status is COMPLETED and there is no projectId, then it is a Historical dive record,
    const isCompletedHistoricalDiveRecord =
        diveRecord.status === DIVE_RECORD_STATUS.COMPLETED.id && !projectId

    const canContinueEditing = () => {
        if (isContractor || creator.id !== userId) {
            return false
        }

        return (
            diveRecord.status === DIVE_RECORD_STATUS.DRAFT.id ||
            diveRecord.status === DIVE_RECORD_STATUS.REJECTED.id
        )
    }

    const canViewDiveSummary =
        diveRecord.status === DIVE_RECORD_STATUS.VERIFIED.id ||
        isCompletedHistoricalDiveRecord ||
        (creator.id === userId &&
            (diveRecord.status === DIVE_RECORD_STATUS.SUBMITTED.id ||
                diveRecord.status === DIVE_RECORD_STATUS.RE_SUBMITTED.id)) ||
        (diveRecord.status === DIVE_RECORD_STATUS.REJECTED.id &&
            diveRecord.supervisorId === userId)

    const supervisor = diveRecord.supervisorId
        ? {
              id: diveRecord.supervisorId,
              fullName: diveRecord.supervisorFullName,
              avatarPath: diveRecord.supervisorAvatarPath,
              profileHash: diveRecord.supervisorProfileHash,
          }
        : null

    const supervisorCompany = diveRecord.supervisorCompanyId
        ? {
              id: diveRecord.supervisorCompanyId,
              fullName: diveRecord.supervisorCompanyFullName,
              avatarPath: diveRecord.supervisorCompanyAvatarPath,
              profileHash: diveRecord.supervisorCompanyProfileHash,
          }
        : null

    const canDoVerification =
        creator.id !== userId &&
        (supervisor?.id === userId ||
            supervisorCompany?.id === currentCompany?.id) &&
        (diveRecord.status === DIVE_RECORD_STATUS.SUBMITTED.id ||
            diveRecord.status === DIVE_RECORD_STATUS.RE_SUBMITTED.id)

    const hasFirstLevelOfVerification =
        diveRecord.verifiedLevel === 1 &&
        diveRecord.status === DIVE_RECORD_STATUS.VERIFIED.id

    const canSendForVerification =
        creator.id === userId &&
        (isCompletedHistoricalDiveRecord || hasFirstLevelOfVerification)

    const dateOfDive = formatDate(diveRecord.dateOfDive)

    const renderRoleName = (roleNumber) => {
        const roleKey = Object.keys(DIVE_RECORD_TYPE).find(
            (key) => DIVE_RECORD_TYPE[key].id === roleNumber
        )
        return DIVE_RECORD_TYPE[roleKey].name
    }

    const viewDiveSummaryLink = `${
        projectId ? `${ROUTES.PROJECT}/${projectId}` : ''
    }/${ROUTES.DIVE_RECORDS}/${diveRecord.id}`

    const continueFillingOutLink = () => {
        const lastProgressStepRoute =
            DIVE_RECORD_STEPS.find((item) => item.id === diveRecord.currentStep)
                ?.route ?? DIVE_RECORD_STEPS[0].route

        return projectId
            ? formatUrl(
                  `${ROUTES.PROJECT}/${projectId}/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                  urlParams
              )
            : formatUrl(
                  `/${ROUTES.DIVE_RECORD}/${diveRecord.id}/${lastProgressStepRoute}`,
                  urlParams
              )
    }

    const renderCardLink = () => {
        if (canViewDiveSummary) {
            return viewDiveSummaryLink
        }
        if (canContinueEditing() && !diveRecord.deviceId) {
            return continueFillingOutLink()
        }
        if (canDoVerification) {
            return verifyDiveRecordLink
        }
        return ''
    }

    const diveRecordName =
        (diveRecord?.projectName ? diveRecord.projectName + ' - ' : '') +
        (diveRecord.dateOfDive ? dateOfDive + ' - ' : '') +
        '#' +
        diveRecord.recordNumber

    const showCardFooter =
        canContinueEditing() || canDoVerification || canSendForVerification

    const getCustomIcon = () => {
        if (diveRecord.deviceId) {
            return (
                <IconWithTooltip
                    icon={ICONS.OFFLINE_MODE}
                    iconColor={COLORS.DARK_BLUE_40}
                    color={COLORS.DARK_BLUE_40}
                    size={ICON_SIZE.SIZE20}
                    tooltip="general.diveRecordCreatedInOfflineMode"
                />
            )
        }

        if (diveRecord.status !== DIVE_RECORD_STATUS.DRAFT.id) {
            return (
                <VerificationLevelIcon
                    levelOfVerification={diveRecord.verifiedLevel}
                    sidebarList={sidebarList}
                />
            )
        }
    }

    return (
        <div className="m-diveRecord__list">
            <CardContainer
                link={renderCardLink()}
                handleClick={
                    diveRecord.deviceId
                        ? () => restrictedDiveRecordAction()
                        : undefined
                }
            >
                <CardHeader
                    customIcon={getCustomIcon()}
                    title={diveRecordName}
                    item={diveRecord}
                    actions={!sidebarList ? getActions() : null}
                    statuses={[
                        {
                            type: 'status',
                            name: statusText,
                            color: statusColor,
                        },
                    ]}
                    sidebarCard={sidebarList}
                />
                {sidebarList && (
                    <div className="divingModeAndRole">
                        <InfoRow gap={5}>
                            {diveRecord.divingModeId && (
                                <Info
                                    value={diveRecord.divingModeName}
                                    color="orange"
                                />
                            )}
                            {diveRecord.diveRecordType && (
                                <Info
                                    value={renderRoleName(
                                        diveRecord.diveRecordType
                                    )}
                                    color="purple"
                                />
                            )}
                        </InfoRow>
                    </div>
                )}
                {diveRecord.divingModeId && !sidebarList && (
                    <Info
                        label="form.label.diveModeTechnique"
                        value={diveRecord.divingModeName}
                        color="orange"
                    />
                )}
                {diveRecord.diveRecordType && !sidebarList && (
                    <Info
                        label="form.label.divingRole"
                        value={renderRoleName(diveRecord.diveRecordType)}
                        color="purple"
                    />
                )}

                {creator && creator.id !== userId && (
                    <>
                        <Info
                            label={`${!sidebarList ? 'form.label.diver' : ''}`}
                            value={creator}
                            valueType={INFO_VALUE_TYPE.DIVER_PROFILE_LINK}
                            iconName={
                                diveRecord.diveRecordType === 2
                                    ? ICONS.USER_SUPERVISOR
                                    : ICONS.USER_DIVER
                            }
                        />
                    </>
                )}

                {((supervisor && supervisor.id !== userId) ||
                    (supervisorCompany &&
                        supervisorCompany.id !== currentCompany?.id)) && (
                    <Info
                        label={`${
                            !sidebarList ? 'general.diveSupervisor' : ''
                        }`}
                        value={supervisorCompany || supervisor}
                        valueType={INFO_VALUE_TYPE.DIVER_PROFILE_LINK}
                        verifiedUser={supervisor?.identityVerified}
                        iconName={
                            supervisorCompany
                                ? ICONS.BUILDING
                                : ICONS.USER_SUPERVISOR
                        }
                        route={
                            supervisorCompany
                                ? ROUTES.DIVING_CONTRACTOR_PROFILE
                                : ROUTES.DIVER_PROFILE
                        }
                    />
                )}

                {diveRecord.rejectComment && (
                    <Info
                        label={
                            supervisorCompany
                                ? 'form.label.commentFromCompany'
                                : 'form.label.commentFromSupervisor'
                        }
                        value={diveRecord.rejectComment}
                        column
                        descriptionText
                    />
                )}

                <CardFooter
                    withBorder={!sidebarList && showCardFooter}
                    withTopMargin={sidebarList && showCardFooter}
                >
                    {canContinueEditing() && (
                        <Button
                            label="button.diveRecordContinue"
                            buttonSize={BUTTON_SIZE.MEDIUM}
                            onClick={editDiveRecord}
                            btnClass={BUTTON_STATUS.SECONDARY}
                        />
                    )}
                    {canDoVerification && (
                        <Button
                            label="button.diveRecordVerify"
                            buttonSize={BUTTON_SIZE.MEDIUM}
                            onClick={verifyDiveRecord}
                            btnClass={BUTTON_STATUS.PRIMARY}
                        />
                    )}
                    {canSendForVerification && (
                        <Button
                            label="button.sendForVerification"
                            buttonSize={BUTTON_SIZE.MEDIUM}
                            onClick={sendForVerification}
                            btnClass={BUTTON_STATUS.PRIMARY}
                        />
                    )}
                </CardFooter>
                {openVerificationSelection && (
                    <VerificationSelection
                        setOpen={setOpenVerificationSelection}
                        diveRecord={diveRecord}
                        diveRecordName={diveRecordName}
                        fetchData={fetchData}
                    />
                )}
            </CardContainer>
        </div>
    )
}

DiveRecordListCard.propTypes = {
    diveRecord: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    sidebarList: PropTypes.bool,
}

export default DiveRecordListCard

import PropTypes from 'prop-types'
import { useContext } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getProjectVisibility } from 'utils/projectVisibilityHelper'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import IconWithTooltip from './IconWithTooltip'

const ProjectVisibilityIcon = ({ hidden, creatorCompanyId }) => {
    const { currentCompany } = useContext(CurrentUserContext)

    if (creatorCompanyId !== currentCompany?.id)
        return null

    const { icon, tooltip } = getProjectVisibility(hidden)

    return (
        <IconWithTooltip
            icon={icon}
            iconColor={COLORS.DARK_BLUE_40}
            color={COLORS.DARK_BLUE_40}
            size={ICON_SIZE.SIZE20}
            tooltip={tooltip}
        />
    )
}
ProjectVisibilityIcon.propTypes = {
    creatorId: PropTypes.number,
    hidden: PropTypes.bool,
    creatorCompanyId: PropTypes.number,
}

ProjectVisibilityIcon.defaultProps = {
    hidden: true,
}

export default ProjectVisibilityIcon

import PropTypes from 'prop-types'

import isFormEmpty from 'utils/emptyForm'

import Button from 'components/Button'

import COLORS from 'constants/colors'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    ICON_SIZE,
} from 'constants/enums'
import ICONS from 'constants/icons'

const ModalButtons = ({
    buttons,
    isSubmitting,
    values,
    setOpen,
    hidePrevBtn,
}) => {
    const { prevBtn, nextBtn, middleBtn, downloadBtn } = buttons || {}

    const disableMiddleBtn = isSubmitting || isFormEmpty(values)

    const handleDefaultPrevBtnClick = () => {
        setOpen(false)
    }

    if (downloadBtn) {
        return (
            <div className="_w -buttons -footer">
                <div className="_12 justify-end">
                    <Button
                        btnClass={BUTTON_STATUS.SECONDARY}
                        icon={ICONS.DOWNLOAD_IMAGE}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconSize={ICON_SIZE.SIZE20}
                        onClick={downloadBtn.handleClick}
                        label={downloadBtn.label}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="_w -buttons space-between -footer">
            {!hidePrevBtn && (
                <div
                    className={`_12 ${!middleBtn ? '_m6' : '_m4'} -prevButton`}
                >
                    <Button
                        btnClass={BUTTON_STATUS.SECONDARY}
                        label={prevBtn?.label || 'button.cancel'}
                        onClick={
                            prevBtn?.handleClick || handleDefaultPrevBtnClick
                        }
                        disabled={prevBtn?.disabled || isSubmitting}
                    />
                </div>
            )}
            {middleBtn && (
                <div className="_12 _m4 -middleButton">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        icon={ICONS.LINE}
                        iconPosition={ICON_POSITION.RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        label={middleBtn.label || 'button.resetFilters'}
                        onClick={middleBtn.handleClick}
                        disabled={middleBtn.disabled || disableMiddleBtn}
                    />
                </div>
            )}
            <div
                className={`_12 ${!middleBtn ? '_m6' : '_m4'} ${
                    hidePrevBtn ? '_12 _m12' : ''
                } -nextButton`}
            >
                <Button
                    btnClass={
                        nextBtn?.btnClass
                            ? nextBtn?.btnClass
                            : BUTTON_STATUS.PRIMARY
                    }
                    type={BUTTON_TYPE.SUBMIT}
                    label={nextBtn?.label || 'button.save'}
                    disabled={nextBtn?.disabled || isSubmitting}
                    onClick={nextBtn?.handleClick}
                    icon={nextBtn?.icon}
                    iconColor={nextBtn?.iconColor}
                />
            </div>
        </div>
    )
}

ModalButtons.propTypes = {
    buttons: PropTypes.object,
    isSubmitting: PropTypes.bool,
    values: PropTypes.object,
    setOpen: PropTypes.func,
    hidePrevBtn: PropTypes.bool,
}

ModalButtons.defaultProps = {
    isSubmitting: false,
    hidePrevBtn: false,
}

export default ModalButtons

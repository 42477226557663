import { useNavigate } from 'react-router-dom'

import { ALERT_TYPES, BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import { Table } from 'components/table'
import ManagementLayout from '../components/ManagementLayout'
import CallCenterBreadcrumbs from 'screens/admin/CallCenter/components/CallCenterBreadcrumbs'
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { MOMENT_FORMATS } from 'services/moment.service'
import useQueryParams from 'hooks/useQueryParams'
import { AlertContext } from 'contexts/AlertContext'
import FILTERS from 'constants/filters'
import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import { useTranslate } from 'react-polyglot'
import StatisticsItem from 'screens/admin/CallCenter/components/StatisticsItem'
import { TABLE_HEADER } from 'screens/admin/CallCenter/util/tableHeader'
import {
    getStatistics,
    handleFilter,
} from 'screens/admin/CallCenter/service/callManagementService'

const CallCenterManagement = () => {
    const t = useTranslate()
    const { page, itemsPerPage } = useQueryParams()
    const navigate = useNavigate()
    const { setAlert } = useContext(AlertContext)

    const [stat, setStat] = useState(null)
    const [calls, setCalls] = useState(null)
    const [meta, setMeta] = useState(null)
    const [isLoading, setIsLoading] = useState(calls === null)
    const [filter, setFilter] = useState({
        fromDate: moment('2024-01-02').format(
            MOMENT_FORMATS.DATE_FULL_TIMEZONE
        ),
        toDate: moment().format(MOMENT_FORMATS.DATE_FULL_TIMEZONE),
        inboundId: null,
        limit: itemsPerPage ?? 10,
        skip: page ? page * (itemsPerPage ?? 10) : 0,
    })

    useFetchActiveFilters({}, FILTERS.SUPER_ADMIN_CALLS_FILTERS)

    useEffect(() => {
        let newFilter = {
            ...filter,
            limit: itemsPerPage ?? 10,
            skip: !page || page === 1 ? 0 : (itemsPerPage ?? 10) * (page - 1),
        }
        setFilter(newFilter)
        setIsLoading(true)
        handleFilter(newFilter).then(({ data: { data, meta, error } }) => {
            setMeta(meta)
            setCalls(data)
            setFilter({ ...filter, inboundId: meta.inboundId })
            setIsLoading(false)
            if (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        })
    }, [page, itemsPerPage])

    useEffect(() => {
        getStatistics().then(({ data }) => {
            setStat(data)
        })
    }, [])

    const handleView = ({ id }) => {
        navigate(`/${ROUTES.CALL_CENTER}/${id}`)
    }

    return (
        <ManagementLayout BreadcrumbsComponent={CallCenterBreadcrumbs}>
            {stat && (
                <div className={'_wr -callCenterTable'}>
                    <div className={'_w'}>
                        <span className="a-bodyTextMedium -title -mb15">
                            {t('callCenter.dailyStatistics')}
                        </span>
                    </div>
                    <div className={'_w -statistics-wrapper'}>
                        <StatisticsItem
                            label={'callCenter.callsToday'}
                            value={stat.total}
                        />
                        <StatisticsItem
                            label={'callCenter.averageDuration'}
                            value={stat.averageDuration}
                        />
                        <StatisticsItem
                            label={'callCenter.completedCalls'}
                            value={stat.completed}
                        />
                        <StatisticsItem
                            label={'callCenter.uncompletedCalls'}
                            value={stat.uncompleted}
                        />
                    </div>
                </div>
            )}
            <Table
                title="general.callCenter"
                headerItems={TABLE_HEADER}
                data={calls || []}
                totalItems={meta?.totalItems || 0}
                searchable={false}
                isLoading={isLoading}
                rowActions={[
                    {
                        handleAction: handleView,
                        icon: ICONS.CALL_INFO,
                        tooltip: 'button.view',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                ]}
                filtersKey={FILTERS.SUPER_ADMIN_CALLS_FILTERS}
            />
        </ManagementLayout>
    )
}

export default CallCenterManagement

import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import CardHeader from 'components/card/CardHeader'
import EditDivingModeExperience from './EditDivingModeExperience'

const DivingModeExperienceList = ({
    data,
    isLoading,
    fetchData,
    refetchData,
    showActions,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [edit, setEdit] = useState(null)

    const handleEdit = (item) => setEdit(item)

    const handleDelete = async ({ id }) => {
        try {
            await deleteEntityService(ENTITIES.EXPERIENCE, id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCloseEdit = () => setEdit(false)

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={1} />
    }

    const formatExpItemValue = (expForDivingMode, highlightedInfo, color) => {
        let infoItems = {}

        if (highlightedInfo) {
            infoItems = {
                sum: {
                    label: 'divesSum',
                    bottomTimeHoursKey: 'bottomTimeHoursSum',
                    bottomTimeMinutesKey: 'bottomTimeMinutesSum',
                },
                closedBellSaturation: {
                    label: 'daysBell',
                    additionalLabel: 'bellRuns',
                    bottomTimeHoursKey: 'bottomTimeHoursBell',
                    bottomTimeMinutesKey: 'bottomTimeMinutesBell',
                },
            }
        } else {
            infoItems = {
                air: {
                    label: 'divesAir',
                    bottomTimeHoursKey: 'bottomTimeHoursAir',
                    bottomTimeMinutesKey: 'bottomTimeMinutesAir',
                },
                nitrox: {
                    label: 'divesNitrox',
                    bottomTimeHoursKey: 'bottomTimeHoursNitrox',
                    bottomTimeMinutesKey: 'bottomTimeMinutesNitrox',
                },
                mixedGas: {
                    label: 'divesMixedGas',
                    bottomTimeHoursKey: 'bottomTimeHoursMixedGas',
                    bottomTimeMinutesKey: 'bottomTimeMinutesMixedGas',
                },
            }
        }

        const infoKeys = Object.keys(infoItems)

        // Filter the keys with valid textItems
        const validKeys = infoKeys.filter((key) => {
            const { label, additionalLabel } = infoItems[key]
            return expForDivingMode[label] || expForDivingMode[additionalLabel]
        })

        return (
            <div className={color === 'orange' ? '-divingModeExperience' : ''}>
                {validKeys.map((key, index) => {
                    const {
                        label,
                        additionalLabel,
                        bottomTimeHoursKey,
                        bottomTimeMinutesKey,
                    } = infoItems[key]

                    const textItems = []

                    if (
                        expForDivingMode[label] ||
                        expForDivingMode[additionalLabel]
                    ) {
                        if (expForDivingMode[label]) {
                            textItems.push(
                                <span
                                    key={`${key}-label-value`}
                                    className="a-captionsTextSemiBold "
                                >
                                    {expForDivingMode[label]}
                                </span>,
                                <span
                                    key={`${key}-label`}
                                    className="opacity80"
                                >{` ${t(`form.label.${label}`)}`}</span>
                            )
                        }

                        if (expForDivingMode[additionalLabel]) {
                            if (textItems.length > 0) {
                                textItems.push(' | ')
                            }
                            textItems.push(
                                <span
                                    key={`${key}-additionalLabel-value`}
                                    className="a-captionsTextSemiBold "
                                >
                                    {expForDivingMode[additionalLabel]}
                                </span>,
                                <span
                                    key={`${key}-additionalLabel`}
                                    className="opacity80"
                                >{` ${t(
                                    `form.label.${additionalLabel}`
                                )}`}</span>
                            )
                        }

                        const hours =
                            expForDivingMode[bottomTimeHoursKey] || '0'
                        const minutes =
                            expForDivingMode[bottomTimeMinutesKey] || '0'

                        const h = (
                            <span
                                key={`${key}-hours`}
                                className="a-captionsTextSemiBold "
                            >
                                {hours !== '0' ? `${hours}h ` : '0h '}
                            </span>
                        )
                        const m = (
                            <span
                                key={`${key}-minutes`}
                                className="a-captionsTextSemiBold "
                            >
                                {minutes !== '0' ? `${minutes}m ` : '0min '}
                            </span>
                        )

                        if (textItems.length > 0) {
                            textItems.push(' | ')
                        }
                        textItems.push(
                            <span key={`${key}-bottomTime`}>
                                {h}
                                {m}
                                <span className="opacity80">
                                    {t('general.bottomTime')}
                                </span>
                            </span>
                        )
                    }

                    return (
                        textItems.length > 0 && (
                            <div className="-info -row -withColor" key={index}>
                                <div
                                    className={`-value -valueWithColor a-status  -${
                                        color || ''
                                    } -textColor-${color || 'gray'} `}
                                >
                                    <span
                                        key={`${expForDivingMode.divingMode.id}-${key}`}
                                        className="-label a-captionsTextRegular "
                                    >
                                        {textItems}
                                    </span>
                                </div>
                            </div>
                        )
                    )
                })}
            </div>
        )
    }

    return (
        <Fragment>
            {data &&
                !!data.length &&
                data.map((item) => (
                    <div key={item.id} className="12 -mt20 -experienceCard">
                        <CardContainer>
                            <CardHeader
                                title={item.divingMode?.name}
                                item={item}
                                actions={
                                    showActions
                                        ? [
                                              {
                                                  handleAction: handleEdit,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  label: 'button.edit',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  label: 'button.delete',
                                                  title:
                                                      'general.deleteDiveModeExperience',
                                                  confirmationMessage:
                                                      'message.areYouSureDeleteDiveModeExperience',
                                                  textColor: '-redText',
                                              },
                                          ]
                                        : []
                                }
                            />
                            {formatExpItemValue(item, true, 'purple')}
                            {formatExpItemValue(item, false, 'orange')}
                        </CardContainer>
                    </div>
                ))}
            {!!edit && (
                <EditDivingModeExperience
                    data={edit}
                    setOpen={handleCloseEdit}
                    handleClose={handleCloseEdit}
                    fetchData={fetchData}
                    showActions={showActions}
                    refetchData={refetchData}
                    divingMode={edit.divingMode}
                />
            )}
        </Fragment>
    )
}

DivingModeExperienceList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    showActions: PropTypes.bool,
}

DivingModeExperienceList.defaultProps = {
    showActions: false,
}

export default DivingModeExperienceList

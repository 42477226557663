import React from 'react'
import PropTypes from 'prop-types'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from './Icon'

const PlaceholderImageIcon = ({ companyProfile, iconSize }) => {
    return (
        <Icon
            name={companyProfile ? ICONS.BUILDING : ICONS.USER}
            color={COLORS.WHITE}
            size={iconSize || ICON_SIZE.SIZE80}
        />
    )
}

PlaceholderImageIcon.propTypes = {
    companyProfile: PropTypes.bool,
    iconSize: PropTypes.number,
}

export default PlaceholderImageIcon

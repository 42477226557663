import { useContext } from 'react'

import { ImagePreviewModalContext } from 'contexts/ImagePreviewModalContext'

import Modal from './Modal'

const ImagePreviewModal = () => {
    const {
        imagePreviewModal: {
            image,
            title,
            currentImageIndex,
            handleDownload,
            handleClose,
        },
    } = useContext(ImagePreviewModalContext)

    if (!image) return null

    return (
        <Modal
            open={true}
            setOpen={handleClose}
            closeButton={handleClose}
            title={title || image?.alt || ''}
            noTranslateTitle
            customClass="-imagePreviewModal"
            buttons={{
                downloadBtn: {
                    label: 'general.downloadImage',
                    handleClick: handleDownload,
                },
            }}
            widerSmallModal
        >
            <div className="_12 -imagePreview">
                <img src={image} alt={`Image ${currentImageIndex}`} />
            </div>
        </Modal>
    )
}

export default ImagePreviewModal

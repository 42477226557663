import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { BUTTON_SIZE, ICON_SIZE } from 'constants/enums'

import ActionButton from './ActionButton'
import Icon from 'components/Icon'
import CellItem from './CellItem'
import DropdownActions from 'components/DropdownActions'

const TableBody = ({
    headerItems,
    data,
    isLoading,
    rowActions,
    selectedRowActions,
    onClickRow,
    selectedRow,
    setSelectedRow,
    defaultItemsPerPage,
    idKey,
    rowDropdownActions,
}) => {
    const t = useTranslate()

    const { itemsPerPage: queryItemsPerPage } = useQueryParams()

    const itemsPerPage = Number(queryItemsPerPage) || defaultItemsPerPage

    const actionsColSpan = rowActions.length + 1

    const handleClickRow = (item) => {
        if (selectedRowActions.length > 0) {
            if (selectedRow && selectedRow[idKey] === item[idKey]) {
                setSelectedRow(null)
                if (onClickRow) onClickRow(null)
            } else {
                setSelectedRow(item)
                if (onClickRow) onClickRow(item)
            }
        } else if (onClickRow) {
            onClickRow(item)
        }
    }

    const isRowClickable = onClickRow || selectedRowActions.length > 0

    if (isLoading && data?.length === 0)
        return (
            <tbody>
                {[...Array(itemsPerPage).keys()].map((item) => (
                    <tr key={item} className="m-table__placeholder">
                        {headerItems.map(({ key }) => (
                            <td key={key}>
                                <span></span>
                            </td>
                        ))}
                        {rowActions && (
                            <td>
                                <div className="-cellWrapper">
                                    <div className="-placeholderActions">
                                        <span>
                                            <Icon
                                                name={ICONS.DOTS}
                                                color={COLORS.LIGHT_BLUE}
                                                size={ICON_SIZE.SIZE16}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        )

    if (data?.length === 0)
        return (
            <tbody>
                <tr className="m-table__empty">
                    <td
                        colSpan={
                            headerItems.length +
                            (rowActions.length || rowDropdownActions.length)
                        }
                    >
                        <span className="a-captionsTextRegular a-lightText">
                            {t('general.noItems')}
                        </span>
                    </td>
                </tr>
            </tbody>
        )

    if (!isLoading || data?.length > 0)
        return (
            <tbody>
                {data.map((item, index) => (
                    <tr
                        key={index}
                        onClick={() => handleClickRow(item)}
                        className={`${isRowClickable ? 'clickable' : ''} ${
                            item[idKey] &&
                            (selectedRow && selectedRow[idKey] === item[idKey]
                                ? '-selected'
                                : '')
                        }`}
                    >
                        {headerItems.map(({ key, ...headerItem }) => (
                            <td key={key}>
                                <div className="-cellWrapper">
                                    <CellItem
                                        key={key}
                                        headerKey={key}
                                        headerItem={headerItem}
                                        item={item}
                                    />
                                </div>
                            </td>
                        ))}
                        {rowDropdownActions.length > 0 && (
                            <td colSpan={actionsColSpan}>
                                <div className="-cellWrapper justify-center">
                                    <div className="m-table__actionsCell">
                                        <DropdownActions
                                            actions={rowDropdownActions}
                                            item={item}
                                            itemIndex={index}
                                        />
                                    </div>
                                </div>
                            </td>
                        )}
                        {rowActions.length > 0 && (
                            <td colSpan={actionsColSpan}>
                                <div className="-cellWrapper justify-center">
                                    <div className="m-table__actionsCell">
                                        {rowActions.map(
                                            (
                                                {
                                                    ActionComponent = ActionButton,
                                                    ...action
                                                },
                                                index
                                            ) => (
                                                <ActionComponent
                                                    key={index}
                                                    {...action}
                                                    item={item}
                                                    buttonSize={
                                                        BUTTON_SIZE.XSMALL
                                                    }
                                                    iconSize={ICON_SIZE.SIZE20}
                                                    stopPropagation={
                                                        !!isRowClickable
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        )
}

TableBody.propTypes = {
    headerItems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    rowActions: PropTypes.array,
    selectedRowActions: PropTypes.array,
    onClickRow: PropTypes.func,
    selectedRow: PropTypes.object,
    setSelectedRow: PropTypes.func,
    defaultItemsPerPage: PropTypes.number,
    idKey: PropTypes.string,
    rowDropdownActions: PropTypes.array,
}

TableBody.defaultProps = {
    rowActions: [],
    rowDropdownActions: [],
}

export default TableBody

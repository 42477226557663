import { APP_LINK } from 'constants/constants'

export const FAQ_DATA = [
    {
        title: 'Accessing Skill N Depth Onboarding',
        questions: [
            {
                question: 'Where can I find the Skill N Depth onboarding app?',
                answer: 'You can access the onboarding form by logging into the Skill N Depth app. Use your desktop to visit',
                linkText: APP_LINK.TEXT,
                link: APP_LINK.LINK,
                answerRemainingText:
                    'or download the app from Google Play or the Apple App Store. Once installed, open the app and follow the onboarding prompts to complete your registration.',
            },
            {
                question:
                    'I’m having trouble accessing the onboarding App. What should I do?',
                answer: 'Please ensure your internet connection is stable. For technical issues, clear your browser cache and try accessing the form again. If problems persist, contact our support team for assistance.',
            },
            {
                question:
                    'What documents or information do I need to have on hand during onboarding?',
                answer: 'Have your professional diving certifications, recent dive logs, company employment details, personal identification and profile picture ready to upload. Each item must be in PDF or JPEG format.',
            },
            {
                question:
                    'Can I complete the onboarding form in my native language?',
                answer: 'Currently, onboarding must be completed in English to ensure consistency and accuracy across all platform entries.',
            },
            {
                question:
                    'Are all questions on the Skill N Depth onboarding form mandatory?',
                answer: 'No, all questions are not necessary to complete the onboarding process and to enable access to the Skill N Depth platform. However, there are mandatory fields that need to be completed for verification of your profile.',
            },
            {
                question:
                    'Will additional forms be required after I complete the onboarding process?',
                answer: 'Typically, no additional forms are required unless further verification is necessary.',
            },
        ],
    },
    {
        title: 'Completing the Skill N Depth onboarding process',
        importantNotice:
            'Ensure all information entered during the Skill N Depth onboarding process is accurate and complete. Errors may delay your access to the platform or require additional verification steps.',
        questions: [
            {
                question:
                    'What are minimal requirements for my profile to be completed and verified?',
                answer: 'Following sections should fulfilled in order your profile to be completed:',
                answerList: [
                    {
                        boldText: 'Personal info',
                        text: 'Provide necessary personal info and upload your profile picture.',
                    },
                    {
                        boldText: 'Identification and travel',
                        text: 'Provide one of the following: National ID, National driving license or Passport.',
                    },
                    {
                        boldText: 'Education and training',
                        text: 'Provide details of your formal education and professional training you have taken.',
                    },
                    {
                        boldText: 'Skills and experience',
                        text: 'Provide your experience and skills.',
                    },
                ],
            },
            {
                question:
                    'How do I verify the accuracy of my onboarding information?',
                answer: 'After completing each section, review your entries before submission.',
            },
            {
                question:
                    'Can a third party complete the onboarding form on my behalf?',
                answer: 'Yes, a third party may assist you in completing the form, but you must review all entries for accuracy before submitting.',
            },
            {
                question:
                    'I encountered unfamiliar terms on the onboarding form. Are they explained?',
                answer: 'Yes, hover over any unfamiliar term on the form for a brief explanation. For further clarification, consult the help guide or contact support.',
            },
        ],
    },
    {
        title: 'Saving and returning to your Skill N Depth onboarding form',
        questions: [
            {
                question:
                    'How can I save my application and return to it later?',
                answer: "You can save your progress by selecting 'Save and Log out.' Use your credentials to log in the app at the later time.",
            },
            {
                question:
                    'How do I download or save a copy of my completed CV?',
                answer: 'Once submitted, you’ll have the option to download a PDF copy of your completed CV.',
            },
            {
                question:
                    'What should I do if my internet connection is lost? Will my information be saved?',
                answer: "Your progress is saved every time you select 'Save.' If your session times out or you lose connection, return to the app to continue your onboarding from the last saved point.",
            },
        ],
    },
    {
        title: 'Identity verification',
        description: 'The Skill N Depth uses',
        linkText: 'Persona',
        link: 'https://withpersona.com/',
        description2:
            'to provide a thorough identity verification process for its users. Verifying identities is a key part of maintaining the authenticity and security of user profiles, which allows users to access features like verified dive records and participation in projects. Persona verification will be enabled after your profile is completed.',
    },
]

import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import { Table } from 'components/table'
import Button from 'components/Button'
import CreateGasCylinders from './CreateGasCylinders'
import EditGasCylinders from './EditGasCylinders'
import GasContents from './GasContents'

const TABLE_HEADER = [
    {
        key: 'gasCylinderPurpose.name',
        title: 'table.header.purpose',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
    },
    {
        key: 'size',
        title: 'table.header.size',
        showOnSmallScreens: true,
    },
    {
        key: 'startPressure',
        title: 'table.header.startPressure',
        showOnSmallScreens: true,
    },
    {
        key: 'endPressure',
        title: 'table.header.endPressure',
        showOnSmallScreens: true,
    },
    {
        key: 'gasContents',
        title: 'table.header.gasContents',
        showOnSmallScreens: true,
        CellComponent: GasContents,
    },
]

const GasCylinders = ({ gasCylinders, setGasCylinders, error }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const [create, setCreate] = useState(false)
    const [editItem, setEditItem] = useState()
    const [editItemIndex, setEditItemIndex] = useState()

    const handleDelete = (item, index) => {
        const newGasCylinders = [...gasCylinders]
        newGasCylinders.splice(index, 1)
        setGasCylinders(newGasCylinders)
        setAlert(t('message.successGasCylinderDelete'), ALERT_TYPES.SUCCESS)
    }

    const handleCreate = () => {
        setCreate(true)
    }

    const handleEdit = (item, index) => {
        setEditItem(item)
        setEditItemIndex(index)
    }

    return (
        <div>
            <div className="_wr">
                <div className="-mt20 aligned-center space-between">
                    <span className="a-bodyTextRegular">
                        {t('general.gasCylindersUsed')}{' '}
                        <span className="redStar">*</span>
                    </span>
                    <Button
                        label="button.addGasCylinder"
                        btnClass={BUTTON_STATUS.SECONDARY}
                        buttonSize={BUTTON_SIZE.MEDIUM}
                        type={BUTTON_TYPE.SUBMIT}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        onClick={handleCreate}
                    />
                </div>
            </div>
            {error && gasCylinders.length === 0 && (
                <span className="_12 errorMessage -active">{error}</span>
            )}
            <Table
                headerItems={TABLE_HEADER}
                data={gasCylinders}
                totalItems={gasCylinders.length}
                fullWidthTitle
                paginate={false}
                rowDropdownActions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        label: 'button.edit',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleDelete,
                        icon: ICONS.DELETE,
                        iconColor: 'red',
                        label: 'button.delete',
                        title: 'general.deleteGasCylinder',
                        confirmationMessage:
                            'message.areYouSureDeleteGasCylinder',
                        btnClass: BUTTON_STATUS.TERTIARY,
                        textColor: '-redText',
                    },
                ]}
            />
            {create && (
                <CreateGasCylinders
                    gasCylinders={gasCylinders}
                    setGasCylinders={setGasCylinders}
                    setCreate={setCreate}
                />
            )}
            {editItem && (
                <EditGasCylinders
                    editItem={editItem}
                    setEditItem={setEditItem}
                    setGasCylinders={setGasCylinders}
                    editItemIndex={editItemIndex}
                    setEditItemIndex={setEditItemIndex}
                />
            )}
        </div>
    )
}

export default GasCylinders

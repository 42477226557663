import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { editEntityService } from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import { UNITS_OF_MEASUREMENT } from 'constants/constants'

import Loader from 'components/Loader'
import Button from 'components/Button'
import { RadioGroupField } from 'components/formFields'

import useFetchDataById from 'hooks/useFetchDataById'
import FocusError from '../../../components/FocusError'
import SelectField from '../../../components/formFields/SelectField'
import SettingsItem from './components/SettingsItem'

const UnitsAndFormats = () => {
    const t = useTranslate()

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const { data: initialData, fetchData: fetchInitialData } = useFetchDataById(
        ENTITIES.USER_SETTING,
        currentUser.userSetting.id,
        {
            include: 'dateFormat,volumeType',
        }
    )

    if (!initialData) return null

    const initialValues = {
        unitImperial: initialData ? Number(initialData.unitImperial) : null,
        dateFormat: initialData ? initialData.dateFormat : null,
        volumeType: initialData ? initialData.volumeType : null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        unitImperial: Yup.number()
            .typeError(t('form.error.required'))
            .required(requiredMessage),
        dateFormat: Yup.object().required(requiredMessage),
        volumeType: Yup.object().required(requiredMessage),
    })

    const handleSubmit = async (
        { unitImperial, dateFormat, volumeType },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)
            await editEntityService(
                ENTITIES.USER_SETTING,
                currentUser.userSetting.id,
                {
                    unitImperial: Boolean(unitImperial),
                    dateFormat,
                    volumeType,
                }
            )
            fetchCurrentUser()
            fetchInitialData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
        showConfirmationModal({
            title: 'general.changesSaved',
            message: 'general.unitsAndFormatsSavedDescription',
            handleConfirm: () => closeConfirmationModal(),
            handleCancel: () => closeConfirmationModal(),
            hideCancel: true,
        })
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, initialValues[key])
        })
        setTouched({})
    }

    const handleCancel = () => closeConfirmationModal()

    const handleResetButton = (values, setFieldValue, setTouched) => {
        showConfirmationModal({
            message: 'message.areYouSureDiscardChanges',
            title: 'general.discardChanges',
            handleConfirm: () => {
                handleReset(values, setFieldValue, setTouched)
                closeConfirmationModal()
            },
            handleCancel,
        })
    }

    const getIsDisabled = (values) => {
        return (
            values.dateFormat.id === initialValues.dateFormat.id &&
            values.unitImperial === initialValues.unitImperial &&
            values.volumeType.id === initialValues.volumeType.id
        )
    }

    const handleDateExplanationText = (dateFormatName) => {
        return <span>{t(`general.${dateFormatName}`)}</span>
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
            enableReinitialize={true}
        >
            {({ values, isSubmitting, setFieldValue, setTouched }) => (
                <Form className="_wr space-between clearPadding">
                    <FocusError />
                    <div className="-unitsAndFormats">
                        <div className="_w">
                            <span className="_12 a-bodyTextMedium">
                                {t('general.unitsAndFormats')}
                            </span>
                            <div className="_12 -mt20 radioButtonLeftAligned">
                                <SettingsItem
                                    title="general.unitsOfMeasurement"
                                    description="general.switchBetweenImperialOrMetric"
                                >
                                    <RadioGroupField
                                        name="unitImperial"
                                        showLabel={false}
                                        defaultOptions={UNITS_OF_MEASUREMENT}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                    />
                                </SettingsItem>
                            </div>

                            <div className="_12 -mt20">
                                <SettingsItem
                                    title="general.dateFormat"
                                    note="message.featureUnderDevelopment"
                                    description="general.selectYourPreferredDateFormat"
                                >
                                    <div className="_12 _m6 -mt20 clearPadding">
                                        <SelectField
                                            name="dateFormat"
                                            label="form.label.dateFormat"
                                            placeholder="form.placeholder.dateFormat"
                                            entityType={ENTITIES.DATE_FORMAT}
                                            required
                                        />
                                    </div>
                                    <p className="a-captionsTextRegular a-lightText">
                                        <span className="a-captionsTextSemiBold">
                                            {values.dateFormat.name} –
                                        </span>{' '}
                                        {handleDateExplanationText(
                                            values.dateFormat.name
                                        )}
                                    </p>
                                </SettingsItem>
                            </div>
                            <div className="_12 -mt20">
                                <SettingsItem
                                    title="general.volume"
                                    note="message.featureUnderDevelopment"
                                    description="general.selectYourPreferredVolumeUnit"
                                >
                                    <div className="_12 _m6 -mt20 clearPadding">
                                        <SelectField
                                            name="volumeType"
                                            label="form.label.volumeType"
                                            placeholder="form.placeholder.volumeType"
                                            entityType={ENTITIES.VOLUME_TYPE}
                                            required
                                            dropup
                                        />
                                    </div>
                                </SettingsItem>
                            </div>
                        </div>
                        <div className="_w space-between -buttons -mt30">
                            <div className="-mt20 -prevButton _12 _m6 _l4">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label="button.discard"
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    onClick={() =>
                                        handleResetButton(
                                            values,
                                            setFieldValue,
                                            setTouched
                                        )
                                    }
                                    disabled={
                                        isSubmitting || getIsDisabled(values)
                                    }
                                />
                            </div>
                            <div className="-mt20 -nextButton _12 _m6 ofs_l4 _l4">
                                <Button
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    buttonSize={BUTTON_SIZE.LARGE}
                                    label="button.saveChanges"
                                    disabled={
                                        isSubmitting || getIsDisabled(values)
                                    }
                                />
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default UnitsAndFormats

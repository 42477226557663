import React, { useMemo, useState } from 'react'

export const FeedbackModalContext = React.createContext()

const modalInitialState = {
    showModal: false,
}

const FeedbackModalContextProvider = (props) => {
    const [feedbackModal, setFeedbackModal] =
        useState(modalInitialState)

    const showFeedbackModal = () => setFeedbackModal({
        showModal: true,
    })

    const closeFeedbackModal = () => setFeedbackModal(modalInitialState)

    const contextValue = useMemo(
        () => ({
            feedbackModal,
            showFeedbackModal,
            closeFeedbackModal,
        }),
        [feedbackModal]
    )

    return (
        <FeedbackModalContext.Provider value={contextValue}>
            {props.children}
        </FeedbackModalContext.Provider>
    )
}

export default FeedbackModalContextProvider

import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const ContactInfoAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.CONTACT_INFO}
            accessTypeLabel="form.label.selectAccessForContactInfo"
            dataAccessAttribute="accessContactInfo"
            title="general.contactInfo"
            noFiles={true}
        />
    )
}

export default ContactInfoAccessControl

import React from 'react'

import { useTranslate } from 'react-polyglot'
import { getColorByStatus, getLabelByStatus } from 'utils/CallStatusUtil'


const CallStatusCell = ({ value }) => {
    const t = useTranslate()
    const color = getColorByStatus(value)
    const label = getLabelByStatus(value)

    return (
        <div className={`a-status a-captionsTextRegular -${color}`}>
            {t(`general.${label}`)}
        </div>
    )
}

export default CallStatusCell

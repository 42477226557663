import { getEntityService } from 'services/entity.service'
import ENTITIES from 'constants/entities'
import { POST, RESPONSE_TYPE } from 'utils/axiosClient'

export const getStatistics = () => {
    return getEntityService(
        ENTITIES.CALL_CENTER_STATISTICS,
        {  },
        false
    )
}

export const handleFilter = (values) => {
    return getEntityService(
        ENTITIES.CALL_CENTER_CALLS,
        values,
        false,
        false,
        process.env.REACT_APP_API_HOST,
        RESPONSE_TYPE.JSON,
        POST
    )
}
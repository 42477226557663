import { Form, Formik } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'
import useFetchData from 'hooks/useFetchData'

import { ICON_SIZE, INPUT_FILED_TYPE } from 'constants/enums'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { CheckboxField, MultiselectField, SelectField } from 'components/formFields'
import {
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
    getTomorrowDate,
    MOMENT_FORMATS,
} from 'services/moment.service'
import REGEXP from 'constants/regex'
import InputField from 'components/formFields/InputField'
import Tooltip from 'components/Tooltip'
import Icon from 'components/Icon'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import Separator from 'components/Separator'
import DateTimeField from 'components/formFields/DateTimeField'
import TextAreaField from 'components/formFields/TextAreaField'
import PropTypes from 'prop-types'
import FocusError from 'components/FocusError'
import Note from 'components/Note'

const HistoricalProjectModalForm = ({
    setOpenCreateHistoricalProjectModal,
    setHistoricalItem,
    initialData,
    handleSubmit,
    title,
}) => {
    const t = useTranslate()
    const formRef = useRef()
    const { data, isLoading } = useFetchData(ENTITIES.DIVING_MODE)

    const handleCancel = () => {
        setOpenCreateHistoricalProjectModal(false)
        setHistoricalItem(false)
    }

    if (!data.length) return null
    if (isLoading) return <Loader />

    const initialValues = {
        id: initialData?.id ?? null,
        projectName: initialData?.name ?? '',
        contractor: initialData?.companyName ?? '',
        ultimateClient: initialData?.ultimateClient ?? '',
        nameOfEmployer: initialData?.nameOfEmployer ?? '',
        hidden: initialData ? initialData.hidden : false,
        fromDate: initialData?.fromDate ?? '',
        toDate: initialData?.toDate ?? '',
        region: initialData?.regionId
            ? {
                  id: initialData?.regionId,
                  name: initialData?.regionName,
              }
            : null,
        country: initialData?.countryId
            ? {
                  id: initialData?.countryId,
                  name: initialData?.countryName,
              }
            : null,
        locationDetail: initialData?.locationDetail ?? '',
        locationType: initialData?.locationTypeId
            ? {
                  id: initialData?.locationTypeId,
                  name: initialData?.locationTypeName,
              }
            : null,
        projectScope: initialData?.projectScope ?? '',
        divingMode: initialData?.divingModeId
            ? {
                  id: initialData?.divingModeId,
                  name: initialData?.divingModeName,
              }
            : null,
        nameOfVessel: initialData?.nameOfVessel ?? '',
        position: initialData?.positionId
            ? {
                  id: initialData?.positionId,
                  name: initialData?.positionName,
              }
            : null,
        tasks: initialData?.tasks ?? [],
        objects: initialData?.objects ?? [],
        activities: initialData?.activities ?? [],
        tools: initialData?.tools ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        projectName: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_NUMERIC, t('form.error.onlyLetterAndNumber'))
            .required(requiredMessage),
        position: Yup.object().required(t('form.error.required')),
        fromDate: Yup.date()
            .nullable()
            .test('fromDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.startedDate'),
                    path: 'fromDate',
                })
            }),
        toDate: Yup.date()
            .nullable()
            .test('toDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.fromDate,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.endDateAfterStartDate'),
                        path: 'toDate',
                    })
                }
                if (
                    !getIsDateSameOrBeforeCurrentDate(
                        value,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.startedDate'),
                        path: 'toDate',
                    })
                }

                return true
            }),
        country: Yup.object().required(requiredMessage),
        region: Yup.object().required(requiredMessage),
        locationType: Yup.object().required(requiredMessage),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
            innerRef={formRef}
        >
            {({ isSubmitting, values: { fromDate } }) => (
                <Form className="-modalElements">
                    <Modal
                        closeOnClickOutside={false}
                        title={title}
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {
                                handleClick: handleCancel,
                            },
                        }}
                    >
                        <FocusError />
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _s12 centered-checkbox">
                                    <div className="-mt10">
                                        <CheckboxField
                                            name="hidden"
                                            label="form.label.hidden"
                                            translate
                                        />
                                    </div>
                                    <span className="help-icon">
                                        <Tooltip
                                            tooltip={t(
                                                'form.label.hiddenInfoText'
                                            )}
                                        >
                                            <Icon
                                                name={ICONS.HELP_AND_INFO}
                                                color={COLORS.LIGHT_BLUE}
                                                size={ICON_SIZE.SIZE20}
                                            />
                                        </Tooltip>
                                    </span>
                                </div>
                                <div className="_12 _l6 -mt15">
                                    <InputField
                                        name="nameOfEmployer"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        placeholder="form.placeholder.nameOfEmployer"
                                        label="form.label.nameOfEmployerOnly"
                                    />
                                </div>
                                <div className="_12 _l6 -mt15">
                                    <InputField
                                        name="contractor"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        placeholder="form.placeholder.nameOfEmployer"
                                        label="form.label.nameOfContractor"
                                    />
                                </div>
                                <div className="_12 _l6 ">
                                    <InputField
                                        name="ultimateClient"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        placeholder="form.placeholder.ultimateClient"
                                        label="form.label.ultimateClient"
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <InputField
                                        name="projectName"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        label="form.label.projectName"
                                        placeholder="form.placeholder.projectName"
                                        required
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <SelectField
                                        name="position"
                                        label="form.label.position"
                                        placeholder="form.placeholder.position"
                                        required
                                        entityType={ENTITIES.DIVER_POSITION}
                                        searchable
                                    />
                                </div>

                                <div className="_12 _l6">
                                    <SelectField
                                        name="divingMode"
                                        label="form.label.divingModeIfApplicable"
                                        placeholder="form.placeholder.modeOfDive"
                                        entityType={ENTITIES.DIVING_MODE}
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <InputField
                                        name="nameOfVessel"
                                        label="form.label.nameOfVesselIfApplicable"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.location')}
                                </span>
                                <div className="_12 _l6 -mt20">
                                    <SelectField
                                        name="locationType"
                                        placeholder="form.placeholder.locationType"
                                        entityType={ENTITIES.LOCATION_TYPE}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l6 -mt20">
                                    <InputField
                                        name="locationDetail"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <SelectField
                                        name="country"
                                        label="form.label.countryOfWork"
                                        placeholder="form.placeholder.countryOfWork"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <SelectField
                                        name="region"
                                        label="form.label.geographicalRegion"
                                        placeholder="form.placeholder.geographicalRegion"
                                        entityType={ENTITIES.REGION}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _m6 _l6">
                                    <MultiselectField
                                        name="tasks"
                                        entityType={ENTITIES.DIVE_RECORD_WORK_TASK}
                                        searchable
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6 _l6">
                                    <MultiselectField
                                        name="objects"
                                        entityType={
                                            ENTITIES.DIVE_RECORD_WORK_OBJECT
                                        }
                                        searchable
                                        createNew
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_12 _m6 _l6">
                                    <MultiselectField
                                        name="activities"
                                        entityType={
                                            ENTITIES.DIVE_RECORD_WORK_ACTIVITY
                                        }
                                        searchable
                                        createNew
                                        dropup
                                    />
                                </div>
                                <div className="_12 _m6 _l6">
                                    <MultiselectField
                                        name="tools"
                                        entityType={ENTITIES.DIVE_RECORD_WORK_TOOL}
                                        searchable
                                        createNew
                                        dropup
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_12 _m12 _l12">
                                    <Note note="notes.historicalProjectModalNote" />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l6">
                                    <DateTimeField
                                        name="fromDate"
                                        label="form.label.start"
                                        dateFormat="MMM/y"
                                        dropup
                                        monthPicker
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <DateTimeField
                                        name="toDate"
                                        label="form.label.end"
                                        dateFormat="MMM/y"
                                        minDate={
                                            fromDate
                                                ? getTomorrowDate(fromDate)
                                                : null
                                        }
                                        dropup
                                        monthPicker
                                    />
                                </div>
                                <div className="_12 -mt5">
                                    <TextAreaField
                                        name="projectScope"
                                        label="form.label.projectScopeOfWork"
                                        placeholder="form.placeholder.projectScopeOfWork"
                                        flexibleTextarea
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
                )}
        </Formik>
    )
}

HistoricalProjectModalForm.propTypes = {
    setOpenCreateDiveRecordModal: PropTypes.func,
    initialData: PropTypes.object,
}

HistoricalProjectModalForm.defaultProps = {
    initialData: null,
    setHistoricalItem: () => {
    },
}

export default HistoricalProjectModalForm

import PropTypes from 'prop-types'

// returns value of objects property defined by key
const getValueWithKey = (key, item) => {
    let retVal = null
    if (key.includes('.')) {
        const splitedKeyValues = key.split('.')
        splitedKeyValues.forEach((keyValue) => {
            if (retVal === null) {
                retVal = item[keyValue]
            } else if (retVal) {
                retVal = retVal[keyValue]
            }
        })
    } else {
        retVal = item[key]
    }
    return retVal
}

const CellItem = ({ headerKey, headerItem, item }) => {
    const { CellComponent } = headerItem

    const value = getValueWithKey(headerKey, item)

    if (CellComponent)
        return <CellComponent {...headerItem} value={value} item={item} />

    return value || '/'
}

CellItem.propTypes = {
    headerKey: PropTypes.string,
    headerItem: PropTypes.object,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CellItem

import React from 'react'

import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const EmailCell = ({ value }) => {
    return (
        <div className="aligned-center -emailCell">
            <div className="-cellIcon">
                <Icon name={ICONS.MAIL} size={ICON_SIZE.SIZE18} />
            </div>
            <span>{value}</span>
        </div>
    )
}

export default EmailCell

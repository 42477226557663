import { useTranslate } from 'react-polyglot'

const ActiveStatusCell = ({ value }) => {
    const t = useTranslate()

    const color = value ? 'green' : 'red'
    const label = value ? 'active' : 'inactive'

    return (
        <div className={`a-status a-captionsTextRegular -${color}`}>
            {t(`general.${label}`)}
        </div>
    )
}

export default ActiveStatusCell

import React from 'react'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const ReleasePage = ({ releaseObject }) => {
    return (
        <div>
            <div className="-mb20">
                <span className="a-bodyTextMedium">{releaseObject.title}</span>
            </div>

            <div className="-mb20">
                <span className="a-mediumText">{releaseObject.introText}</span>
            </div>

            <div className="-mb30 -pt20 a-separator -dashedtop">
                {releaseObject.items.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="a-mediumText -mb20 -pb20 a-separator -dashedbottom"
                        >
                            <div className="aligned-center -gap5">
                                {item.icon && (
                                    <Icon
                                        name={item.icon}
                                        color={COLORS.LIGHT_BLUE}
                                        size={ICON_SIZE.SIZE20}
                                    />
                                )}
                                <p className="a-mediumTextSemiBold">
                                    {item.title}
                                </p>
                            </div>
                            <p className="-mt5">{item.description}</p>
                            <ul>
                                {item.descriptionList?.map((item, index) => (
                                    <li
                                        className="a-mediumText  -mt5 -liDisc"
                                        key={index}
                                    >
                                        {item.title && (
                                            <span className="a-mediumTextSemiBold">
                                                {item.title}:{' '}
                                            </span>
                                        )}
                                        <span className="a-lightText">
                                            {item.description}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )
                })}
            </div>

            <div className="-mb20 column">
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.UPCOMING_FEATURES}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE20}
                    />
                    <span className="a-bodyTextMedium">
                        {releaseObject.upcomingSectionTitle}
                    </span>
                </div>
                {releaseObject.upcomingSectionDescription && (
                    <span className="a-mediumText -mt5">
                        {releaseObject.upcomingSectionDescription}
                    </span>
                )}
            </div>

            <div className="-mb20">
                <ul>
                    {releaseObject.upcomingItems.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className="a-mediumText -liDisc -mb5"
                            >
                                <span className="a-mediumTextSemiBold">
                                    {item.title}
                                </span>{' '}
                                <span className="a-lightText">
                                    {item.description}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div>
                <span className="a-mediumText">{releaseObject.outroText}</span>
            </div>
        </div>
    )
}

export default ReleasePage

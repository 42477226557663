import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 1.2.0',
    introText:
        'We’re excited to roll out the latest updates, packed with features to improve your experience on our platform. From dive record verifications to user-friendly interface enhancements, here’s what’s new and upcoming:',
    items: [
        {
            title: 'Dive record verifications',
            description:
                'Strengthening trust and accuracy within our platform:',
            icon: ICONS.VERIFICATION_OUTLINE,
            descriptionList: [
                {
                    title: 'Level 2',
                    description:
                        'Platform member verification – An additional layer of verification for users, increasing platform reliability.',
                },
                {
                    title: 'Level 3',
                    description:
                        'Company verification for dive supervisors – Companies can now verify dive supervisors, adding credibility to dive records.',
                },
            ],
        },
        {
            title: 'UI/UX Improvements',
            description:
                'To provide you with a more seamless experience, we’ve made the following updates:',
            icon: ICONS.UI_UX_IMPROVEMENTS,
            descriptionList: [
                {
                    title: 'Project positions management',
                    description:
                        'Streamlined management of positions within projects, giving you more control and flexibility.',
                },
                {
                    title: 'Job applications',
                    description:
                        'Enhanced interface for job applications, inviting users via search and making it easier to manage applicants and whole process.',
                },
            ],
        },
        {
            title: 'Introducing packages',
            description:
                'We’re introducing tiered packages to better suit your needs:',
            icon: ICONS.PRO,
            descriptionList: [
                {
                    title: 'FREE',
                    description:
                        'Access essential features with no commitment, perfect for new users getting started.',
                },
                {
                    title: 'PRO',
                    description:
                        'Unlock advanced features designed for professional use, offering greater options and deeper insights.',
                },
            ],
        },
        {
            title: 'Dive organization accounts enhancement',
            description:
                'Organisation accounts creation and profile management process is now more comprehensive and tailored so organisations can more accurately represent their specifics and services.',
            icon: ICONS.BUILDING,
        },
        {
            title: 'User roles enhancement',
            description:
                'We’ve enhanced role selection to provide clarity and flexibility by allowing you to view detailed role descriptions and select roles from multiple categories to match your unique needs and expertise.',
            icon: ICONS.USERS,
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingSectionDescription:
        'We’re always working to bring more value to your experience. Here’s a sneak peek at what’s coming soon:',
    upcomingItems: [
        {
            title: 'UI improvements and skills & experience enhancements:',
            description:
                'Further refinements to our interface, making it easier to showcase and manage your skills and experience.',
        },
        {
            title: 'Total bottom times and days summary:',
            description:
                'A comprehensive summary of your total bottom times and dive days, helping you keep track of your diving milestones.',
        },
        {
            title: 'Historical dive projects :',
            description:
                'Access a record of past dive projects, allowing you to view and reflect on your professional journey.',
        },
    ],
    outroText:
        'Thank you for being part of our community! These updates are designed to enhance your experience and ensure the platform meets your evolving needs. We’re here to listen and grow with your feedback—reach out with any questions or insights. Stay tuned for more!',
}

const Release120 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release120

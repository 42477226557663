/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useRef,
    useEffect,
    useCallback,
    useContext,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import _ from 'lodash'

import { createEntityService } from 'services/entity.service'

import useFetchData from 'hooks/useFetchData'

import { BASE_URL } from 'utils/axiosClient'
import { isFormDisabled } from 'utils/form'
import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, ICON_SIZE, SELECT_VALUE_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import { SEARCH_DELAY } from 'constants/constants'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Dropdown from 'components/Dropdown'
import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'
import Icon from './Icon'

const Select = ({
    open,
    setOpen,
    outsideClick,
    label,
    value,
    setValue,
    setTouched,
    entityType,
    params,
    searchable,
    required,
    defaultOptions,
    reload,
    condition,
    error,
    isJSONAPI,
    apiUrl,
    placeholder,
    displayAttribute, //The displayAttribute is required if the select options are objects, otherwise null should be passed. Default value is 'name'.
    searchAttribute,
    disabled,
    valueType,
    customError,
    iconAttribute,
    showLabel,
    showPlaceholder,
    createNew,
    createNewParams,
    customFieldError,
    dropup,
    icon,
    disabledItems,
    tooltip,
    handleProFeature,
    translateItem,
    ...props
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const searchRef = useRef(null)
    const dropdownRef = useRef(null)
    const fieldRef = useRef()
    const isFirstRender = useRef(true)

    const [search, setSearch] = useState('')

    const showIcon = iconAttribute && value

    const isDisabled = disabled || isFormDisabled(fieldRef)

    const { data, meta, isLoading, fetchData, loadMore } = useFetchData(
        entityType,
        params,
        condition,
        true,
        false,
        defaultOptions,
        isJSONAPI,
        apiUrl
    )

    useEffect(() => {
        if (!isFirstRender.current && condition) {
            fetchData()
        } else {
            isFirstRender.current = false
        }
    }, [reload])

    useEffect(() => {
        if (outsideClick && setTouched) {
            setTouched(true)
        }
    }, [outsideClick])

    useEffect(() => {
        if (!open && search) {
            searchRef.current.value = ''
            setSearch('')
            fetchData()
        }
        if (!open && dropdownRef.current) dropdownRef.current.scrollTop = 0
    }, [open])

    const handleFocus = () => {
        if (isDisabled) return
        setOpen(true)
    }

    const removeValue = () => {
        if (isDisabled) {
            return
        }
        setOpen(false)
        setValue(null)
    }

    const getIsDisabledItem = (item) =>
        disabledItems.some(
            (el) => (el.id || el).toString() === (item.id || item).toString()
        )

    const handleSelect = (item) => {
        if (item.isProFeature && handleProFeature) {
            handleProFeature()
        }

        switch (valueType) {
            case SELECT_VALUE_TYPE.STRING:
                setValue(item?.id || item)
                break
            case SELECT_VALUE_TYPE.OBJECT:
            default:
                setValue(item)
                break
        }

        setOpen(false)
    }

    const handleSearchDebounce = useCallback(
        _.debounce((event) => {
            const { value } = event.target

            setSearch(value)

            if (dropdownRef.current) dropdownRef.current.scrollTop = 0

            fetchData({ [searchAttribute]: value, ...params })
        }, SEARCH_DELAY),
        [params]
    )

    const handleOpen = () => {
        if (isDisabled) return
        if (!open && searchRef.current) {
            searchRef.current.focus()
        }
        if (open && setTouched) {
            setTouched(true)
        }
        setOpen(!open)
    }

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
                [searchAttribute]: search,
            })
        }
    }

    const handleCreateNew = async () => {
        try {
            if (
                !createNew ||
                !search ||
                data.some((item) => item.name === search)
            )
                return
            const newItem = await createEntityService(
                ENTITIES.CREATE_BASIC_ENTITY,
                {
                    name: search,
                    entity_type: entityType,
                    ...createNewParams,
                },
                false
            )
            handleSelect({
                id: newItem.data.id,
                name: newItem.data.attributes.name,
                entityType,
            })
        } catch (error) {
            setAlert(error.response.data.message, ALERT_TYPES.ERROR)
        }
    }

    const renderItem = (item) => {
        if (translateItem) {
            return translateItem(item, t)
        } else if (displayAttribute) {
            return item[displayAttribute]
        }

        return item
    }

    const filterData = () => {
        return defaultOptions && search
            ? data.filter((item) => {
                  const itemValue = item.id ? item[searchAttribute] : item
                  return itemValue.toLowerCase().includes(search.toLowerCase())
              })
            : data
    }

    const renderOptions = () => {
        const filteredData = filterData()
        if (filteredData.length > 0) {
            return filteredData.map((item, index) => {
                const isDisabled = getIsDisabledItem(item)
                return (
                    <li
                        className={`-result ${isDisabled ? '-disabled' : ''}`}
                        key={index}
                        onClick={
                            !isDisabled ? () => handleSelect(item) : undefined
                        }
                        title={
                            item.isProFeature
                                ? t('general.featureForProUsers')
                                : ''
                        }
                    >
                        {iconAttribute && (
                            <div className="a-flag">
                                <img
                                    className="a-flag__img"
                                    src={`${BASE_URL}${item[iconAttribute]}`}
                                    alt={iconAttribute}
                                />
                            </div>
                        )}
                        {icon && (
                            <div className="-customIcon">
                                <Icon
                                    name={item[icon]}
                                    size={ICON_SIZE.SIZE32}
                                />
                            </div>
                        )}
                        {item.isProFeature && (
                            <Icon
                                name={ICONS.PRO}
                                size={ICON_SIZE.SIZE20}
                                color={COLORS.YELLOW}
                            />
                        )}
                        {renderItem(item)}
                    </li>
                )
            })
        }
        return (
            <li className="-result a-lightText a-captionsTextRegular -noHover">
                {t('general.noItems')}
            </li>
        )
    }

    const getValue = () => {
        if (translateItem) {
            return translateItem(value, t)
        }
        if (valueType === SELECT_VALUE_TYPE.OBJECT && displayAttribute) {
            return value ? value[displayAttribute] || '' : ''
        }
        if (valueType === SELECT_VALUE_TYPE.STRING && displayAttribute) {
            const selectedOption = data.find(
                (item) => (item.id || item).toString() === value?.toString()
            )
            return selectedOption ? selectedOption[displayAttribute] : ''
        }
        return value || ''
    }

    return (
        <div
            scroll-attribute={props.name}
            className={`m-selectGroup ${required ? '-required' : ''} ${
                isDisabled ? '-disabled' : ''
            } ${tooltip ? '-overflowVisible' : ''}`}
        >
            {showLabel && (
                <FieldLabel
                    label={label || `form.label.${props.name}`}
                    tooltip={tooltip}
                    required={required}
                />
            )}
            <div className="m-selectGroup__container">
                <input
                    {...props}
                    ref={fieldRef}
                    placeholder={
                        showPlaceholder
                            ? t(placeholder || `form.placeholder.${props.name}`)
                            : ''
                    }
                    value={getValue()}
                    onFocus={handleFocus}
                    className={`m-selectGroup__select a-input ${
                        open ? '-open' : ''
                    } ${searchable && open ? '-hide' : ''} ${
                        error || customFieldError ? '-error' : ''
                    } ${showIcon ? '-withFlag' : '-noFlag'}`}
                    readOnly
                    disabled={isDisabled}
                    autoComplete="new-country-area"
                />
                {value && !required && (
                    <div
                        className="clear"
                        onClick={removeValue}
                        title={t('general.clearSelection')}
                    >
                        <Icon
                            name={ICONS.CLEAR}
                            size={ICON_SIZE.SIZE20}
                            color={COLORS.DARK_BLUE_60}
                            title={t('button.clearSelection')}
                        />
                    </div>
                )}
                {showIcon && (
                    <div className="a-flag">
                        <img
                            className="a-flag__img"
                            src={`${BASE_URL}${value[iconAttribute]}`}
                            alt={iconAttribute}
                        />
                    </div>
                )}
                {searchable && (
                    <input
                        className={`m-selectGroup__searchInput a-input ${
                            showIcon ? '-withFlag' : '-noFlag'
                        }`}
                        onClick={handleFocus}
                        onChange={handleSearchDebounce}
                        autoComplete="new-dropdown-field"
                        ref={searchRef}
                        disabled={isDisabled}
                    />
                )}
                <span
                    className="m-selectGroup__arrow"
                    onClick={handleOpen}
                ></span>
                <Dropdown
                    open={open}
                    dropdownRef={dropdownRef}
                    handleReachedBottom={handleLoadMore}
                    dropup={dropup}
                    explanation={
                        createNew ? t('general.createNewExplanation') : ''
                    }
                >
                    <ul>
                        {renderOptions()}
                        {loadMore && (
                            <li className="m-selectGroup__loader a-captionsTextRegular a-lightText">
                                <span>{t('general.loading')}</span>
                            </li>
                        )}
                        {!loadMore && createNew && search && (
                            <li
                                className="-result -create a-mediumTextSemiBold"
                                key="new"
                                onClick={handleCreateNew}
                            >
                                <Icon
                                    name={ICONS.PLUS}
                                    color={COLORS.LIGHT_BLUE}
                                />
                                {`${t('general.createNew')} "${search}"`}
                            </li>
                        )}
                    </ul>
                </Dropdown>
            </div>
            {!customError && (error || customFieldError) && (
                <FieldError error={error || customFieldError} />
            )}
        </div>
    )
}

export const SelectMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    entityType: PropTypes.string,
    params: PropTypes.object,
    searchable: PropTypes.bool,
    required: PropTypes.bool,
    reload: PropTypes.any,
    condition: PropTypes.bool,
    defaultOptions: PropTypes.array,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    displayAttribute: PropTypes.string,
    searchAttribute: PropTypes.string,
    disabled: PropTypes.bool,
    iconAttribute: PropTypes.string,
    valueType: PropTypes.oneOf([
        SELECT_VALUE_TYPE.STRING,
        SELECT_VALUE_TYPE.OBJECT,
    ]),
    showLabel: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    createNew: PropTypes.bool,
    createNewParams: PropTypes.object,
    customFieldError: PropTypes.string,
    icon: PropTypes.string,
    disabledItems: PropTypes.array,
    tooltip: PropTypes.string,
}

Select.propTypes = {
    ...SelectMainPropTypes,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    dropup: PropTypes.bool,
}

Select.defaultProps = {
    displayAttribute: 'name',
    searchAttribute: 'name',
    condition: true,
    disabled: false,
    valueType: SELECT_VALUE_TYPE.OBJECT,
    showLabel: true,
    showPlaceholder: true,
    createNew: false,
    dropup: false,
    disabledItems: [],
}

export default clickOutsideHOC(Select, false, true)

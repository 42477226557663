import PropTypes from 'prop-types'

const TruncatedText = ({ text, textLength }) => {
    const truncateText = (item) => {
        if (item.length > textLength) {
            return item.slice(0, textLength) + '...'
        }
        return item
    }

    return <span>{truncateText(text)}</span>
}

TruncatedText.propTypes = {
    text: PropTypes.string.isRequired,
    textLength: PropTypes.number,
}

TruncatedText.defaultProps = {
    textLength: 15,
}

export default TruncatedText

import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment'

import { persistEntityService } from 'services/entity.service'

import useFetchDataById from 'hooks/useFetchDataById'
import useSaveByStep from './hooks/useSaveByStep'
import FocusError from '../../../components/FocusError'
import useWindowDimensions from 'hooks/useWindowDimension'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'
import { AlertContext } from 'contexts/AlertContext'

import {
    ALERT_TYPES,
    DIVE_PHYSICAL_CHARACTERISTIC,
    DIVE_RECORD_STATUS,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import { YES_NO_BOOLEAN_OPTIONS } from 'constants/constants'
import {
    AIR_TEMPERATURE_IMPERIAL,
    AIR_TEMPERATURE_METRIC,
    WATER_CURRENT,
    WATER_TEMPERATURE_IMPERIAL,
    WATER_TEMPERATURE_METRIC,
    WATER_VISIBILITY_IMPERIAL,
    WATER_VISIBILITY_METRIC,
    WAVE_HEIGHT_IMPERIAL,
    WAVE_HEIGHT_METRIC,
    WIND_CONDITIONS,
} from './constants/diveRecordEntities'

import {
    InputField,
    DateTimeField,
    SelectField,
    MultiselectField,
    RadioGroupField,
    TextAreaField,
} from 'components/formFields'
import Separator from 'components/Separator'
import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'

import GeneralInformationFormContext from './formContext/GeneralInformationFormContext'
import { isChamberPressurizationOnly } from '../../../utils/diveRecordFunctions'
import ProjectFormContext from 'screens/common/singleProject/formContext/ProjectFormContext'
import useQueryParams from 'hooks/useQueryParams'
import {
    MOMENT_FORMATS,
    getIsDateSameOrBeforeCurrentDate,
} from 'services/moment.service'
import FieldError from 'components/FieldError'
import Checkbox from 'components/Checkbox'

const GeneralInformationRecord = () => {
    const t = useTranslate()
    const { isMobile } = useWindowDimensions()
    const { projectId, diveRecordId } = useParams()
    const { filterParams } = useQueryParams()
    const { divingMode, role } = filterParams

    const formRef = useRef()

    const [useMyLocation, setUseMyLocation] = useState(false)
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)

    const { currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const {
        diveRecord,
        diveSupervisors,
        isLoadingDiveSupervisors,
        divingModes,
        isLoadingDivingModes,
        isSupervisorDiveRecord,
        goToNextStep,
        goPreviousStep,
        handlePostSaveAction,
        goToStepRef,
    } = useContext(DiveRecordContext)

    const generalInformationId =
        diveRecord.data?.diveRecordGeneralInformation?.id

    const isImperialUnit =
        diveRecord.data?.unitImperial ?? currentUser.userSetting.unitImperial
    const unitOfMeasurementLabel = isImperialUnit
        ? 'form.label.ft'
        : 'form.label.m'

    const today = moment(new Date()).toISOString()

    const { data: projectData, isLoading: isLoadingProject } = useFetchDataById(
        ENTITIES.PROJECT,
        projectId,
        {
            include:
                'country,company,region,locationType,industry,projectIndustryType,divingMode,countryOfMobilization,projectStatus,positions,bodyOfWater,industry',
        }
    )

    const { data: initialData, isLoading } = useFetchDataById(
        ENTITIES.GENERAL_INFORMATION_RECORD,
        generalInformationId,
        {
            include:
                'bodyOfWater,region,bottomConditions,country,divingMode,projectIndustryType,divingPlatform,industry,locationType,waterQualities,supervisor,divePhysicalCharacteristics,divers,breathingApparatusType',
        },
        !!generalInformationId,
        generalInformationId
    )

    useEffect(() => {
        if (initialData) {
            setUseMyLocation(!!initialData.latitude && !!initialData.longitude)
            setLatitude(initialData.latitude)
            setLongitude(initialData.longitude)
        }
    }, [initialData])

    const handleSave = async (formData, formActions, complete = true) => {
        try {
            await formRef.current.validateForm()
            const isValid = !Object.keys(formRef.current.errors).length

            const { data: generalInformationData } = await persistEntityService(
                ENTITIES.GENERAL_INFORMATION_RECORD,
                {
                    ...formData,
                    supervisor: {
                        id: formData.supervisor?.id,
                        entityType: ENTITIES.USER,
                    },
                    divers: formData.divers.map((item) => ({
                        ...item,
                        entityType: ENTITIES.USER,
                    })),
                    diveRecordType: Number(role),
                    project:
                        diveRecord?.data?.project || projectData
                            ? {
                                  id:
                                      diveRecord?.data?.project?.id ||
                                      projectData?.id,
                                  entityType: ENTITIES.PROJECT,
                              }
                            : null,
                    complete: initialData?.complete ? isValid : complete,
                    unitImperial: !!isImperialUnit,
                    latitude: latitude,
                    longitude: longitude,
                },
                generalInformationId
            )

            complete && !goToStepRef.current
                ? goToNextStep(generalInformationData.diveRecord.id)
                : handlePostSaveAction(false, false, goToStepRef.current)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useSaveByStep(formRef, handleSave)

    if (
        (projectId && (!projectData || !diveSupervisors)) ||
        (diveRecordId && !(generalInformationId && initialData))
    )
        return null

    if (isLoadingDiveSupervisors || isLoadingDivingModes) return <Loader />

    const getSupervisor = () => {
        if (isSupervisorDiveRecord) return null
        if (diveSupervisors.length === 1) return diveSupervisors[0]
        if (initialData?.supervisor)
            return {
                id: initialData.supervisor.id,
                name: initialData.supervisor.fullName,
            }
        return null
    }

    const getProjectAltitude = () => {
        if (!projectData) {
            return null
        }

        const isProjectImperial = projectData.unitImperial
        if (isProjectImperial === isImperialUnit) {
            return projectData.altitude
        }

        if (isProjectImperial === true && isImperialUnit === false) {
            return Math.round(projectData.altitude * 0.3048)
        }

        return Math.round(projectData.altitude / 0.3048)
    }

    const initialValues = {
        dateOfDive: initialData?.dateOfDive ?? today,
        nameOfEmployer:
            projectData?.company?.name ?? initialData?.nameOfEmployer ?? '',
        nameOfProject: projectData?.name ?? initialData?.nameOfProject ?? '',
        industry: projectData?.industry ?? initialData?.industry ?? null,
        projectIndustryType:
            projectData?.projectIndustryType ??
            initialData?.projectIndustryType ??
            null,
        nameOfVessel:
            projectData?.nameOfVessel ?? initialData?.nameOfVessel ?? '',
        diveContractorRepresentative:
            initialData?.diveContractorRepresentative ?? '',
        divers: initialData?.divers ?? [],
        diversText: initialData?.diversText ?? '',
        supervisor: getSupervisor(),
        supervisorName: initialData?.supervisorName ?? '',
        comment: initialData?.comment ?? '',
        country: projectData?.country ?? initialData?.country ?? null,
        region: projectData?.region ?? initialData?.region ?? null,
        locationDetail:
            projectData?.locationDetail ?? initialData?.locationDetail ?? '',
        locationType:
            projectData?.locationType ?? initialData?.locationType ?? null,
        bodyOfWater:
            projectData?.bodyOfWater ?? initialData?.bodyOfWater ?? null,
        altitude: projectData?.altitude
            ? getProjectAltitude()
            : initialData?.altitude ?? '',
        divingMode: divingMode
            ? divingModes.find((item) => item.id.toString() === divingMode)
            : initialData?.divingMode ?? null,
        divingPlatform: initialData?.divingPlatform ?? null,
        divePhysicalCharacteristics:
            initialData?.divePhysicalCharacteristics ?? [],
        lengthDistanceOfPenetration:
            initialData?.lengthDistanceOfPenetration ?? '',
        waveHeight: initialData?.waveHeight ?? '',
        waterTemperature: initialData?.waterTemperature ?? '',
        airTemperature: initialData?.airTemperature ?? '',
        waterVisibility: initialData?.waterVisibility ?? '',
        waterCurrent: initialData?.waterCurrent ?? '',
        waterQualities: initialData?.waterQualities ?? [],
        bottomConditions: initialData?.bottomConditions ?? [],
        nightTimeDive: initialData?.nightTimeDive ?? null,
        breathingApparatusType: initialData?.breathingApparatusType ?? null,
        windConditions: initialData?.windConditions ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        dateOfDive: Yup.date()
            .test('dateOfDive', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.dateOfDiveInTheFuture'),
                    path: 'dateOfDive',
                })
            })
            .required(requiredMessage),
        nameOfEmployer: Yup.string().trim().required(requiredMessage),
        nameOfProject: Yup.string().trim().required(requiredMessage),
        industry: Yup.object().required(requiredMessage),
        projectIndustryType: Yup.object().required(requiredMessage),
        supervisor:
            projectId && !isSupervisorDiveRecord
                ? Yup.object().required(requiredMessage)
                : Yup.object().nullable(),
        diveContractorRepresentative:
            isSupervisorDiveRecord && projectId
                ? Yup.string().trim().required(requiredMessage)
                : Yup.string(),
        divers:
            isSupervisorDiveRecord && projectId
                ? Yup.array().min(1, requiredMessage).required(requiredMessage)
                : Yup.array().notRequired(),
        diversText: isSupervisorDiveRecord && Yup.string(),
        comment: Yup.string().trim(),
        country: Yup.object().required(requiredMessage),
        region: Yup.object().notRequired(),
        locationType: Yup.object().required(requiredMessage),
        bodyOfWater: Yup.object().required(requiredMessage),
        altitude: Yup.number().min(0, t('form.error.positiveAltitude')),
        divingMode: Yup.object().required(requiredMessage),
        divingPlatform: Yup.object().required(requiredMessage),
        divePhysicalCharacteristics: Yup.array().notRequired(),
        lengthDistanceOfPenetration: Yup.number().when(
            'divePhysicalCharacteristics',
            {
                is: (val) =>
                    val.some((item) =>
                        item.code?.includes(
                            DIVE_PHYSICAL_CHARACTERISTIC.LENGTH_DISTANCE_OF_PENETRATION
                        )
                    ),
                then: () =>
                    Yup.number()
                        .min(0, t('form.error.invalidNumber'))
                        .required(requiredMessage),
                otherwise: () => Yup.number(),
            }
        ),
        waveHeight: Yup.string().notRequired(),
        waterTemperature: Yup.string().notRequired(),
        airTemperature: Yup.string().notRequired(),
        waterVisibility: Yup.string().notRequired(),
        waterCurrent: Yup.string().notRequired(),
        waterQualities: Yup.array().notRequired(),
        bottomConditions: Yup.array().notRequired(),
        nightTimeDive: Yup.bool().notRequired(),
        breathingApparatusType: isSupervisorDiveRecord
            ? Yup.object().when('divingMode', {
                  is: (val) => isChamberPressurizationOnly(val),
                  then: () => Yup.object().nullable(),
                  otherwise: () => Yup.object().required(requiredMessage),
              })
            : Yup.object().nullable(),
    })

    const rejectComment =
        diveRecord?.data?.status === DIVE_RECORD_STATUS.REJECTED.id &&
        diveRecord?.data?.rejectComment

    const resetCoordinates = () => {
        setLatitude(null)
        setLongitude(null)
    }

    const useMyPreciseLocation = (event) => {
        const checked = event.target.checked
        setUseMyLocation(checked)
        if (checked) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function setPosition(position) {
                        setLatitude(position.coords.latitude)
                        setLongitude(position.coords.longitude)
                        setAlert(
                            t('form.success.locationCapturedSuccessfully'),
                            ALERT_TYPES.SUCCESS
                        )
                    },
                    function showLocationError(error) {
                        setUseMyLocation(false)
                        resetCoordinates()
                        if (error.code === error.PERMISSION_DENIED) {
                            setAlert(
                                t('form.error.locationPermissionDenied'),
                                ALERT_TYPES.WARNING
                            )
                        } else {
                            setAlert(
                                t('form.error.locationNotAvailable'),
                                ALERT_TYPES.WARNING
                            )
                        }
                    }
                )
            } else {
                setUseMyLocation(false)
                resetCoordinates()
                setAlert(
                    t('form.error.locationNotAvailable'),
                    ALERT_TYPES.WARNING
                )
            }
        } else {
            resetCoordinates()
        }
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSave}
                innerRef={formRef}
            >
                {({ handleSubmit, values, isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <ProjectFormContext />
                        <GeneralInformationFormContext
                            unitOfMeasurement={t(unitOfMeasurementLabel)}
                        />
                        <div className="_wr -contentElements -diveRecordContent">
                            {rejectComment && (
                                <div className="_w">
                                    <div className="_12 -mb20">
                                        <FieldError
                                            error={`${t('general.rejectionComment')}: ${rejectComment}`}
                                            className={
                                                '-boxError aligned-start'
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="_w">
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.locationDetails')}
                                </span>
                            </div>
                            <div className="_w">
                                <div className="_12 _m4 ">
                                    <DateTimeField
                                        name="dateOfDive"
                                        required
                                        maxDate={new Date(today)}
                                    />
                                </div>
                                <div className="_12 _m4 ">
                                    <InputField
                                        name="nameOfEmployer"
                                        required
                                        disabled={!!projectId}
                                    />
                                </div>
                                <div className="_12 _m4 ">
                                    <InputField
                                        name="nameOfProject"
                                        required
                                        disabled={!!projectId}
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="industry"
                                        entityType={ENTITIES.INDUSTRY}
                                        params={
                                            projectData
                                                ? {
                                                      onlyUser: false,
                                                  }
                                                : null
                                        }
                                        createNew
                                        searchable
                                        required
                                        disabled={!!projectData?.industry}
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="projectIndustryType"
                                        label="form.label.projectType"
                                        placeholder="form.placeholder.projectType"
                                        entityType={
                                            ENTITIES.PROJECT_INDUSTRY_TYPES
                                        }
                                        params={
                                            projectData
                                                ? null
                                                : {
                                                      'industries.id':
                                                          values.industry?.id,
                                                  }
                                        }
                                        createNew
                                        searchable
                                        required
                                        disabled={
                                            projectData?.projectIndustryType
                                                ? true
                                                : !values.industry
                                        }
                                        reload={values.industry}
                                        createNewParams={{
                                            related_entity: ENTITIES.INDUSTRY,
                                            related_entity_id:
                                                values.industry?.id,
                                        }}
                                    />
                                </div>
                                <div className="_12 _m4 ">
                                    <InputField
                                        name="nameOfVessel"
                                        label="form.label.nameOfVesselIfApplicable"
                                        placeholder="form.placeholder.vesselName"
                                        disabled={!!projectData?.nameOfVessel}
                                    />
                                </div>
                                {!isSupervisorDiveRecord && (
                                    <div className="_12 _m4 ">
                                        {projectId ? (
                                            <SelectField
                                                name="supervisor"
                                                label="form.label.diveSupervisor"
                                                placeholder="form.placeholder.diveSupervisor"
                                                entityType={
                                                    ENTITIES.PROJECT_SUPERVISOR
                                                }
                                                params={{
                                                    project: projectId,
                                                }}
                                                disabled={
                                                    diveSupervisors.length === 1
                                                }
                                                condition={
                                                    diveSupervisors.length !== 1
                                                }
                                                required
                                            />
                                        ) : (
                                            <InputField
                                                name="supervisorName"
                                                placeholder="form.placeholder.diveSupervisor"
                                            />
                                        )}
                                    </div>
                                )}
                                {isSupervisorDiveRecord && (
                                    <Fragment>
                                        <div className="_12 _m4 ">
                                            <InputField
                                                name="diveContractorRepresentative"
                                                placeholder="form.placeholder.supervisor"
                                                required={!!projectId}
                                            />
                                        </div>
                                        {projectId ? (
                                            <div className="_12 _m4">
                                                <MultiselectField
                                                    name="divers"
                                                    label="form.label.nameOfDivers"
                                                    placeholder="form.placeholder.supervisor"
                                                    entityType={
                                                        ENTITIES.PROJECT_DIVERS
                                                    }
                                                    params={{
                                                        project: projectId,
                                                    }}
                                                    displayAttribute="fullName"
                                                    searchable
                                                    required
                                                />
                                            </div>
                                        ) : (
                                            <div className="_12 _m4">
                                                <InputField
                                                    name="diversText"
                                                    label="form.label.nameOfDivers"
                                                    placeholder="form.placeholder.supervisor"
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                                <div className="_12 ">
                                    <TextAreaField
                                        name="comment"
                                        largeTextarea
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.geographicalData')}
                                </span>
                                <div
                                    className={`_12 -mb20 aligned-center ${
                                        isMobile
                                            ? '-gap10 column aligned-start'
                                            : '-gap20'
                                    } flex-wrap`}
                                >
                                    <Checkbox
                                        name="location"
                                        value={useMyLocation}
                                        label="form.label.useMyPreciseLocation"
                                        translate
                                        onChange={useMyPreciseLocation}
                                    />
                                    <div className="aligned-center -gap5">
                                        <span className="a-mediumText">
                                            {t('form.label.latitude')}:
                                        </span>
                                        <span className="a-mediumText a-lightText -opacity-60">
                                            {latitude ?? '/'}
                                        </span>
                                    </div>
                                    <div className="aligned-center -gap5">
                                        <span className="a-mediumText">
                                            {t('form.label.longitude')}:
                                        </span>
                                        <span className="a-mediumText a-lightText -opacity-60">
                                            {longitude ?? '/'}
                                        </span>
                                    </div>
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="country"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                        disabled={!!projectId}
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="region"
                                        label="form.label.geographicalRegion"
                                        placeholder="form.placeholder.selectRegion"
                                        entityType={ENTITIES.REGIONS}
                                        searchable
                                        disabled={!!projectId}
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <InputField
                                        name="locationDetail"
                                        disabled={
                                            !!projectId &&
                                            !!projectData?.locationDetail
                                        }
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="locationType"
                                        entityType={ENTITIES.LOCATION_TYPE}
                                        searchable
                                        required
                                        disabled={!!projectId}
                                        dropup
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="bodyOfWater"
                                        entityType={ENTITIES.BODY_OF_WATER}
                                        searchable
                                        required
                                        disabled={!!projectData?.bodyOfWater}
                                        dropup
                                    />
                                </div>
                                <div className="_12 _m4 ">
                                    <InputField
                                        name="altitude"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        units={unitOfMeasurementLabel}
                                        disabled={!!projectData?.altitude}
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.diveDetails')}
                                </span>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="divingMode"
                                        entityType={ENTITIES.DIVING_MODE}
                                        searchable
                                        required
                                        disabled={!!initialValues.divingMode}
                                        dropup
                                    />
                                </div>
                                <div className="_12 _m4">
                                    <SelectField
                                        name="divingPlatform"
                                        entityType={ENTITIES.DIVING_PLATFORM}
                                        searchable
                                        required
                                        dropup
                                    />
                                </div>
                            </div>
                            {!isChamberPressurizationOnly(
                                values.divingMode
                            ) && (
                                <div className="_w">
                                    <div className="_12 _m4">
                                        <MultiselectField
                                            name="divePhysicalCharacteristics"
                                            entityType={
                                                ENTITIES.DIVE_PHYSICAL_CHARACTERISTIC
                                            }
                                            searchable
                                            createNew
                                            dropup
                                        />
                                    </div>
                                    {values.divePhysicalCharacteristics.some(
                                        (item) =>
                                            item.code?.includes(
                                                DIVE_PHYSICAL_CHARACTERISTIC.LENGTH_DISTANCE_OF_PENETRATION
                                            )
                                    ) && (
                                        <div className="_12 _m4 ">
                                            <InputField
                                                name="lengthDistanceOfPenetration"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                                required
                                                units={unitOfMeasurementLabel}
                                            />
                                        </div>
                                    )}
                                    <Separator />
                                    {isSupervisorDiveRecord ? (
                                        <Fragment>
                                            <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                                {t(
                                                    'general.breathingApparatusType'
                                                )}
                                            </span>

                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="breathingApparatusType"
                                                    entityType={
                                                        ENTITIES.BREATHING_APPARATUS_TYPE
                                                    }
                                                    searchable
                                                    required={
                                                        isSupervisorDiveRecord &&
                                                        !isChamberPressurizationOnly(
                                                            values.diveRecord
                                                        )
                                                    }
                                                    dropup
                                                />
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <span className="-mb20 _12">
                                                {t(
                                                    'general.environmentalConditions'
                                                )}
                                            </span>
                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="waveHeight"
                                                    defaultOptions={
                                                        isImperialUnit
                                                            ? WAVE_HEIGHT_IMPERIAL
                                                            : WAVE_HEIGHT_METRIC
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                    displayAttribute={null}
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="waterTemperature"
                                                    defaultOptions={
                                                        isImperialUnit
                                                            ? WATER_TEMPERATURE_IMPERIAL
                                                            : WATER_TEMPERATURE_METRIC
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                    displayAttribute={null}
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="airTemperature"
                                                    defaultOptions={
                                                        isImperialUnit
                                                            ? AIR_TEMPERATURE_IMPERIAL
                                                            : AIR_TEMPERATURE_METRIC
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                    displayAttribute={null}
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="waterVisibility"
                                                    defaultOptions={
                                                        isImperialUnit
                                                            ? WATER_VISIBILITY_IMPERIAL
                                                            : WATER_VISIBILITY_METRIC
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                    displayAttribute={null}
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="waterCurrent"
                                                    defaultOptions={
                                                        WATER_CURRENT
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                    displayAttribute={null}
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <MultiselectField
                                                    name="waterQualities"
                                                    entityType={
                                                        ENTITIES.WATER_QUALITY
                                                    }
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <MultiselectField
                                                    name="bottomConditions"
                                                    entityType={
                                                        ENTITIES.BOTTOM_CONDITION
                                                    }
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4">
                                                <SelectField
                                                    name="windConditions"
                                                    defaultOptions={
                                                        WIND_CONDITIONS
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                    displayAttribute={null}
                                                    searchable
                                                    dropup
                                                />
                                            </div>
                                            <div className="_12 _m4 nightTimeDive">
                                                <RadioGroupField
                                                    name="nightTimeDive"
                                                    defaultOptions={
                                                        YES_NO_BOOLEAN_OPTIONS
                                                    }
                                                    valueType={
                                                        SELECT_VALUE_TYPE.STRING
                                                    }
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                        <StepButtons
                            backlabel="button.cancel"
                            skipLabel="button.saveAndContinueLater"
                            handleBack={goPreviousStep}
                            handleNext={handleSubmit}
                            handleSkip={() => handleSave(values, null, false)}
                            disableNext={isSubmitting}
                            canSkip
                        />
                        {(isSubmitting ||
                            isLoadingProject ||
                            isLoading ||
                            isLoadingDivingModes) && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default GeneralInformationRecord

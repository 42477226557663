import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'
import { MOMENT_FORMATS, formatDate } from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'

import { ACCESS_CONTROL_STATUSES, ALERT_TYPES, BUTTON_SIZE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Note from '../../../components/Note'

const { NEW, REQUESTED_AGAIN, REJECTED, GRANTED_WITHOUT_FILES } =
    ACCESS_CONTROL_STATUSES

const RequestAccess = ({
    module,
    title,
    status,
    canRequestAccess,
    profile,
    checkAccessControl,
    requestTime,
    grantedWithoutFilesMessage,
    buttonSize,
    onlyButton,
    onlyNoteStatus,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [isLoading, setIsLoading] = useState(false)

    const accessRequest = async () => {
        try {
            setIsLoading(true)
            await createEntityService(ENTITIES.DATA_ACCESS_REQUEST, {
                module,
                target: { id: profile.id, entityType: ENTITIES.USER },
            })
            checkAccessControl()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    const requestAgain = async () => {
        try {
            setIsLoading(true)

            await createEntityService(
                `${ENTITIES.DATA_ACCESS_RESEND}/${profile.id}?module=${module}`,
                {},
                false
            )
            checkAccessControl()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    const getStatusText = () => {
        switch (status) {
            case NEW.id:
                return `${t('general.accessRequestPending')}`
            case REJECTED.id:
                return `${t('general.accessRequestRejected')} ${formatDate(
                    requestTime,
                )} ${t('general.at')} ${formatDate(
                    requestTime,
                    MOMENT_FORMATS.TIME,
                )}.`
            case REQUESTED_AGAIN.id:
                return `${t('general.accessRequestedAgainPending')}`
            default:
                return null
        }
    }

    if (onlyButton) {
        const noButtonStatuses = [NEW.id, REQUESTED_AGAIN.id]
        if (noButtonStatuses.indexOf(status) === -1) {
            return <Button
                disabled={isLoading}
                label={
                    status === REJECTED.id
                        ? 'button.requestAgain'
                        : 'button.sendRequest'
                }
                onClick={
                    status === REJECTED.id
                        ? requestAgain
                        : accessRequest
                }
                buttonSize={buttonSize}
            />
        }
        return <></>
    }

    if (onlyNoteStatus) {
        const noteStatus = getStatusText(status)
        if (noteStatus) {
            return <Note note={noteStatus} translate={false} bottomMargin={false}/>
        }
        return <></>
    }

    return (
        <div className="m-profileTabs">
            {title && (
                <div className="aligned-center ">
                    <span className="a-bodyTextMedium ">{t(title)}</span>
                </div>
            )}
            {canRequestAccess && (
                <Fragment>
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.sendRequestForDataAccess')}
                    </p>
                    <div className="-mt30">
                        <Button
                            disabled={isLoading}
                            label="button.sendRequest"
                            onClick={accessRequest}
                            buttonSize={buttonSize}
                        />
                    </div>
                </Fragment>
            )}
            {status === NEW.id && (
                <p className="a-mediumText a-lightText -mt20">
                    {getStatusText()}
                </p>
            )}
            {status === REJECTED.id && (
                <Fragment>
                    <p className="a-mediumText a-lightText -mt20">
                        {getStatusText()}
                    </p>
                    <div className="-mt30">
                        <Button
                            disabled={isLoading}
                            label="button.requestAgain"
                            onClick={requestAgain}
                            buttonSize={buttonSize}
                        />
                    </div>
                </Fragment>
            )}
            {status === REQUESTED_AGAIN.id && (
                <p className="a-mediumText a-lightText -mt20">
                    {getStatusText()}
                </p>
            )}
            {status === GRANTED_WITHOUT_FILES.id &&
                grantedWithoutFilesMessage && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t(grantedWithoutFilesMessage)}
                    </p>
                )}
            {isLoading && <Loader />}
        </div>
    )
}

RequestAccess.propTypes = {
    dataAccessItem: PropTypes.object,
    canRequestAccess: PropTypes.bool,
    status: PropTypes.number,
    profile: PropTypes.object,
    checkAccessControl: PropTypes.func,
    requestTime: PropTypes.string,
    grantedWithoutFilesMessage: PropTypes.string,
    buttonSize: PropTypes.string,
    onlyButton: PropTypes.bool,
    onlyNoteStatus: PropTypes.bool,
}

RequestAccess.defaultProps = {
    buttonSize: BUTTON_SIZE.LARGE,
    onlyButton: false,
    onlyNoteStatus: false,
}

export default RequestAccess

import React from 'react'
import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.sendDiveRecordForVerificationLevelOne',
    videoLink: 'https://embed.app.guidde.com/playbooks/uRdKxD3EgggKwfdoQfQoFw',
    description: `howTo.sendDiveRecordForVerificationLevelOneDescription`,
    goTo: false,
    steps: [
        {
            title: `Introduction`,
            description: `Start by navigating to the Skill N Depth platform.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fm7rSVTru7zn1TSTY7Ym3Jb%2F1XbUBzGU54LxjUQh9k2GDj_doc.png?alt=media&token=7e11fe51-30f5-4400-bf66-a0b8e5c8cf2e',
        },
        {
            title: `Click "My records"`,
            description: `Once on the platform, locate and click on the "My
                        records" section. This is where all your dive records
                        are stored. You'll need to find the specific record you
                        want to verify.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2Fmk3AnxQ89Qm364ZPNQ5z4v_doc.png?alt=media&token=f9cf808b-1c9b-4972-af85-bffd844d8108',
        },
        {
            title: `Click "Filters"`,
            description: `To make it easier to find records that need
                        verification, use the filter feature. Click on the
                        "Filters" button to begin narrowing down your search.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FasgUopDBqng9HRNYCnvn1a_doc.png?alt=media&token=61165eac-47c7-4f84-b643-3a77d4ff6ebd',
        },
        {
            title: `Click the "Status" field`,
            description: `In the filters, you'll see a field labeled "Please
                        select status." Click on this field to choose the status
                        of the dive records you want to filter.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FdFJZmw9NrbWtFK26jKvQHB_doc.png?alt=media&token=43c66a66-bac6-4de6-a7b5-a12ce9768e89',
        },
        {
            title: `Select "Completed"`,
            description: `From the dropdown options, select "Completed." This will
                        filter your dive records to show only those that are
                        fully populated but have not yet been verified.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2F8peCUrNghTbYhrbtkbHm59_doc.png?alt=media&token=f5eeb729-f622-496a-9058-07cd1d17e0da',
        },
        {
            title: `Click "Filter"`,
            description: `After selecting the appropriate status, apply the
                        filters by clicking on the "Filter" button. This will
                        display a list of all records that are ready for
                        verification.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FsxmA59h6pKRzMWMsMpBF46_doc.png?alt=media&token=48e53c9a-002d-41a9-833f-db263a4e4540',
        },
        {
            title: `Click the share button`,
            description: `Now that you have the list of records, it's time to
                        create a sharable link. Click on the share button next
                        to the dive record you wish to verify. Remember, this
                        guide focuses on the first level of verification.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2F6p8BBmrWGs823hF69rmDai_doc.png?alt=media&token=c5f3f589-c94f-493e-b6fd-297f24e241f8',
        },
        {
            title: `Read the description`,
            description: `A share modal will appear with important information
                        about the visibility of your dive record. Once you share
                        the link, the record will be publicly accessible via
                        that link. It's important to share this link only with
                        your supervisor, who will complete the verification.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FvGbTr2fJjBVXymCXaubyq6_doc.png?alt=media&token=dfc3ac07-7a8a-4c72-8b9e-f83d82f7bffd',
        },
        {
            title: `Click "Copy link"`,
            description: `Finally, copy the link provided in the modal and send it
                        to your supervisor using your preferred messaging app
                        (e.g., Email, SMS, WhatsApp). Once your supervisor has
                        verified the record, you’ll receive a notification, and
                        your dive record will be marked with a verification
                        badge.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FuRdKxD3EgggKwfdoQfQoFw%2FxawaaSWavsXmVqcAp2BE3C_doc.png?alt=media&token=1d6b002e-30a4-4ca4-87b1-16da336529b0',
        },
    ],
    endNote: ` And that’s it! You've successfully sent your dive record
                        for Level 1 verification. Once your supervisor completes
                        the process, your record will be officially verified. If
                        you need to verify additional records or move to a
                        higher verification level, feel free to explore our
                        other guides. Happy diving!`,
}

const SendDiveRecordForVerificationLevelOne = () => {
    return <HowToPage howToObject={howToObject} />
}

export default SendDiveRecordForVerificationLevelOne

import React from 'react'

import { BASE_URL } from 'utils/axiosClient'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const AvatarIcon = ({ avatarPath, icon }) => {
    return (
        <div className="a-avatarIcon__wrapper">
            {avatarPath ? (
                <img src={`${BASE_URL}${avatarPath}`} alt="avatarPath" />
            ) : (
                <Icon
                    name={icon}
                    size={ICON_SIZE.SIZE20}
                    color={COLORS.DARK_BLUE_60}
                />
            )}
        </div>
    )
}

export default AvatarIcon

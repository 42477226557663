import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

const Note = ({ note, children, translate, customLabel, bottomMargin }) => {
    const t = useTranslate()

    return (
        <div className={`a-note fullWidth ${bottomMargin ? '-mb10' : ''}`}>
            <p className="a-note__text a-captionsTextRegular">
                <span className="a-captionsTextSemiBold">
                    {customLabel || t('notes.note')}{' '}
                </span>
                {children || (translate ? t(note) : note)}
            </p>
        </div>
    )
}

Note.propTypes = {
    note: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    translate: PropTypes.bool,
    customLabel: PropTypes.string,
    bottomMargin: PropTypes.bool,
}

Note.defaultProps = {
    note: 'notes.featureUnderDevelopment',
    translate: true,
    customLabel: null,
    bottomMargin: true,
}

export default Note

import React from 'react'
import { useParams } from 'react-router-dom'

import { editEntityService } from 'services/entity.service'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'

import ManagementLayout from '../components/ManagementLayout'
import CompanyAccountBreadcrumbs from './components/CompanyAccountBreadcrumbs'
import CompanyAccountForm from './components/CompanyAccountForm'
import Loader from 'components/Loader'

const CompanyAccountEdit = () => {
    const { companyId } = useParams()

    const { data: companyData, isLoading } = useFetchDataById(
        ENTITIES.COMPANY,
        companyId,
        {
            include: [
                'industries,services,user,vessels,clients,countryPhoneCode,hqCountry,size,regions,logo,heroImage,organizationTypes,locationOfServices',
            ],
        }
    )

    const handleSubmit = async (formData) => {
        await editEntityService(ENTITIES.COMPANY, companyId, formData, true, [
            'locationOfServices',
        ])
    }

    if (isLoading) return <Loader />
    if (!companyData) return null

    return (
        <ManagementLayout
            BreadcrumbsComponent={CompanyAccountBreadcrumbs}
            edit={companyData}
        >
            <CompanyAccountForm
                initialData={companyData}
                handleSubmit={handleSubmit}
            />
        </ManagementLayout>
    )
}

export default CompanyAccountEdit

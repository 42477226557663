import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { APP_LINK } from 'constants/constants'

const HowToPage = ({ howToObject }) => {
    const t = useTranslate()

    return (
        <div className="m-howTo">
            <div>
                {howToObject.title && (
                    <div className="-mb20">
                        <span className="a-bodyTextMedium">
                            {t(howToObject.title)}
                        </span>
                    </div>
                )}
                {howToObject.videoLink && (
                    <div className="m-howTo__iframe">
                        <iframe
                            src={howToObject.videoLink}
                            title={howToObject.title}
                            frameBorder={0}
                            referrerPolicy="unsafe-url"
                            allowFullScreen={true}
                            allow="clipboard-write"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                        />
                    </div>
                )}
                {howToObject.description && (
                    <div className="-mt15">
                        <p className="a-mediumText a-lightText">
                            {t(howToObject.description)}
                        </p>
                    </div>
                )}
                {howToObject.goTo && (
                    <div className="-mt20">
                        <span className="a-mediumText">
                            Go to{' '}
                            <a href={APP_LINK.LINK} target="_blank">
                                {APP_LINK.TEXT}
                            </a>
                        </span>
                    </div>
                )}
                {howToObject.steps && (
                    <div className="column">
                        <ul>
                            {howToObject.steps?.map((step, index) => (
                                <Fragment key={index}>
                                    <div className="-mt30">
                                        <li className="a-mediumTextSemiBold -liNumber -noSpaceLeft">
                                            {step.title}
                                        </li>
                                    </div>
                                    <div className="-mt5 -mb10">
                                        <p className="a-mediumText a-lightText">
                                            {step.description}
                                        </p>
                                    </div>
                                    <img src={step.img} alt={step.title} />
                                </Fragment>
                            ))}
                        </ul>
                    </div>
                )}
                {howToObject.endNote && (
                    <div className="-mt20">
                        <p className="a-mediumText a-lightText">
                            {howToObject.endNote}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

HowToPage.propTypes = {
    howToObject: PropTypes.object,
}

export default HowToPage

import React from 'react'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import Breadcrumbs from 'components/Breadcrumbs'
import { getEntityFilters } from 'services/localStorage.service'
import FILTERS from 'constants/filters'

const CallCenterBreadcrumbs = ({ edit }) => {
    const t = useTranslate()
    return (
        <Breadcrumbs
            breadcrumbs={[
                {
                    label: 'menuItems.callCenter',
                    to: `/${ROUTES.CALL_CENTER}`,
                    icon: ICONS.HOMEPAGE_BREADCRUMB,
                    queryParams: getEntityFilters(
                        FILTERS.SUPER_ADMIN_CALLS_FILTERS
                    )
                }, edit && {
                    label: `${t('general.call')} ${edit.id}`,
                    to: `/${ROUTES.CALL_CENTER}/${edit.id}`,
                    translate: false,
                },
            ].filter((item) => item)}
        />
    )
}

export default CallCenterBreadcrumbs

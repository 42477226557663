import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import ICONS from 'constants/icons'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import CardHeader from 'components/card/CardHeader'
import Info from 'components/Info'
import EditSkillRecord from './EditSkillRecord'

const SkillRecordList = ({
    data,
    fetchData,
    isLoading,
    showActions,
    refetchData,
}) => {
    const t = useTranslate()
    const [edit, setEdit] = useState(null)

    const { setAlert } = useContext(AlertContext)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleDelete = async (id) => {
        try {
            await deleteEntityService(ENTITIES.SKILL_RECORD, id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={1} />
    }

    return (
        <div className="column -gap10 -mt20">
            {data &&
                !!data.length &&
                data.map((skill, index) => (
                    <div key={index} className="fullWidth">
                        <CardContainer>
                            <CardHeader
                                title={skill.skillCategoryName}
                                actions={
                                    showActions
                                        ? [
                                              {
                                                  handleAction: () =>
                                                      handleEdit(skill),
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  label: 'button.edit',
                                              },
                                              {
                                                  handleAction: () =>
                                                      handleDelete(skill.id),
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  label: 'button.delete',
                                                  title: 'general.deleteSkill',
                                                  confirmationMessage:
                                                      'message.areYouSureDeleteSkill',
                                                  textColor: '-redText',
                                              },
                                          ]
                                        : []
                                }
                            />
                            <Info
                                value={skill.skills.map((item) => item.name)}
                                color="orange"
                            />
                        </CardContainer>
                    </div>
                ))}

            {!!edit && (
                <EditSkillRecord
                    data={data}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    showActions={showActions}
                    edit={edit}
                />
            )}
        </div>
    )
}

SkillRecordList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
}

SkillRecordList.defaultProps = {
    showActions: false,
}

export default SkillRecordList

const SUCCESS_STATUS = 130
const INCOMPLETE_STATUS = 110
const COMPLETED_STATUS = 100

export const getLabelByStatus = (statusCode) => {
    switch (statusCode) {
        case SUCCESS_STATUS:
            return 'callSuccessful'
        case INCOMPLETE_STATUS:
            return 'callUnsuccessful'
        case COMPLETED_STATUS:
            return 'completed'
        default:
            return 'callUnsuccessful'
    }


}

export const getColorByStatus = (statusCode) => {
    switch (statusCode) {
        case SUCCESS_STATUS:
        case COMPLETED_STATUS:
            return 'green'
        case INCOMPLETE_STATUS:
            return 'red'
        default:
            return 'gray'
    }
}
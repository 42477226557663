import React from 'react'
import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.updatePersonalInformation',
    videoLink: 'https://embed.app.guidde.com/playbooks/kFKAw87mQuNMQ5XPXKn4uy',
    description: 'howTo.updatePersonalInformationDescription',
    goTo: true,
    steps: [
        {
            title: 'Click on your name',
            description:
                'Let’s walk through the process together—it’s simple and will only take a few minutes! While using Skill N Depth app you will have your profile page simply accessible. Start by clicking on your name in the top right corner.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fnw5tmzULt8YgdAxACMiCXw_doc.png?alt=media&token=62f7fe14-aa17-46f1-911e-b61343bbb49f',
        },
        {
            title: 'Click "Profile"',
            description:
                "In the dropdown menu, click on 'Profile' to view and edit your information.",
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FesiBsYNUzhuUT8orwHPYVJ_doc.png?alt=media&token=f5898688-34dd-4394-9a3c-c3281d3b7bbe',
        },
        {
            title: 'Click "Edit" button',
            description:
                'Look for the little edit icon. Click it, and voila! You’re ready to start making changes.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F4RQu7PZ5TiPT7fePgdbZGe_doc.png?alt=media&token=99916a2d-440a-4485-a14e-aa0c35391512',
        },
        {
            title: `Click "Available for hire"`,
            description: 'Start by selecting your availability.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fww5uSN2Rj4wxqsmiQpYw5s_doc.png?alt=media&token=1ac433c3-06f6-4e67-8551-6252241c9dfd',
        },
        {
            title: 'Select your availability',
            description:
                'Are you open to new opportunities, or maybe taking a break? Click on this option to select your current status, like “Currently unavailable” if you’re taking some time off.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FbQeUSjLWVoyZ1LYswXUQUW_doc.png?alt=media&token=646233de-02a8-4362-903b-8a1fd7ec8b89',
        },
        {
            title: 'Select profile visibility',
            description:
                'Next, choose if your profile is hidden or not. If you prefer to keep a low profile and don’t want to appear in searches, simply check the Hidden box. It’s like going incognito but for your profile! Click on the info icon to receive more detail about what will change if you choose to hide your profile.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FwGb4qeFZUihCPwWxfhEwe8_doc.png?alt=media&token=ba320fc7-d825-4ba4-b582-8abe2ad75d54',
        },
        {
            title: 'Adjust first name',
            description:
                'Click on the first name field and adjust in your first name if needed.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FcaJsAQG6tcUBy3rxRC3rsb_doc.png?alt=media&token=36783996-0070-443c-99db-d625adacb220',
        },
        {
            title: 'Enter your middle name',
            description:
                'If you have a middle name or initial, you can update it here.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fmpddmz2tngxM16g3a1gbZY_doc.png?alt=media&token=75f3dbb7-26d6-491f-9fec-2417cfb8421b',
        },
        {
            title: 'Enter last name',
            description:
                'Make sure your last name is correct, and update it if needed.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FgWG4Fbhz1FkvB3e1cveQ2k_doc.png?alt=media&token=ba73baaa-1fd2-43f1-84b7-23f5de733fe2',
        },
        {
            title: 'Enter current title',
            description:
                'Are you sporting a new title? Update it in the current title field so everyone knows what you’re up to.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F5FM7fTLbFW3TUtUcTXSZWQ_doc.png?alt=media&token=e0af7517-a3e6-4242-bcb9-128a0e89940d',
        },
        {
            title: 'Enter date of birth',
            description:
                'Click on the field and select your birth month and day. It’s a small detail, but it helps complete your profile.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FqqeNvEczbv6Fo37YxmuTHF_doc.png?alt=media&token=9a070d7c-e3a3-4dcc-90b6-4969f166d998',
        },
        {
            title: 'Select your roles',
            description: 'Next, select all roles that showcase your expertise.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F2KaM24u5s75Lb4kSyMugsL_doc.png?alt=media&token=da4384d8-87dd-4c5e-b4cc-a197b0d8c724',
        },
        {
            title: 'Choose your country of residence',
            description:
                'Let everyone know where you’re based by selecting your country from the dropdown menu.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FefN1AaZM8E5rK54xZHWg13_doc.png?alt=media&token=9156354a-42a1-4282-b2ff-00c491a9e8d5',
        },
        {
            title: 'Click on the nationality field',
            description:
                'Update your nationality by clicking on the “Nationality” field and typing it in.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fv4bx1MSgubNiC31Dbtzkbs_doc.png?alt=media&token=d910e6be-9628-48da-93f0-d0db89d6b7c9',
        },
        {
            title: 'Add languages',
            description:
                'Do you speak more than one language? Add them here by choosing from the list—being multilingual is a great asset!',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FrVVqfu3RphwzEL9SWBanrf_doc.png?alt=media&token=4fefc4ef-2c05-4d75-816f-3f3505e61b5d',
        },
        {
            title: 'Enter your email address',
            description:
                'Make sure your email address is current so we can reach you.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fc9hDJonz25WTXGFRm48nqM_doc.png?alt=media&token=1ecff832-ed32-4727-a4c9-d10ebd406a82',
        },
        {
            title: 'Adjust your phone number',
            description: 'Next, update your phone number.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FnuaMM1Xn8igZyj6k4MpU3N_doc.png?alt=media&token=aecb086e-04e4-43e5-ac4e-0f31b5cdc7bd',
        },
        {
            title: 'Adjust About me section',
            description:
                'This is your chance to share a little about yourself. Click on this section and type in a brief introduction or summary that reflects who you are.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F2evQEACeh46UBxERQpzuMa_doc.png?alt=media&token=c3101086-ec77-4e47-bb4e-d0caf3170bb2',
        },
        {
            title: 'Adjust your professional summary section',
            description:
                'Review and polish your professional summary to make sure it accurately reflects your experience and skills.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fj6FvwSW94dfJyQvNvJVWSg_doc.png?alt=media&token=76ed520a-5964-4fdb-b542-0caaba0fe25e',
        },
        {
            title: 'Preferred regions of work',
            description:
                'Where would you love to work? Click on this field and pick the regions that appeal to you most from the list.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fu5ZJBSiQsttEtpZArcJ78i_doc.png?alt=media&token=ed414514-3d9a-4ec1-b668-32f2344ba5d2',
        },
        {
            title: `Click "Save personal information"`,
            description:
                'Once you’ve made all your changes, don’t forget to click the “Save personal information” button. This will lock in all your updates and ensure your profile is ready to shine!',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FkAw3uS22ibNgFkujiriAdH_doc.png?alt=media&token=51f633e7-151e-4b84-b7b1-e9ed09717202',
        },
    ],
    endNote:
        'And that’s it! You’re all set with an updated profile on Skill N Depth. Whether you’re actively looking for new opportunities or just keeping your profile current, these small steps can make a big difference in how you’re perceived by others in the industry. Happy updating!',
}

const UpdatePersonalInformation = () => {
    return <HowToPage howToObject={howToObject} />
}

export default UpdatePersonalInformation

import React from 'react'
import { useTranslate } from 'react-polyglot'

import { getEntityFilters } from 'services/localStorage.service'

import FILTERS from 'constants/filters'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import Breadcrumbs from 'components/Breadcrumbs'

const IndividualAccountBreadcrumbs = ({ edit }) => {
    const t = useTranslate()

    return (
        <Breadcrumbs
            breadcrumbs={[
                {
                    label: 'menuItems.userManagement',
                    to: ROUTES.HOME,
                    icon: ICONS.HOMEPAGE_BREADCRUMB,
                },
                {
                    label: 'menuItems.individualAccountManagement',
                    to: `/${ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT}`,
                    queryParams: getEntityFilters(
                        FILTERS.SUPER_ADMIN_INDIVIDUAL_FILTERS
                    ),
                },
                edit && {
                    label: `${t('general.edit')} ${edit.fullName} ${t(
                        'general.account'
                    )}`,
                    to: `/${ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT}${ROUTES.EDIT}/${edit.id}`,
                    translate: false,
                },
            ].filter((item) => item)}
        />
    )
}

export default IndividualAccountBreadcrumbs

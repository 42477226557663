import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { ALERT_TYPES, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import { createEntityService } from '../../../../../services/entity.service'
import ENTITIES from '../../../../../constants/entities'
import { AlertContext } from '../../../../../contexts/AlertContext'
import { ProfileContext } from '../../../profile/context/ProfileContext'

const MedicalInfoTermsAndConditions = ({}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { setProfile } = useContext(ProfileContext)

    const [termsOfService, setTermsOfService] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)

    const handleConfirm = async () => {
        try {
            await createEntityService(
                ENTITIES.MEDICAL_INFO_TERMS_AND_CONDITIONS,
                {}
            )
            setProfile((state) => ({
                ...state,
                agreedMedicalInfoTermsAndConditions: true,
            }))
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Fragment>
            <div className="m-profileTabs">
                <div className="aligned-center ">
                    <span className="a-bodyTextMedium ">
                        {t('general.medicalInformation')}
                    </span>
                </div>
            </div>
            <div className="-mt20">
                <div className="a-note -mb20">
                    <div className="a-captionsTextSemiBold -mb10">
                        {t('medicalInfoTerms.importantNotice')}
                    </div>
                    <div className="a-captionsTextRegular -mb10">
                        {t('medicalInfoTerms.importantNoticeProceed')}
                    </div>
                    <div className="a-captionsTextRegular -mb10 -ml5">
                        1. {t('medicalInfoTerms.importantNoticeAgree')}
                    </div>
                    <div className="a-captionsTextRegular -mb10 -ml5">
                        2. {t('medicalInfoTerms.importantNoticeConfirm')}
                    </div>
                    <div className="a-captionsTextRegular">
                        {t('medicalInfoTerms.importantNoticeCheck')}
                    </div>
                </div>
                <div>
                    <div class="-mb10">
                        <span className="a-bodyTextMedium">
                            {t('medicalInfoTerms.consentTitle')}
                        </span>
                    </div>
                    <div className="-mb10 aligned-center">
                        <Checkbox
                            name="terms"
                            label=""
                            value={termsOfService}
                            onChange={(e) => {
                                setTermsOfService(e.target.checked)
                            }}
                            translate
                        />
                        <span className="a-mediumText">
                            {t('medicalInfoTerms.agreeTermsLabel')}
                            <a
                                href="https://skillndepth.com/terms-of-service"
                                target="_blank"
                                className="a-mediumText -ml5"
                            >
                                {t('medicalInfoTerms.agreeTermsLink')}
                            </a>
                        </span>
                    </div>
                    <div className="-mb10 aligned-center">
                        <Checkbox
                            name="privacy"
                            label=""
                            value={privacyPolicy}
                            onChange={(e) => {
                                setPrivacyPolicy(e.target.checked)
                            }}
                            translate
                        />
                        <span className="a-mediumText">
                            {t('medicalInfoTerms.agreePrivacyLabel')}
                            <a
                                href="https://skillndepth.com/privacy-policy"
                                target="_blank"
                                className="a-mediumText -ml5"
                            >
                                {t('medicalInfoTerms.agreePrivacyLink')}
                            </a>
                        </span>
                    </div>
                    <div className="a-mediumText -mb20">
                        {t('medicalInfoTerms.consentDescription')}
                    </div>
                    <div className="aligned-right">
                        <Button
                            btnClass={BUTTON_STATUS.PRIMARY}
                            type={BUTTON_TYPE.BUTTON}
                            label="button.confirm"
                            tooltip={'medicalInfoTerms.buttonTooltip'}
                            onClick={handleConfirm}
                            disabled={!termsOfService || !privacyPolicy}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MedicalInfoTermsAndConditions

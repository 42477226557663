import { useTranslate } from 'react-polyglot'

import { translateRoleOptions } from 'utils/translateOptionsHelper'

const UserRoleCell = ({ value }) => {
    const t = useTranslate()
    return <div>{translateRoleOptions(value, t)}</div>
}

export default UserRoleCell

import React from 'react'

import { MOMENT_FORMATS } from 'services/moment.service'
import moment from 'moment'

const DateCell = ({ value }) => {
    if (!value) return '/'

    const date = value ? moment(value).format(MOMENT_FORMATS.DATETIME) : ''

    return <span className="a-mediumText">{date}</span>
}

export default DateCell

import React, { useState, useRef, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { Sidebar } from 'components/sidebar'
import Header from './Header'
import { ADMIN_MENU_ITEMS } from 'hooks/useMenuItems'
import useWindowDimensions from 'hooks/useWindowDimension'

const AdminLayout = ({ children }) => {
    const { isSmallScreen } = useWindowDimensions()
    const [openSidebar, setOpenSidebar] = useState(!isSmallScreen)

    const hocRef = useRef(null)
    const hamBtnRef = useRef(null)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (!isSmallScreen) {
            setOpenSidebar(true)
        }
    }, [isSmallScreen])

    const handleClickOutside = (event) => {
        if (
            document.body.clientWidth < 768 &&
            hocRef &&
            hocRef &&
            hocRef.current &&
            !hocRef.current.contains(event.target) &&
            !hamBtnRef.current.contains(event.target)
        ) {
            setOpenSidebar(false)
        }
    }

    return (
        <div>
            <Header
                setOpenSidebar={setOpenSidebar}
                hamBtnRef={hamBtnRef}
                openSidebar={openSidebar}
                fixedSidebar={!isSmallScreen}
            />
            <div className="m-mainWrapper">
                <Sidebar
                    open={openSidebar}
                    setOpen={setOpenSidebar}
                    hocRef={hocRef}
                    menu={ADMIN_MENU_ITEMS}
                    isSmallScreen={isSmallScreen}
                    fixedSidebar={!isSmallScreen}
                />
                <div
                    className={`m-main -superAdmin ${
                        !isSmallScreen ? '-fixedSidebar' : ''
                    } ${openSidebar ? '-sidebarIsOpen' : ''}`}
                >
                    <div className="m-main__wrapper">
                        <div className="_wr ">
                            <div className="_w">
                                <div className="m-main__content">
                                    {children || <Outlet />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLayout

import React from 'react'
import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.createPersonalAccount',
    videoLink: 'https://embed.app.guidde.com/playbooks/bkRWfmSh42yL78uYbs9Nnp',
    description: 'howTo.createPersonalAccountDescription',
    goTo: true,
    steps: [
        {
            title: `Click "Sign Up"`,
            description: `Once you’re on the app, look for the "Sign Up" button. Give it a click to begin creating your account.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FngYeJUQLTZ4hf6gL25sykV_doc.png?alt=media&token=32e20a59-9b5d-4b5e-b6dc-c48e3a45d2a9',
        },
        {
            title: 'Click and enter email address',
            description:
                'In the sign-up form, click on the email field and type in your email address. Make sure it’s one you use regularly, as this is where we’ll send important information.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2Ffd8QwPP6BUzNE52yzV4qZx_doc.png?alt=media&token=4bf9fa2b-537a-4a4d-9d25-2ff77fb8f328',
        },
        {
            title: 'Click and enter password',
            description:
                'Next, click on the password field. Choose a strong password that you’ll remember—think of something unique and safe.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2Fb4xJTagJs6qxEk1sNLC6vQ_doc.png?alt=media&token=a8142b90-0447-40aa-85d8-101d058af051',
        },
        {
            title: 'Confirm your password',
            description: `To avoid any typos, we’ll ask you to enter your password again in the "Confirm password" field. Just type it in one more time.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FpjiVM3wBQyo6b7JQVK9R6R_doc.png?alt=media&token=3cb0da1e-e7aa-49a4-a2cb-2d36051fc451',
        },
        {
            title: `Click "Agree and Join"`,
            description: `Once your details are in, review the terms of service (if you’d like), then click on the "Agree and Join" button. Welcome aboard!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FaRbCc31PRTp8LSxeLtc4Wo_doc.png?alt=media&token=92bb72a9-f798-4600-b084-73abe45dc9ba',
        },
        {
            title: 'Enter verification code',
            description:
                'Now, check your inbox. We’ve sent you a verification email—just click on the link inside to verify your account. This helps keep your account secure.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FqQ3rsDfhzJDUshBF6ksQZz_doc.png?alt=media&token=afbc9496-3a11-4b88-80ef-cfa966455fda',
        },
        {
            title: 'Select your account type',
            description:
                'The next item is selecting whether you want to create an individual or an Organization account. If you are the one who is diving select individual, if you are looking to hire professionals to work for you select Organization account type.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F8i3gFy394k9eaw8QFDgcGV_doc.png?alt=media&token=68fcf37b-07c3-4f58-bdf2-43d51f2766be',
        },
        {
            title: `Click "Next"`,
            description:
                'The next item is selecting whether you want to create an individual or an Organization account. If you are the one who is diving select individual, if you are looking to hire professionals to work for you select Organization account type.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FmhEGz8nDQrGX6RCRdXXbqn_doc.png?alt=media&token=bf2a4261-58e9-472a-baff-a10686799b8d',
        },
        {
            title: 'Enter your name',
            description: 'Enter your first name.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FvHg2h7gT3rmwAGfeC9K61G_doc.png?alt=media&token=0b129e7d-0e14-492b-bc70-f6f27bd60a9c',
        },
        {
            title: 'Enter your last name',
            description: 'Enter your last name.',
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FiAdDuD3RWUekEwzzxXsKqc_doc.png?alt=media&token=25ae6afe-1f08-4f19-a640-acdc1ab8444f',
        },
        {
            title: `Click "Next"`,
            description: `Click "Next" to proceed to the next part of the setup.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FnVsbjC7DJXKYAPy1hDJX2f_doc.png?alt=media&token=fc2a435f-3f77-45d8-8351-7c58e7418b0f',
        },
        {
            title: 'Select your country and click next',
            description: `When prompted, click on the country field and select where you’re from (e.g., "Canada"). We’re a global community, and it’s great to know where our members are from!`,

            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F98mbgMkAqRtc4LuhjypYM4_doc.png?alt=media&token=94d2f82f-50b3-4ad1-ab78-f6a644251d06',
        },
        {
            title: 'Select all your roles',
            description: `Enter your job title in the provided field (e.g., "Diver"). This helps us tailor the platform to your needs.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2FvkRWymzGKBFuPpXJSDeJq8_doc.png?alt=media&token=7d96257b-1f3d-4597-a04b-1bb245b6b15a',
        },
        {
            title: `Click "Next"`,
            description: `Click on "Next"`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F8T4Tk2T6YBgDnVzVjY8JHG_doc.png?alt=media&token=829c2679-d971-4015-b568-931decaf20ed',
        },
        {
            title: 'Upload your photo',
            description: `Let’s put a face to the name! Click on "Upload new photo" and choose your best picture to upload as your profile photo.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2Fpmp85yLnNUyyQJnje9BKVk_doc.png?alt=media&token=504c140b-9fd2-48bc-b25a-6570c25239e6',
        },
        {
            title: `Click "Finish"`,
            description: `Once everything’s set, click the "Finish" button. And that’s it—you’re all done!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FbkRWfmSh42yL78uYbs9Nnp%2F6qJVC9PRoWi2RLVMFhW9Go_doc.png?alt=media&token=715f711d-2bd5-4e7b-a9c4-fff69492f35e',
        },
    ],
    endNote: 'Thank you for beeing a member of Skill N Depth!',
}

const CreatePersonalAccount = () => {
    return <HowToPage howToObject={howToObject} />
}

export default CreatePersonalAccount

import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'
import { formatDate, MOMENT_FORMATS } from 'services/moment.service'

import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import { refetchProfile } from '../../utils/fetchHelper'
import { formatByName } from 'utils/cardHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    PRIMARY_TRAINING_TYPE,
    PRIMARY_TRAINING_TYPE_CATEGORY,
} from 'constants/enums'
import ICONS from 'constants/icons'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'
import Accordion from 'components/Accordion'
import InfoFiles from 'components/InfoFiles'
import InfoRow from 'components/InfoRow'
import CertificateCard from './CertificateCard'
import EditTrainingCourse from './EditTrainingCourse'
import NoData from 'components/NoData'
import CardHeader from 'components/card/CardHeader'

const { MILITARY } = PRIMARY_TRAINING_TYPE_CATEGORY
const { OTHER_TRAINING } = PRIMARY_TRAINING_TYPE

const TrainingCourseList = ({
    data,
    isLoading,
    fetchData,
    profilePage,
    showActions,
    refetchData,
    filteredData,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [edit, setEdit] = useState(null)

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleDelete = async (item) => {
        try {
            await deleteEntityService(ENTITIES.TRAINING_COURSE, item.id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()

            const condition = [
                PRIMARY_TRAINING_TYPE.DIVE_SCHOOL_TRAINING,
                PRIMARY_TRAINING_TYPE.OTHER_TRAINING,
            ].includes(item?.primaryTrainingType?.id)

            refetchData &&
                refetchProfile(
                    condition,
                    filteredData,
                    refetchData,
                    false,
                    true
                )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const getDeleteConfirmationMessage = (item) => {
        const deletedItem = data.find(
            (deletedItem) => deletedItem?.id === item.id
        )
        const courseWithCertificates =
            deletedItem?.trainingCertificates?.length > 0

        return courseWithCertificates
            ? 'message.areYouSureDeleteTrainingCourseWithCertificate'
            : 'message.areYouSureDeleteTrainingCourse'
    }

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    const getCardTitle = (item) => {
        if (!item.courseName) {
            return item.primaryTrainingType?.name
        }

        return item.courseName
    }

    return (
        <Fragment>
            {data.map((item) => (
                <div key={item?.id} className="-mt20">
                    <CardContainer>
                        <CardHeader
                            title={getCardTitle(item)}
                            item={item}
                            actions={
                                showActions
                                    ? [
                                          {
                                              handleAction: handleEdit,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.DARK_BLUE,
                                              label:
                                                  'button.editTrainingCourse',
                                          },
                                          {
                                              handleAction: handleDelete,
                                              icon: ICONS.DELETE,
                                              iconColor: COLORS.RED,
                                              label:
                                                  'button.deleteTrainingCourse',
                                              title:
                                                  'general.deleteTrainingCourse',
                                              customConfirmationMessage: getDeleteConfirmationMessage,
                                              textColor: '-redText',
                                          },
                                      ]
                                    : []
                            }
                            fullTitle
                        />
                        {!!item.courseName && (
                            <Info
                                label="form.label.trainingTypeCard"
                                value={item.primaryTrainingType?.name}
                            />
                        )}
                        <Info
                            label="form.label.category"
                            value={item.primaryTrainingTypeCategory?.name}
                        />
                        {item.primaryTrainingTypeCategory?.id === MILITARY ? (
                            <Fragment>
                                <Info
                                    label="form.label.branch"
                                    value={item.branch?.name}
                                />
                                <Info
                                    label="form.label.country"
                                    value={item.country?.name}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Info
                                    label={
                                        item.primaryTrainingType.id ===
                                        OTHER_TRAINING
                                            ? 'form.label.trainingSchoolName'
                                            : 'form.label.diveTrainingSchoolName'
                                    }
                                    value={
                                        item.diveSchool?.name ||
                                        item.diveSchoolName
                                    }
                                />
                                <Info
                                    label="form.label.schoolAddress"
                                    value={item.schoolAddress}
                                />
                            </Fragment>
                        )}
                        <InfoRow>
                            {item.dateOfEnrolled && (
                                <Info
                                    label="form.label.dateOfEnrolled"
                                    value={formatDate(
                                        item.dateOfEnrolled,
                                        MOMENT_FORMATS.DATE_MONTHYEAR
                                    )}
                                />
                            )}
                            <Info
                                label="form.label.dateOfCompleted"
                                value={formatDate(
                                    item.dateOfCompleted,
                                    MOMENT_FORMATS.DATE_MONTHYEAR
                                )}
                            />
                        </InfoRow>
                        <Info
                            label="form.label.trainingTypes"
                            value={formatByName(item.trainingTypes)}
                            color="gray"
                        />
                        {item.proficiencyLevel && (
                            <Info
                                label="form.label.trainingLevel"
                                value={item.proficiencyLevel.name}
                                color="purple"
                            />
                        )}

                        <InfoFiles
                            label="general.trainingCourseFiles"
                            files={item.files}
                        />

                        {item?.trainingCertificates?.length > 0 && (
                            <Accordion
                                title="general.certificates"
                                isLoading={isLoading}
                                withBorders
                            >
                                {item?.trainingCertificates?.map(
                                    (item, index) => (
                                        <div key={item.id}>
                                            <span className="a-bodyTextMedium -mb5">
                                                {item.name}
                                            </span>
                                            <CertificateCard
                                                item={item}
                                                showName
                                            />
                                        </div>
                                    )
                                )}
                            </Accordion>
                        )}
                    </CardContainer>
                </div>
            ))}

            {!!edit && (
                <EditTrainingCourse
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    filteredData={filteredData}
                    showActions={showActions}
                />
            )}

            {showEmptyValueMessage && data?.length === 0 && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t('general.nothingHereRightNow')}
                />
            )}
        </Fragment>
    )
}

TrainingCourseList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
}

TrainingCourseList.defaultProps = {
    showActions: false,
}

export default TrainingCourseList

import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, COMPANY_USER_STATUS } from 'constants/enums'

import Loader from 'components/Loader'
import { MultiselectField } from 'components/formFields'
import FocusError from 'components/FocusError'
import Modal from 'components/Modal'

const InviteColleagueForm = ({
    setModalOpened,
    refetchData,
    projectId,
    projectClientReps,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        clientReps: projectClientReps.map(projectRep => {
            return {
                ...projectRep.user.companyUser,
                user: projectRep.user
            }
        }),
    }

    const validation = Yup.object({
        clientReps: Yup.array(),
    })

    const onSubmit = async ({ clientReps }, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true)
            const { detail } = await createEntityService(
                ENTITIES.PROJECT_CLIENT_REPRESENTATIVES + '/bulk',
                {
                    clientRepresentatives: clientReps.map((clientRep) => {
                        return {
                            id: clientRep.user.id,
                        }
                    }),
                    project: {
                        id: projectId
                    }
                },
                false
            )
            setAlert(detail, ALERT_TYPES.SUCCESS)
            resetForm({})
            handleClose()
            if (refetchData) refetchData()
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => {
        setModalOpened(false)
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={setModalOpened}
                        closeOnClickOutside={false}
                        title={t('button.addClientRepresentatives')}
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: handleClose,
                            },
                            nextBtn: {
                                label: 'button.assign',
                            },
                        }}
                        smallModalWithDropdowns
                        noTranslateTitle
                    >
                        <div className="_12">
                            <MultiselectField
                                name="clientReps"
                                entityType={ENTITIES.COMPANY_USERS}
                                displayAttribute="fullName"
                                required
                                searchable
                                params={{
                                    status: COMPANY_USER_STATUS.ACTIVE.value
                                }}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

InviteColleagueForm.propTypes = {
    setModalOpened: PropTypes.func,
    refetchData: PropTypes.func,
    projectId: PropTypes.number,
    projectClientReps: PropTypes.array
}

export default InviteColleagueForm

import React from 'react'
import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.signUpAsOrganization',
    videoLink: 'https://embed.app.guidde.com/playbooks/kBvmuHYA5dtM5Spdv5qg98',
    description: `howTo.signUpAsOrganizationDescription`,
    goTo: false,
    steps: [
        {
            title: `Introduction`,
            description: `Whether you love using Chrome, Firefox, Safari, or any
                        other browser, just open it up and get ready to start
                        your journey with us.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FeTk1LRM7cYY4DmrSpvXK1v_doc.png?alt=media&token=6be33159-72d5-46fe-b06e-4ba96d737d30',
        },
        {
            title: `Click "Sign Up"`,
            description: `On the login page, you’ll see a welcoming “Sign Up”
                        button. Give it a click to begin creating your brand-new
                        account with Skill N Depth.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2Fv6nUmjcApHnfCmtZAPQfAd_doc.png?alt=media&token=a448f072-ac97-481c-85e8-e9e969398e81',
        },
        {
            title: `Enter data`,
            description: `The first thing you’ll need to do is enter your
                        organization’s email address. Make sure it’s one you
                        check regularly since we’ll be sending you important
                        information. Next up, you’ll need to create a strong
                        password. This is your key to accessing the platform, so
                        pick something secure but memorable. Type it into the
                        "Enter your password" field. To double-check that you
                        didn’t make any typos, re-enter the same password in the
                        "Confirm password" field.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FqwK1dE6LsHr6GCfhpRPjHk_doc.png?alt=media&token=f73fa985-7172-405c-97b2-00e2d097669f',
        },
        {
            title: `Click "Agree and Join"`,
            description: `Click the "Agree and Join" button to proceed with the
                        registration.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F97KqdXr5mWEdunTV1oXcJR_doc.png?alt=media&token=cc62cc4b-6b32-4bb8-89a4-917a276b1f1d',
        },
        {
            title: `Enter verification code`,
            description: `We’ve sent a verification code to the email address you
                        provided. This is just to make sure everything’s secure.
                        Head over to your inbox, find our email, and grab the
                        code. Once you have the code, type it into the
                        verification field on the sign-up page. And just like
                        that, you’re almost there!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F1MZjRK11b4DbC3faLYNwdH_doc.png?alt=media&token=ea98483c-61de-42b7-9a40-82f451f09f32',
        },
        {
            title: `Select Diving organization`,
            description: `Next, we need to select which type of account we need.
                        In our case, we will select Diving organization.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FmE7ybj1gNywAqRV9F181Bp_doc.png?alt=media&token=42a5e90b-420f-4295-9a2f-4aa41b1ad31e',
        },
        {
            title: `Click "Next"`,
            description: `After making your selection, click “Next” to continue.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FsMwWavE4Td4Nf62XUN2L2j_doc.png?alt=media&token=1e02acc7-e7e0-43cf-adb8-152c88a3f566',
        },
        {
            title: `Enter first name`,
            description: `This part’s important. We need to know who we should
                        reach out to if we have questions or need to get in
                        touch. Start by entering the first name of the main
                        contact person in your organization. Click on the “Enter
                        contact person first name” field and type it in.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FakN69oWmnBifSc7wPk4PpB_doc.png?alt=media&token=eeb7fafa-2f16-4d13-9362-24edfecd43c7',
        },
        {
            title: `Enter last name`,
            description: `Then, do the same for their last name in the “Enter
                        contact person last name” field.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FoQ84rURrY8zzuFS5BnpV9G_doc.png?alt=media&token=cf64f936-169b-4999-b3d2-0073e4e05749',
        },
        {
            title: `Click "Next"`,
            description: `Proceed by clicking "Next".`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FutFvUg3yeL4pdFoW1ruLNu_doc.png?alt=media&token=0c16e272-e77e-4bda-8e59-12db85eea49d',
        },
        {
            title: `Enter name of the company`,
            description: `Now, let’s move on to your company details. Start by
                        typing in your company’s name.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FeDJMbJfcf3g71BBN4d5CGw_doc.png?alt=media&token=a555ce19-49d4-4280-beb0-2cc2d23b57fb',
        },
        {
            title: `Enter address`,
            description: `We also need to know where you’re based. Please provide
                        the full address of your headquarters.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F7CpRXJgnq14fejQEUnTrKD_doc.png?alt=media&token=98cf8523-19f0-4bba-8e01-c312953c5690',
        },
        {
            title: `Select company size`,
            description: `We’d love to know how big your company is, so select the
                        size range that best describes your organization. It
                        helps us tailor our services to suit your needs.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F7sW9yHkw286o5Q9kX9U3Jp_doc.png?alt=media&token=58b302fe-f55d-447c-90fb-71f017b7a18d',
        },
        {
            title: `Select industry`,
            description: `Next, let us know what industry your company operates
                        in. Whether you’re in "Oil and Gas" or another field,
                        select the industry that best fits your business.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F1J5412yXF9e7cH64nTM2n8_doc.png?alt=media&token=69d3dd93-6ec9-4aab-9598-73b872da826e',
        },
        {
            title: `Click "Next"`,
            description: `Proceed by clicking "Next.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F1tderXY3KLo5nKce3m1JSp_doc.png?alt=media&token=d20c6817-51c5-4b7f-91b2-ba702a636d77',
        },
        {
            title: `Select family of services`,
            description: `Choose all the relevant service families that apply to
                        your organization (e.g., "General Marine Services").`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F5BTAB9AtUzxAdF78bkrVVw_doc.png?alt=media&token=35b5c345-47e4-4559-b0f9-aaf79fa54bc0',
        },
        {
            title: `Select regions where your services are available`,
            description: `Also, tell us where you offer your services by selecting
                        the regions where your services are available, such as
                        “Asia Pacific & Oceania.”`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2FgSg9tEnDd7GXc4XYUEUrJw_doc.png?alt=media&token=a7cf006e-a005-41dd-a015-83351f0fcc62',
        },
        {
            title: `Click "Finish"`,
            description: `Click the “Finish” button, and that’s it—you’re all
                        signed up!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2Fe4eAKmK3cwFdpPTZXeYtBg_doc.png?alt=media&token=b8e83ffb-f26d-45a1-a667-d24786a1d994',
        },
        {
            title: `And that is it. Welcome to Skill N Depth`,
            description: `Congratulations! You’re now part of a community that’s
                        revolutionizing the diving industry. Take a moment to
                        review the welcome message and follow any additional
                        instructions we provide.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkBvmuHYA5dtM5Spdv5qg98%2F9r6TBggpZkpteNRG7U39Wr_doc.png?alt=media&token=3c47cdab-6074-4ef6-8fbb-300d2ec3e45e',
        },
    ],
    endNote: `We’re so excited to have you on board and can’t wait to
            see what we’ll achieve together. If you ever need a
            hand, don’t hesitate to reach out—we’re always here to
            help!`,
}

const SignUpAsOrganization = () => {
    return <HowToPage howToObject={howToObject} />
}

export default SignUpAsOrganization

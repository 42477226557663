import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { getColorByStatus, getLabelByStatus } from 'utils/CallStatusUtil'

import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'

import ManagementLayout from '../components/ManagementLayout'
import Loader from 'components/Loader'
import CallCenterBreadcrumbs from 'screens/admin/CallCenter/components/CallCenterBreadcrumbs'
import Separator from 'components/Separator'

const CallCenterView = () => {
    const { callId } = useParams()
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    const { data: call, isLoading } = useFetchDataById(
        ENTITIES.CALL_CENTER_CALLS,
        callId,
        {},
        true,
        true,
        false
    )

    if (isLoading) return <Loader />
    if (!call) return null

    return (
        <ManagementLayout
            BreadcrumbsComponent={CallCenterBreadcrumbs}
            edit={call}
        >
            <div className="_wr -callCenterView">
                <div className="_w">
                    <div className={`_12 ${isSmallScreen ? '-mb20' : '-mb40'}`}>
                        <span className="a-bodyTextMedium -mb10">
                            {t('callCenter.callInformation')}
                        </span>
                    </div>
                    <div className="_12 _l6 column">
                        <div className="column">
                            <span className="a-bodyTextMedium -mb10">
                                {t('callCenter.fromPhone')}
                            </span>
                            <p className="a-mediumText a-lightText -opacity-60 -about">
                                {call.from}
                            </p>
                        </div>
                        <div className="column">
                            <span className="a-bodyTextMedium -mt15 -mb10">
                                {t('callCenter.toPhone')}
                            </span>
                            <p className="a-mediumText a-lightText -opacity-60 -about">
                                {call.to}
                            </p>
                        </div>
                        <div className="column">
                            <span className="a-bodyTextMedium -mt15 -mb10">
                                {t('callCenter.callDuration')}
                            </span>
                            <p className="a-mediumText a-lightText -opacity-60 -about">
                                {call.duration}
                            </p>
                        </div>
                        <div className="column">
                            <span className="a-bodyTextMedium -mt15 -mb10">
                                {t('callCenter.status')}
                            </span>
                            <p className="a-mediumText a-lightText -opacity-60 -about">
                                <div
                                    className={`a-status a-captionsTextRegular -${getColorByStatus(
                                        call.conversationStatus
                                    )}`}
                                >
                                    {t(
                                        `general.${getLabelByStatus(
                                            call.conversationStatus
                                        )}`
                                    )}
                                </div>
                            </p>
                        </div>
                    </div>
                    <div
                        className={`_12 _l6 column ${
                            isSmallScreen ? '-mt15' : ''
                        } -mb25`}
                    >
                        {call.summary && (
                            <div className="column">
                                <span className="a-bodyTextMedium -mb10">
                                    {t('general.summary')}
                                </span>
                                <p className="a-mediumText a-lightText -opacity-60 -about">
                                    {call.summary}
                                </p>
                            </div>
                        )}
                        {call.recording && (
                            <div className="column fullWidth">
                                <span className="a-bodyTextMedium -mt15 -mb10">
                                    {t('general.recording')}
                                </span>
                                <audio controls className="fullWidth">
                                    <source
                                        src={call.recording}
                                        type="audio/mpeg"
                                    />
                                </audio>
                            </div>
                        )}
                    </div>
                    <Separator />
                    {call.transcript.length !== 0 && (
                        <div className="_12 _l6 column">
                            <span className="a-bodyTextMedium -mt15 -mb20">
                                {t('general.transcript')}
                            </span>
                            <div className="column">
                                {call.transcript.map((item, key) => {
                                    return (
                                        <div key={key} className="chat-item">
                                            {item.content}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ManagementLayout>
    )
}

export default CallCenterView

import React from 'react'
import HowToPage from '../HowToPage'

const howToObject = {
    title: 'howTo.updateOrganizationProfile',
    videoLink: 'https://embed.app.guidde.com/playbooks/vdXJ64k31oPa7ghwWDoRhx',
    description: `howTo.updateOrganizationProfileDescription`,
    goTo: false,
    steps: [
        {
            title: `Introduction`,
            description: `Once you’re logged in, take a look at the top right
                        corner of the page. You’ll see your profile picture or
                        name there.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2Fb2A9UT5tjY8sypZ1MgbbNL_doc.png?alt=media&token=5d7bd8be-e91e-4062-a892-635f21bd3350',
        },
        {
            title: `Select your profile`,
            description: `Give it a click to open a dropdown menu where you’ll
                        find all sorts of options.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FvSU4REMHpXGsr1Uc8cjZVw_doc.png?alt=media&token=2f51a996-525e-4ccf-b8f1-5400df060dfc',
        },
        {
            title: `Click "Profile"`,
            description: `From the dropdown menu, select the "Profile" option.
                        This will take you to your organization’s profile page
                        where all your important details live.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FpCD2tpJwSLpGkzvDFsXGCD_doc.png?alt=media&token=ec4843dc-5c0e-43d6-a163-660c99ff4465',
        },
        {
            title: `Open Company Profile For Editing`,
            description: `On the company profile page, look for an edit icon—it
                        looks like a pencil. Click this icon to switch your
                        profile into editing mode. Now, you can make all the
                        changes you need.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FwsbJCN3xVHeSRyCPNcxysc_doc.png?alt=media&token=629051bd-a904-4d6e-b80a-9e7dd58d8404',
        },
        {
            title: `Upload The Profile Photo`,
            description: `In the "Edit company information" section, you’ll see an
                        area for your profile photo. Click on the edit icon.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F2bJPzge2JpMTwiTyQiZE8A_doc.png?alt=media&token=3cdd4b72-849e-4cbd-b93c-f81106731314',
        },
        {
            title: `Click "Upload new photo"`,
            description: `Choose the Upload new photo option. Pick a fresh,
                        professional image from your computer, and upload it.
                        This is your chance to give your profile a new look!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FmHZbaUYf4kP8DHNSJiKY11_doc.png?alt=media&token=91a57e92-4fb1-44a0-94f7-9b8376655d9a',
        },
        {
            title: `Adjust the name`,
            description: `Double-check the name field to ensure your company’s
                        name is correctly displayed.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F1Uv3dQWaHpYw313dRoxE9u_doc.png?alt=media&token=e7d4032e-53f0-46da-88a4-422d2b3b1568',
        },
        {
            title: `Adjust size of the company`,
            description: `Choose the correct size category for your company from
                        the dropdown list. For instance, if your company has
                        grown, you might select "101-200 employees." Growth is
                        good!`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FnSoiozMPr5YXyFakG4jkP5_doc.png?alt=media&token=3c9926dc-8f1c-47f1-869e-0397a273c468',
        },
        {
            title: `Select a country`,
            description: `Next, select the country where your company is based.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FcXuTv2DXY4VK9XfRDyFMtt_doc.png?alt=media&token=d8d25fb0-3336-4fa7-974e-7537b47a5a20',
        },
        {
            title: `Select date when company was founded`,
            description: `Enter the date your company was founded. This adds a
                        nice historical touch to your profile, showing how
                        established your company is. Select the month, day, and
                        year from the dropdown menus.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FrMsEWwqJKNtePkm13FVJ7K_doc.png?alt=media&token=9d58d181-1260-46a2-a821-bd0515e9992d',
        },
        {
            title: `Enter TIN/VAT number`,
            description: `In this step, provide your company’s TIN or VAT number.
                        This is essential for maintaining accurate records and
                        ensuring compliance.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FfyddxKfAuVB5M1wusneEik_doc.png?alt=media&token=0a5c09f5-4937-424a-b5c7-797c52f051d1',
        },
        {
            title: `Adjust company email address`,
            description: `Make sure the company email address listed is up-to-date.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FkaVrgor8DsTmKMQsQnuAmQ_doc.png?alt=media&token=54d4aca6-35cc-487f-8648-cd978b0d04b8',
        },
        {
            title: `Adjust phone number`,
            description: `Click on the phone number field and enter the correct
                        contact number for your company.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FogQS3W9Hq3oA4VL9aYu4jm_doc.png?alt=media&token=7903702c-4139-4d78-b459-b111d5d0ac3b',
        },
        {
            title: `Enter the website of your company`,
            description: `Type in your company’s website URL in the designated
                        field. Your website is your digital front door, so make
                        sure it’s accurate and functional.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FjGFBYDBuhapXmshJL8RVb2_doc.png?alt=media&token=5be6c4f7-3329-428b-9b96-840e89a35b0c',
        },
        {
            title: `Adjust contact person name`,
            description: `Update the name of the primary contact person for your
                        company. If John Doe is no longer the contact, make sure
                        the new person’s name is listed correctly.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FfwhCd5K5a7zg93dfw5Jtxb_doc.png?alt=media&token=783afa91-3c97-4dd2-9206-0746fee6a7fb',
        },
        {
            title: `Enter more details about the company`,
            description: `Head over to the "About" section and enter a brief
                        description or update the existing information about
                        your company. This is a great place to tell your story
                        and showcase what makes your company special.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F8q2U5s2xmVozkZ57x5tZ7L_doc.png?alt=media&token=48efcae8-dad6-4815-a246-9c6801747a3d',
        },
        {
            title: `Select industries`,
            description: `Choose the relevant industries that your company
                        operates in, such as "Civil Engineering-Infrastructure."
                        This helps others understand what fields you specialize
                        in.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FnTz3fK3XcEsKrM1CfY5Gdk_doc.png?alt=media&token=cea63016-c9e0-4051-94bb-32ce7db079ae',
        },
        {
            title: `Select family of provided services`,
            description: `From the list, choose the family of services your
                        company offers, like "Close Visual Inspection." This
                        ensures that your profile accurately reflects what you
                        do.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F2MoAggW4s3Zn5F1KDwVqF9_doc.png?alt=media&token=e0d8bc1f-ccfa-41de-8ce8-d5bafa5e14ad',
        },
        {
            title: `Adjust location of your services`,
            description: `Specify the geographic regions where your services are
                        available. For instance, you might select "South Asia
                        (Indian)" if that’s where you operate.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FoNANfeQAAq4ncWDAFpM9R3_doc.png?alt=media&token=a8ba2dac-c3db-46f2-a9a8-41ae4fdc9d4f',
        },
        {
            title: `Showcase if your company owns/operates any vessels`,
            description: `If your company owns or operates any vessels, this is
                        the place to highlight them. For example, you might list
                        "DSV Toisa Pegasus." It’s a great way to showcase your
                        assets.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2Ffbk1jrB2wbbASAhmAYbUyQ_doc.png?alt=media&token=5cb0638a-8538-4f11-b9a5-65c2b4ddcead',
        },
        {
            title: `Showcase companies you are working with`,
            description: `Add any key companies you partner with. This can boost
                        your credibility and show the strength of your
                        partnerships.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2F7zDzTTtUupr5Xwnd7oZqxp_doc.png?alt=media&token=51d0eec4-93b9-4554-9004-c0094bff4704',
        },
        {
            title: `Click "Save company information"`,
            description: `Finally, once all the changes have been made, don’t
                        forget to click on "Save company information." This
                        ensures all your hard work is captured and your profile
                        is up-to-date.`,
            img: 'https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FvdXJ64k31oPa7ghwWDoRhx%2FeZFJ7bTGH4ehiGgYh4qnwP_doc.png?alt=media&token=e2c1920b-8524-443d-b767-17ef0d4455aa',
        },
    ],
    endNote: `Congratulations! You’ve successfully updated your
            organization’s profile on Skill N Depth. By keeping your
            profile current, you’re helping to ensure that your
            company is accurately represented on the platform. If
            you ever need to make more changes or have any
            questions, the Skill N Depth support team is always here
            to help.`,
}

const UpdateOrganizationProfile = () => {
    return <HowToPage howToObject={howToObject} />
}

export default UpdateOrganizationProfile

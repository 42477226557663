import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { translatePackagesOptions } from 'utils/translateOptionsHelper'

import { editEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'

import {
    ALERT_TYPES,
    PACKAGE_INFO_DATA,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import FocusError from 'components/FocusError'
import { SelectField } from 'components/formFields'
import Loader from 'components/Loader'

const IndividualAccountChangeSubscriptionType = ({
    open,
    setOpen,
    user,
    fetchData,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const initialSubscriptionType = PACKAGE_INFO_DATA.find(
        (item) => item.id === user.userSubscription.userSubscriptionType
    )

    const initialValues = {
        userSubscriptionType: initialSubscriptionType ?? null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        userSubscriptionType: Yup.object().required(requiredMessage),
    })

    const handleSubmit = async (
        { userSubscriptionType },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)
            await editEntityService(
                ENTITIES.USER_SUBSCRIPTION,
                user.userSubscription.id,
                {
                    userSubscriptionType: userSubscriptionType.id,
                }
            )
            setAlert(
                t('message.successfullyChangedUserSubscriptionType'),
                ALERT_TYPES.SUCCESS
            )
            fetchData()
            setOpen(false)
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.changeUserSubscriptionType"
                        isSubmitting={isSubmitting}
                        smallModal
                        smallModalWithDropdowns
                        buttons={{
                            prevBtn: {
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                    >
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <div className="_12 -mb5">
                                    <SelectField
                                        name="userSubscriptionType"
                                        label="form.label.subscriptionType"
                                        placeholder="form.placeholder.subscriptionType"
                                        defaultOptions={PACKAGE_INFO_DATA}
                                        valueType={SELECT_VALUE_TYPE.OBJECT}
                                        translateItem={translatePackagesOptions}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

IndividualAccountChangeSubscriptionType.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    user: PropTypes.object,
    fetchData: PropTypes.func,
}

export default IndividualAccountChangeSubscriptionType

import React from 'react'
import { useParams } from 'react-router-dom'

import { editEntityService } from 'services/entity.service'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'

import ManagementLayout from '../components/ManagementLayout'
import IndividualAccountBreadcrumbs from './components/IndividualAccountBreadcrumbs'
import IndividualAccountForm from './components/IndividualAccountForm'
import Loader from 'components/Loader'

const IndividualAccountEdit = () => {
    const { userId } = useParams()

    const { data: userData, isLoading } = useFetchDataById(
        ENTITIES.USER,
        userId,
        {
            include: [
                'role,country,diverPositions,countryPhoneCode,userSetting,regions,userStatus,avatar,heroImage,userSubscription',
            ],
        }
    )

    const handleSubmit = async (formData) => {
        await editEntityService(ENTITIES.USER, userId, formData, true, [
            'diverLanguages',
            'userSubscription',
        ])
    }

    if (isLoading) return <Loader />
    if (!userData) return null

    return (
        <ManagementLayout
            BreadcrumbsComponent={IndividualAccountBreadcrumbs}
            edit={userData}
        >
            <IndividualAccountForm
                initialData={userData}
                handleSubmit={handleSubmit}
            />
        </ManagementLayout>
    )
}

export default IndividualAccountEdit

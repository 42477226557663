import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 1.5.0',
    introText:
        'We’re thrilled to introduce version 1.5.0, packed with enhancements to improve your experience and make the platform more intuitive and efficient. Here’s what’s new and upcoming:',
    items: [
        {
            title: 'Extended client organizations features',
            description:
                'We’ve enhanced the client organizations functionality with new capabilities:',
            icon: ICONS.BUILDING,
            descriptionList: [
                {
                    description:
                        'Support for multiple user accounts within an organization, allowing seamless collaboration among team members.',
                },
                {
                    description:
                        'Automatic sharing of user data when applying for a job, ensuring relevant information is accessible to the organization, streamlining the hiring process.',
                },
            ],
        },
        {
            title: 'Enhanced dive records management',
            description:
                'Track, view, and manage your dive records more effectively with improved organization and filtering options. You can now filter dive records by status, project, position, and verification level, ensuring easier access and management of your data.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Automatic GPS coordinates for dive records',
            description:
                'When filling out your dive records, the GPS coordinates of your location can now be automatically stored. This ensures accurate location.',
            icon: ICONS.LOCATION,
        },
        {
            title: 'Upload dive records as files',
            description:
                'Simplify record-keeping with the ability to upload your dive records directly as files to your profile. This feature allows you to store your records effortlessly, without manually entering each detail.',
            icon: ICONS.UPLOAD,
        },
        {
            title: 'In-app feedback mechanism',
            description:
                'You can now provide feedback directly within the app. Share your opinions, report issues, and suggest improvements to help us create a better experience for you and the community.',
            icon: ICONS.ROUNDED_EDIT,
        },
        {
            title: 'Notifications for data access control requests',
            description:
                'Stay informed with notifications for data access control requests and actions, ensuring you’re always aware of changes to your profile’s data access permissions.',
            icon: ICONS.NOTIFICATIONS_NO_CIRCLE,
        },
        {
            title: 'Level 2 Verification for dive records',
            description:
                'Dive supervisors can now send dive records for Level 2 verification exclusively to users with company roles. This maintains the existing Level 1 and Level 3 verification process while allowing targeted verification by appropriate company users.',
            icon: ICONS.VERIFICATION_OUTLINE,
        },
        {
            title: 'Historical dive record access',
            description:
                'Divers who have been removed from a project can now access their dive records in edit mode as historical records. This ensures you retain control of your records without project or supervisor restrictions, avoiding errors.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Profile visibility controls',
            description:
                'Take full control over the visibility of your profile elements, including your contact details, ensuring you decide what information is shared and with whom.',
            icon: ICONS.SHOW_PASSWORD,
        },
        {
            title: 'Auto populated training school details',
            description:
                'When adding professional diver training, the training school details will now autopopulate upon selection, saving you time and ensuring accurate data entry.',
            icon: ICONS.TRAINING_RECORD,
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingSectionDescription: 'Here’s what’s on the horizon:',
    upcomingItems: [
        {
            title: 'Dive Records:',
            description:
                'Enhanced separation and filtering options for improved usability.',
        },
        {
            title: 'Dive Record Levels:',
            description:
                'Improved showcase and presentation of record verification levels.',
        },
    ],
    outroText:
        'Thank you! Thank you for being a valued part of our community. These updates are inspired by your feedback, and we’re dedicated to making the platform work better for you. Stay tuned for more improvements in future releases!',
}

const Release150 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release150

import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
    DIVE_RECORD_STATUS,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { TAB } from 'screens/diver/profile/diverInformation/constants/tabs'

import SearchAndFilter from 'components/SearchAndFilter'
import DiveRecordFilters from './components/DiveRecordFilters'
import DiveRecordListCard from './components/DiveRecordListCard'
import CreateDiveRecordModal from '../CreateDiveRecordModal'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import Pagination from 'components/table/Pagination'
import EmptyList from '../../../components/EmptyList'
import TabHeading from 'screens/diver/profile/diverInformation/components/TabHeading'

const filters = {
    project: { entityType: ENTITIES.PROJECT, displayAttribute: 'name' },
    divingMode: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    status: {
        formatter: (value) => {
            const selected = Object.values(DIVE_RECORD_STATUS).find(
                (item) => item.id === parseInt(value)
            )

            return selected ? selected.name : ''
        },
    },
    fromDate: { type: 'date' },
    toDate: { type: 'date' },
}

const DiveRecordList = ({
    projectId,
    userID,
    profilePage,
    myProfile,
    user,
}) => {
    const t = useTranslate()

    const {
        data: diveRecords,
        meta,
        isLoading,
        fetchData,
    } = useFetchDataByQueryParams(ENTITIES.DIVE_RECORD_ELASTIC, {
        'project.id': projectId,
        'creator.id': userID,
        isProjectPage: projectId ? 1 : 0,
        include:
            'diveRecordGeneralInformation,diveRecordGeneralInformation.divingMode,project,diveRecordGeneralInformation.supervisor,creator',
    })

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    const hideFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.DIVE_RECORDS
    )

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.DIVE_RECORDS}
                title="general.diveRecords"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profileId={userID}
                checkAccessControl={fetchData}
                profile={user}
                requestTime={meta.dataAccessRequestTime}
            />
        )
    }

    if (!diveRecords) return null

    return (
        <div className="_wr profileWrapper">
            {profilePage ? (
                <div className="-mb20">
                    <TabHeading
                        tabTitle="general.diveRecords"
                        tab={ROUTES.DIVE_RECORDS}
                        myProfile={myProfile}
                    />
                    {hideFiles && (
                        <p className="a-mediumText a-lightText -mt20">
                            {t('general.noAccessToFiles')}
                        </p>
                    )}
                    {myProfile && (
                        <div className="justify-center -mb20">
                            <CreateDiveRecordModal
                                route={`${ROUTES.DIVER_PROFILE}/${userID}?tab=${TAB.DIVE_RECORDS}`}
                                profile
                            />
                        </div>
                    )}
                </div>
            ) : (
                <h3 className="-mb20">
                    {t('general.diveRecords')} ({meta.totalItems})
                </h3>
            )}

            {!profilePage && (
                <SearchAndFilter
                    searchPlaceholder="general.searchDiveRecords"
                    activeFilters={activeFilters}
                    filters={filters}
                    showDefaultKey={false}
                >
                    <DiveRecordFilters
                        activeFilters={activeFilters}
                        projectId={projectId}
                    />
                </SearchAndFilter>
            )}

            {diveRecords.map((diveRecord) => (
                <div className="-mb20" key={diveRecord.id}>
                    <DiveRecordListCard
                        diveRecord={diveRecord}
                        fetchData={fetchData}
                    />
                </div>
            ))}
            {diveRecords.length === 0 && !isLoading && (
                <EmptyList
                    icon={ICONS.NO_DATA}
                    title={
                        meta?.totalItems
                            ? t('general.noDiveRecordsOnThisPage')
                            : t('general.noDiveRecords')
                    }
                    description={
                        meta?.totalItems
                            ? t('general.noDiveRecordsOnThisPageDesc')
                            : t('general.noDiveRecordsDesc')
                    }
                    showSearchResult={
                        Object.keys(activeFilters).length !== 0 &&
                        !meta?.totalItems
                    }
                />
            )}
            <Pagination totalItems={meta?.totalItems} meta={meta} scrollToTop />
            {renderLoader(isLoading, profilePage, isLoadingFilters)}
        </div>
    )
}

DiveRecordList.propTypes = {
    projectId: PropTypes.number,
    userID: PropTypes.number,
    profilePage: PropTypes.bool,
    myProfile: PropTypes.bool,
}

DiveRecordList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default DiveRecordList
